export default function OptimismSmIcon() {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_11024_2647)">
        <path
          d="M7.5 15C11.6421 15 15 11.6421 15 7.5C15 3.35786 11.6421 0 7.5 0C3.35786 0 0 3.35786 0 7.5C0 11.6421 3.35786 15 7.5 15Z"
          fill="white"
        />
        <path
          d="M5.31397 9.49196C4.86697 9.49196 4.50097 9.38696 4.21597 9.17696C3.93397 8.96396 3.79297 8.65796 3.79297 8.26496C3.79297 8.18096 3.80197 8.08196 3.81997 7.96196C3.86797 7.69196 3.93697 7.36796 4.02697 6.98696C4.28197 5.95496 4.94197 5.43896 6.00397 5.43896C6.29197 5.43896 6.55297 5.48696 6.78097 5.58596C7.00897 5.67896 7.18897 5.82296 7.32097 6.01496C7.45297 6.20396 7.51897 6.42896 7.51897 6.68996C7.51897 6.76796 7.50997 6.86696 7.49197 6.98696C7.43497 7.31996 7.36897 7.64696 7.28797 7.96196C7.15597 8.47496 6.93097 8.86196 6.60697 9.11696C6.28597 9.36896 5.85397 9.49196 5.31397 9.49196ZM5.39497 8.68196C5.60497 8.68196 5.78197 8.61897 5.92897 8.49597C6.07897 8.37297 6.18697 8.18397 6.24997 7.92597C6.33697 7.57197 6.40297 7.26596 6.44797 7.00196C6.46297 6.92396 6.47197 6.84296 6.47197 6.75896C6.47197 6.41696 6.29497 6.24596 5.93797 6.24596C5.72797 6.24596 5.54797 6.30896 5.39797 6.43196C5.25097 6.55496 5.14597 6.74396 5.08297 7.00196C5.01397 7.25396 4.94797 7.55997 4.87897 7.92597C4.86397 8.00097 4.85497 8.07897 4.85497 8.16297C4.85197 8.51097 5.03497 8.68196 5.39497 8.68196Z"
          fill="black"
        />
        <path
          d="M7.77836 9.43797C7.73636 9.43797 7.70636 9.42597 7.68236 9.39897C7.66436 9.36897 7.65836 9.33597 7.66436 9.29697L8.44136 5.63697C8.44736 5.59497 8.46836 5.56197 8.50436 5.53497C8.53736 5.50797 8.57336 5.49597 8.61236 5.49597H10.1094C10.5264 5.49597 10.8594 5.58297 11.1114 5.75397C11.3664 5.92797 11.4954 6.17697 11.4954 6.50397C11.4954 6.59697 11.4834 6.69597 11.4624 6.79797C11.3694 7.22997 11.1804 7.54797 10.8924 7.75497C10.6104 7.96197 10.2234 8.06397 9.73136 8.06397H8.97236L8.71436 9.29697C8.70536 9.33897 8.68736 9.37197 8.65136 9.39897C8.61836 9.42597 8.58236 9.43797 8.54336 9.43797H7.77836ZM9.77036 7.28697C9.92936 7.28697 10.0644 7.24497 10.1814 7.15797C10.3014 7.07097 10.3794 6.94797 10.4184 6.78597C10.4304 6.72297 10.4364 6.66597 10.4364 6.61797C10.4364 6.50997 10.4034 6.42597 10.3404 6.36897C10.2774 6.30897 10.1664 6.27897 10.0134 6.27897H9.33836L9.12536 7.28697H9.77036Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_11024_2647">
          <rect width="15" height="15" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
