import { ComponentProps } from 'react';
import { Box } from '@mui/material';

import UnstyledLink from 'common/UnstyledLink';

interface Props extends ComponentProps<typeof UnstyledLink> {
  children: React.ReactNode;
  condition: boolean;
  else?: () => void;
}

export default function ConditionalLink({
  children,
  condition,
  ...props
}: Props) {
  if (condition) {
    return (
      <UnstyledLink {...props} passHref>
        {children}
      </UnstyledLink>
    );
  }

  if (props.else) {
    return <Box onClick={() => props.else?.()}>{children}</Box>;
  }

  return <>{children}</>;
}
