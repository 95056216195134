import { ComponentProps, useRef, useState } from 'react';
import {
  Backdrop,
  Box,
  ClickAwayListener,
  Popper,
  Portal,
} from '@mui/material';

interface Props
  extends Omit<ComponentProps<typeof Popper>, 'id' | 'open' | 'anchorEl'> {
  content: React.ReactNode | ((close: () => void) => React.ReactNode);
  withBackdrop?: boolean;
  children: ({
    isOpen,
    handleClose,
  }: {
    isOpen: boolean;
    handleClose: () => void;
  }) => React.ReactNode;
  beforeClose?: () => void;
  allowHover?: boolean;
}

export default function PopperWrapper(props: Props) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <Popper
        sx={{ zIndex: 10 }}
        {...props}
        open={open}
        anchorEl={anchorRef.current}
      >
        <ClickAwayListener
          onClickAway={(e) => {
            props.beforeClose?.();
            handleClose(e);
          }}
        >
          <Box
            sx={{
              opacity: open ? 1 : 0,
              transition: 'opacity 1s ease-in-out',
            }}
            key="4"
          >
            {typeof props.content === 'function'
              ? props.content(() => setOpen(false))
              : props.content}
          </Box>
        </ClickAwayListener>
      </Popper>

      <Box
        onClick={handleToggle}
        sx={{
          width: 'auto',
        }}
        ref={anchorRef}
      >
        {props.children({
          isOpen: open,
          handleClose: () => setOpen(false),
        })}
      </Box>
      {props.withBackdrop && (
        <Portal>
          <Backdrop
            style={{
              backgroundColor: 'rgba(0,0,0,0.2)',
            }}
            open={open}
          />
        </Portal>
      )}
    </>
  );
}
