export default function ScrollSmIcon() {
  return (
    <img
      src="/icons/chains/scroll.svg"
      width={16}
      height={16}
      alt="scroll"
      style={{
        maxWidth: '100%',
        height: 'auto',
      }}
    />
  );
}
