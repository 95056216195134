import dayjs from 'dayjs';
import { create, StateCreator } from 'zustand';
import { persist } from 'zustand/middleware';

import { ActivityRange } from 'types/Filters';

export type SocialTrackingStore = {
  activity: {
    time: {
      from: string | null;
      to: string | null;
    };
    range: ActivityRange | null;
  };
  setActivityRange: (
    range: ActivityRange | null,
    from: string | null,
    to: string | null,
  ) => void;
  lastVisitedProjectId: string | null;
  setLastVisitedProjectId: (id: string | null) => void;
};

const prepareStore: StateCreator<SocialTrackingStore> = (set) => ({
  lastVisitedProjectId: null,
  setLastVisitedProjectId: (id) => set(() => ({ lastVisitedProjectId: id })),
  activity: {
    time: {
      from: dayjs().subtract(7, 'day').toISOString(),
      to: dayjs().toISOString(),
    },
    range: '7d',
  },
  setActivityRange: (range, from, to) =>
    set(() => ({
      activity: {
        time: {
          from,
          to,
        },
        range,
      },
    })),
});

export const useSocialTrackingStore = create(
  persist(prepareStore, {
    name: 'social-tracking-store',
    version: 1.0,
    partialize: (state) => ({
      activity: state.activity,
      lastVisitedProjectId: state.lastVisitedProjectId,
    }),
  }),
);
