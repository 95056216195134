export default function EthereumIcon() {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15.5" cy="15.5" r="15.5" fill="#ECEFF0" />
      <g clipPath="url(#clip0_9885_18303)">
        <path
          d="M15.9679 3.75757L15.8242 4.24994V18.5362L15.9679 18.6808L22.5416 14.7609L15.9679 3.75757Z"
          fill="#343434"
        />
        <path
          d="M15.9684 3.75757L9.39453 14.7609L15.9684 18.6808V11.7467V3.75757Z"
          fill="#8C8C8C"
        />
        <path
          d="M15.9677 19.9363L15.8867 20.036V25.1249L15.9677 25.3634L22.5454 16.0185L15.9677 19.9363Z"
          fill="#3C3C3B"
        />
        <path
          d="M15.9684 25.3634V19.9363L9.39453 16.0185L15.9684 25.3634Z"
          fill="#8C8C8C"
        />
        <path
          d="M15.9688 18.6808L22.5424 14.7609L15.9688 11.7466V18.6808Z"
          fill="#141414"
        />
        <path
          d="M9.39453 14.7609L15.9684 18.6808V11.7466L9.39453 14.7609Z"
          fill="#393939"
        />
      </g>
      <defs>
        <clipPath id="clip0_9885_18303">
          <rect
            width="13.1515"
            height="21.6061"
            fill="white"
            transform="translate(9.39453 3.75757)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
