import React from 'react';
import { Box } from '@mui/material';

import Text from 'components/primitives/Text';

export default function DesktopHeaderDemoTeamOverlay() {
  return (
    <Box
      display={{
        xs: 'none',
        lg: 'block',
      }}
    >
      <Text
        position="absolute"
        sx={{
          left: '50%',
          transform: 'translateX(-50%) translateY(-50%)',
          fontWeight: 600,
          fontSize: '14px',
          lineHeight: '1',
          top: '50%',
          color: 'red.500',
        }}
      >
        You are viewing the Cookie3 app in demo mode. Change your team to view
        your own data.
      </Text>
    </Box>
  );
}
