import React from 'react';
import Link from 'next/link';
import { Box } from '@mui/material';

import SlashButton from 'components/primitives/Button/SlashButton';
import Text from 'components/primitives/Text';

export default function GoToPricingButton() {
  return (
    <Link href="/plans">
      <SlashButton sx={{ height: '38px !important' }}>
        <Box>
          <Text
            color="white"
            sx={{
              fontSize: '12px !important',
              lineHeight: 1,
              mr: 0.5,
            }}
          >
            View pricing plans
          </Text>
          <Text
            color="white"
            width="fit-content"
            sx={{
              fontSize: '10px !important',
              opacity: 0.6,
              lineHeight: 1,
              mt: 0.25,
              ml: 0,
            }}
          >
            Go to pricing
          </Text>
        </Box>
      </SlashButton>
    </Link>
  );
}
