import React from 'react';
import { PiMagnifyingGlass } from 'react-icons/pi';

import Button from 'components/primitives/Button';
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover';
import ExplorerSearchDropdown from 'components/views/explorer/common/ExplorerSearchDropdown';

export default function TokenSearch() {
  return (
    <Popover>
      <PopoverTrigger className="flex w-full py-0">
        <Button variant="white" size="mini" className="!h-[28px]">
          <PiMagnifyingGlass size={16} color="#979899" /> Search for tokens
        </Button>
      </PopoverTrigger>

      <PopoverContent
        align="start"
        side="bottom"
        className="z-[99999999] h-max !max-h-max  w-full p-0 sm:w-[500px]"
      >
        <ExplorerSearchDropdown />
      </PopoverContent>
    </Popover>
  );
}
