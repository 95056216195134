export default function BlastSmIcon() {
  return (
    <svg
      width="21"
      height="14"
      viewBox="0 0 21 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.18437 13.4777C2.29639 10.1049 3.39299 6.78052 4.50465 3.40922C5.20462 3.91499 5.87925 4.38595 6.52597 4.88994C6.61521 4.95955 6.62036 5.21066 6.57482 5.35024C6.08087 6.86541 5.56783 8.37525 5.06213 9.88686C4.94277 10.2442 4.83003 10.6032 4.69855 11.0099C4.86932 11.0206 4.99272 11.0348 5.11611 11.0348C8.09889 11.0358 11.0817 11.0316 14.0644 11.0408C14.3575 11.0419 14.4945 10.9708 14.5841 10.6803C14.8856 9.70324 15.2275 8.73752 15.5746 7.70681H8.63066C8.86129 7.01174 9.07136 6.37847 9.2895 5.7214C9.49663 5.7214 9.69054 5.7214 9.88444 5.7214C11.8731 5.7214 13.8614 5.71607 15.85 5.72779C16.1287 5.72957 16.2709 5.65782 16.3539 5.39002C16.6003 4.59443 16.8731 3.80666 17.1567 2.95247H0.0345212C0.0231365 2.92122 0.0113846 2.88961 0 2.85835C0.206025 2.70101 0.408378 2.53905 0.618811 2.38739C1.39444 1.82835 2.16418 1.26149 2.9556 0.724473C3.14253 0.597677 3.40474 0.514922 3.6328 0.514211C8.22486 0.50249 12.8165 0.509949 17.4086 0.500004C17.7836 0.499294 18.0854 0.587022 18.3778 0.808649C18.8713 1.18265 19.3807 1.53782 19.8956 1.88411C20.0913 2.01588 20.1303 2.14623 20.0553 2.36111C19.7384 3.26928 19.4457 4.18527 19.1196 5.0899C19.0678 5.23339 18.9121 5.37581 18.7667 5.44862C17.806 5.92988 16.835 6.39196 15.7589 7.00392C15.8566 7.0398 15.9679 7.05791 16.0501 7.11474C16.6128 7.50472 17.1651 7.90855 17.7303 8.29533C17.9132 8.42035 17.9808 8.52655 17.9 8.75848C17.5625 9.72597 17.2606 10.7048 16.9264 11.6737C16.879 11.8108 16.7501 11.9593 16.6179 12.0254C15.6895 12.4881 14.7534 12.9367 13.8118 13.3743C13.6421 13.4531 13.4368 13.4947 13.2477 13.4951C9.38829 13.5018 5.52927 13.5 1.66987 13.499C1.51893 13.499 1.36836 13.4858 1.184 13.4773L1.18437 13.4777Z"
        fill="white"
      />
    </svg>
  );
}
