export const prepareFeedbackForm = (data: {
  description: string;
  file?: FileList | undefined;
}): FormData => {
  const formData = new FormData();

  formData.append('description', data.description);

  if (Boolean(data.file) && data.file?.length) {
    formData.append('file', data.file[0]);
  }

  return formData;
};
