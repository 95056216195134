import { ContractDetails, ImplementedInterface } from 'types/SegmentBuilder';

export const SUGGESTED_TOKENS = [
  {
    address: '0xae7ab96520de3a18e5e111b5eaab095312d7fe84',
    chain: 1,
    implementedInterfaces: ImplementedInterface.Erc20,
    name: 'Lido Staked Ether',
    symbol: 'STETH',
  },
  {
    address: '0x514910771af9ca656af840dff83e8264ecf986ca',
    chain: 1,
    implementedInterfaces: ImplementedInterface.Erc20,
    name: 'Chainlink',
    symbol: 'LINK',
  },
  {
    address: '0x6b175474e89094c44da98b954eedeac495271d0f',
    chain: 1,
    implementedInterfaces: ImplementedInterface.Erc20,
    name: 'DAI Stablecoin',
    symbol: 'DAI',
  },
  {
    address: '0x5a98fcbea516cf06857215779fd812ca3bef1b32',
    chain: 1,
    implementedInterfaces: ImplementedInterface.Erc20,
    name: 'Lido DAO',
    symbol: 'LDO',
  },
  {
    address: '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984',
    chain: 1,
    implementedInterfaces: ImplementedInterface.Erc20,
    name: 'Uniswap',
    symbol: 'UNI',
  },
  {
    address: '0x6b3595068778dd592e39a122f4f5a5cf09c90fe2',
    chain: 1,
    implementedInterfaces: ImplementedInterface.Erc20,
    name: 'Sushi',
    symbol: 'SUSHI',
  },
  {
    address: '0x5283d291dbcf85356a21ba090e6db59121208b44',
    chain: 1,
    implementedInterfaces: ImplementedInterface.Erc20,
    name: 'Blur',
    symbol: 'BLUR',
  },
  {
    address: '0xd1d2eb1b1e90b638588728b4130137d262c87cae',
    chain: 1,
    implementedInterfaces: ImplementedInterface.Erc20,
    name: 'GALA',
    symbol: 'GALA',
  },
  {
    address: '0xba5bde662c17e2adff1075610382b9b691296350',
    chain: 1,
    implementedInterfaces: ImplementedInterface.Erc20,
    name: 'SuperRare',
    symbol: 'RARE',
  },
  {
    address: '0xbb0e17ef65f82ab018d8edd776e8dd940327b28b',
    chain: 1,
    implementedInterfaces: ImplementedInterface.Erc20,
    name: 'Axie Infinity',
    symbol: 'AXS',
  },
  {
    address: '0xe53ec727dbdeb9e2d5456c3be40cff031ab40a55',
    chain: 1,
    implementedInterfaces: ImplementedInterface.Erc20,
    name: 'SuperVerse',
    symbol: 'SUPER',
  },
  {
    address: '0x6982508145454ce325ddbe47a25d4ec3d2311933',
    chain: 1,
    implementedInterfaces: ImplementedInterface.Erc20,
    name: 'Pepe',
    symbol: 'PEPE',
  },
  {
    address: '0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0',
    chain: 1,
    implementedInterfaces: ImplementedInterface.Erc20,
    name: 'Polygon',
    symbol: 'MATIC',
  },
  {
    address: '0xb50721bcf8d664c30412cfbc6cf7a15145234ad1',
    chain: 1,
    implementedInterfaces: ImplementedInterface.Erc20,
    name: 'Arbitrum',
    symbol: 'ARB',
  },
  {
    address: '0x767fe9edc9e0df98e07454847909b5e959d7ca0e',
    chain: 1,
    implementedInterfaces: ImplementedInterface.Erc20,
    name: 'Illuvium',
    symbol: 'ILV',
  },
  {
    address: '0xaea46a60368a7bd060eec7df8cba43b7ef41ad85',
    chain: 1,
    implementedInterfaces: ImplementedInterface.Erc20,
    name: 'Fetch',
    symbol: 'FET',
  },
  {
    address: '0x580e933d90091b9ce380740e3a4a39c67eb85b4c',
    chain: 1,
    implementedInterfaces: ImplementedInterface.Erc20,
    name: 'GameSwift',
    symbol: 'GSWIFT',
  },
];

export const SUGGESTED_NFTS: ContractDetails[] = [
  {
    address: '0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d',
    chain: 1,
    implementedInterfaces: ImplementedInterface.Erc721,
    name: 'Bored Ape Yacht Club',
    symbol: 'BAYC',
  },
  {
    address: '0x60e4d786628fea6478f785a6d7e704777c86a7c6',
    chain: 1,
    implementedInterfaces: ImplementedInterface.Erc721,
    name: 'Mutant Ape Yacht Club',
    symbol: 'MAYC',
  },
  {
    address: '0xb47e3cd837ddf8e4c57f05d70ab865de6e193bbb',
    chain: 1,
    implementedInterfaces: ImplementedInterface.Erc721,
    name: 'CryptoPunks',
    symbol: 'C',
  },
  {
    address: '0x8821BeE2ba0dF28761AffF119D66390D594CD280',
    chain: 1,
    implementedInterfaces: ImplementedInterface.Erc721,
    name: 'DeGods',
    symbol: 'DEGODS',
  },
  {
    address: '0x769272677faB02575E84945F03Eca517ACc544Cc',
    chain: 1,
    implementedInterfaces: ImplementedInterface.Erc721,
    name: 'Captainz',
    symbol: 'Captainz',
  },
  {
    address: '0xB6a37b5d14D502c3Ab0Ae6f3a0E058BC9517786e',
    chain: 1,
    implementedInterfaces: ImplementedInterface.Erc721,
    name: 'Azuki Elementals',
    symbol: 'ELEM',
  },
  {
    address: '0x23581767a106ae21c074b2276D25e5C3e136a68b',
    chain: 1,
    implementedInterfaces: ImplementedInterface.Erc721,
    name: 'Moonbirds',
    symbol: 'MOONBIRD',
  },
  {
    address: '0xba30e5f9bb24caa003e9f2f0497ad287fdf95623',
    chain: 1,
    implementedInterfaces: ImplementedInterface.Erc721,
    name: 'Bored Ape Kennel Club',
    symbol: 'BAKC',
  },
  {
    address: '0x49cF6f5d44E70224e2E23fDcdd2C053F30aDA28B',
    chain: 1,
    implementedInterfaces: ImplementedInterface.Erc721,
    name: 'CloneX',
    symbol: 'CloneX',
  },
  {
    address: '0x8a90CAb2b38dba80c64b7734e58Ee1dB38B8992e',
    chain: 1,
    implementedInterfaces: ImplementedInterface.Erc721,
    name: 'Doodles',
    symbol: 'DOODLe',
  },
  {
    address: '0xbd3531da5cf5857e7cfaa92426877b022e612cf8',
    chain: 1,
    implementedInterfaces: ImplementedInterface.Erc721,
    name: 'Pudgy Penguins',
    symbol: 'PPG',
  },
  {
    address: '0x7bd29408f11d2bfc23c34f18275bbf23bb716bc7',
    chain: 1,
    implementedInterfaces: ImplementedInterface.Erc721,
    name: 'Meebits',
    symbol: '⚇',
  },
];
