import { ExplorerRequestFilterPayload } from 'types/Explorer';

export interface MatchingWallet {
  address: string;
  balanceEth: number;
  balanceUsd: number;
  erc20BalanceUsd: number;
  erc721BalanceUsd: number;
  firstTransactionDate: string;
  lastTransactionDate: string;
  nftsOwned: number;
  numberOfTransactions: number;
}

// Enum sent over to BE in order to filter data
export enum ExplorerCrmColumnId {
  None = 0,
  Status = 1,
  WalletAddress = 2,
  Balance = 3,
  Transactions = 4,
  NftTransactions = 5,
  LastActivity = 6,
  FirstActivity = 7,
  BalanceUsd = 8,
  TokenErc20Balance = 9,
  NftBalance = 10,
}

export interface ExplorerCrmPayload {
  chain: number;
  filter: ExplorerRequestFilterPayload;
  skip: number;
  limit: number;
  orderBy: ExplorerCrmColumnId | null;
  ascending: boolean;
}
