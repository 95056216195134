import * as yup from 'yup';

export const feedbackFormSchema = yup.object().shape({
  description: yup.string().required('Please enter a description'),
  file: yup.mixed().test('fileSize', 'File too large', (value) => {
    if (!value || !value.length) return true;

    const file = value[0] as File;
    return file && file.size <= 1024 * 1024 * 5;
  }),
});
