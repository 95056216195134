export default function BaseSmIcon() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_11168_5220)">
        <path
          d="M6.98768 14C10.8606 14 14 10.8662 14 7C14 3.13376 10.8606 0 6.98768 0C3.31352 0 0.2996 2.82128 0 6.41144H9.26856V7.58856H0C0.2996 11.1787 3.31352 14 6.98768 14Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_11168_5220">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
