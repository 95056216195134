import dayjs from 'dayjs';
import { create, StateCreator } from 'zustand';
import { persist } from 'zustand/middleware';

import { EventDimensionType } from 'components/views/website/analytics/events/types';
import { ActivityRange } from 'types/Filters';
import { DimensionType } from 'types/WebsiteAnalytics/acquisition';

import { _defaultValues } from '../FilterPanel/WebsiteAdvancedFilterDropdown/form';
import { AdvancedFilters } from '../FilterPanel/WebsiteAdvancedFilterDropdown/types';

export type DashboardStore = {
  activity: {
    time: {
      from: string | null;
      to: string | null;
    };
    range: ActivityRange | null;
  };
  setActivity: (type: 'from' | 'to', date: string | null) => void;
  setActivityRange: (
    range: ActivityRange | null,
    from: string | null,
    to: string | null,
  ) => void;
  clearAllFilters: () => void;
  selectedSiteIds: string[];
  areAllSitesSelected: boolean;
  acquisitionReportPrimaryDimension: DimensionType;
  acquisitionReportSecondaryDimension: DimensionType | null;
  eventsReportPrimaryDimension: EventDimensionType;
  eventsReportSecondaryDimension: EventDimensionType | null;
  advancedFilters: AdvancedFilters;

  setAcquisitionReportPrimaryDimension: (value: DimensionType) => void;
  setAcquisitionReportSecondaryDimension: (value: DimensionType | null) => void;

  setEventsReportPrimaryDimension: (value: EventDimensionType) => void;
  setEventsReportSecondaryDimension: (value: EventDimensionType | null) => void;

  saveSelectedSiteIds: (id: string[]) => void;
  setAreAllSitesSelected: (value: boolean) => void;
  clear: () => void;
  saveAdvancedFilters: (filters: AdvancedFilters) => void;
  clearAdvancedFilters: () => void;
};

const prepareStore: StateCreator<DashboardStore> = (set) => ({
  selectedSiteIds: [],
  areAllSitesSelected: false,

  acquisitionReportPrimaryDimension: 'ReferrerType',
  acquisitionReportSecondaryDimension: null,
  eventsReportPrimaryDimension: 'EventCategory',
  eventsReportSecondaryDimension: null,

  advancedFilters: _defaultValues,
  saveAdvancedFilters: (filters) => set({ advancedFilters: filters }),

  clearAdvancedFilters: () =>
    set({
      advancedFilters: {
        userAgent: {
          ..._defaultValues.userAgent,
        },
        location: {
          ..._defaultValues.location,
        },
        trafficSource: {
          ..._defaultValues.trafficSource,
        },
        page: {
          ..._defaultValues.page,
        },
        utmParameters: {
          ..._defaultValues.utmParameters,
        },
      },
    }),

  setAcquisitionReportPrimaryDimension: (value) =>
    set({ acquisitionReportPrimaryDimension: value }),
  setAcquisitionReportSecondaryDimension: (value) =>
    set({ acquisitionReportSecondaryDimension: value }),
  setEventsReportPrimaryDimension: (value) =>
    set({ eventsReportPrimaryDimension: value }),
  setEventsReportSecondaryDimension: (value) =>
    set({ eventsReportSecondaryDimension: value }),
  saveSelectedSiteIds: (ids) => set({ selectedSiteIds: ids }),
  setAreAllSitesSelected: (value) => set({ areAllSitesSelected: value }),
  clear: () =>
    set({
      selectedSiteIds: [],
      areAllSitesSelected: false,
    }),
  activity: {
    time: {
      from: dayjs().subtract(7, 'day').toISOString(),
      to: dayjs().toISOString(),
    },
    range: '7d',
  },

  setActivity: (type, date) =>
    set((state) => ({
      activity: {
        ...state.activity,
        time: {
          ...state.activity.time,
          [type]: date,
        },
        range: 'custom',
      },
    })),
  setActivityRange: (range, from, to) =>
    set(() => ({
      activity: {
        time: {
          from,
          to,
        },
        range,
      },
    })),
  clearAllFilters: () =>
    set({
      activity: {
        time: {
          from: null,
          to: null,
        },
        range: null,
      },
    }),
  websiteId: null,
  onChainWebsiteId: null,
  campaignId: null,
  onChainCampaignId: null,
  searchQuery: '',
  utmLinkCodes: null,
  onChainUtmLinkCodes: null,
});

export const useDashboardStore = create(
  persist(prepareStore, {
    name: 'website-store',
    version: 1.1,
    partialize: (state) => ({
      acquisitionReportPrimaryDimension:
        state.acquisitionReportPrimaryDimension,
      acquisitionReportSecondaryDimension:
        state.acquisitionReportSecondaryDimension,
      activity: state.activity,
      areAllSitesSelected: state.areAllSitesSelected,
      selectedSiteIds: state.selectedSiteIds,
    }),
  }),
);
