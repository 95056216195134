import { CHAINS_NAME_ID_PAIR } from 'config/chains/config';

import AstarIcon from 'components/views/campaign-manager/goals/icons/AstarIcon';
import AstarSmIcon from 'components/views/campaign-manager/goals/icons/sm/Astar';
import MetisSmIcon from 'components/views/campaign-manager/goals/icons/sm/Metis';

import ArbitrumIcon from './icons/Arbitrum';
import AvalancheIcon from './icons/Avalanche';
import BaseIcon from './icons/Base';
import BlastIcon from './icons/Blast';
import BnbIcon from './icons/Bnb';
import ChilizIcon from './icons/Chiliz';
import EthereumIcon from './icons/Ethereum';
import FantomIcon from './icons/Fantom';
import LineaIcon from './icons/Linea';
import MantaIcon from './icons/Manta';
import MantleIcon from './icons/Mantle';
import MetisIcon from './icons/Metis';
import ModeIcon from './icons/Mode';
import OptimismIcon from './icons/Optimism';
import PolygonIcon from './icons/Polygon';
import RoninIcon from './icons/Ronin';
import ScrollIcon from './icons/Scroll';
import ArbitrumSmIcon from './icons/sm/Arbitrum';
import AvalancheSmIcon from './icons/sm/Avalanche';
import BaseSmIcon from './icons/sm/Base';
import BlastSmIcon from './icons/sm/Blast';
import BnbSmIcon from './icons/sm/Bnb';
import ChilizSmIcon from './icons/sm/Chiliz';
import EthereumSmIcon from './icons/sm/Ethereum';
import FantomSmIcon from './icons/sm/Fantom';
import LineaSmIcon from './icons/sm/Linea';
import MantaSmIcon from './icons/sm/Manta';
import MantleSmIcon from './icons/sm/Mantle';
import ModeSmIcon from './icons/sm/Mode';
import OptimismSmIcon from './icons/sm/Optimism';
import PolygonSmIcon from './icons/sm/Polygon';
import RoninSmIcon from './icons/sm/Ronin';
import ScrollSmIcon from './icons/sm/Scroll';
import { XLayerSmIcon } from './icons/sm/XLayer';
import { XLayerIcon } from './icons/XLayer';

export const CHAINS = new Map<number, Chain>([
  [
    CHAINS_NAME_ID_PAIR.Ethereum,
    {
      name: 'Ethereum',
      Icon: EthereumIcon,
      IconSm: EthereumSmIcon,
      primaryColor: '#4C5EF2',
      secondaryColor: '#5081E7',
    },
  ],
  [
    CHAINS_NAME_ID_PAIR['BNB Chain'],
    {
      name: 'BNB Chain',
      Icon: BnbIcon,
      IconSm: BnbSmIcon,
      primaryColor: '#F0B90B',
      secondaryColor: '#D7A300',
    },
  ],
  [
    CHAINS_NAME_ID_PAIR.Polygon,
    {
      name: 'Polygon',
      Icon: PolygonIcon,
      IconSm: PolygonSmIcon,
      primaryColor: '#8247E5',
      secondaryColor: '#7039CC',
    },
  ],
  [
    CHAINS_NAME_ID_PAIR.Avalanche,
    {
      name: 'Avalanche',
      Icon: AvalancheIcon,
      IconSm: AvalancheSmIcon,
      primaryColor: '#E84142',
      secondaryColor: '#D21A1B',
    },
  ],
  [
    CHAINS_NAME_ID_PAIR.Ronin,
    {
      name: 'Ronin',
      Icon: RoninIcon,
      IconSm: RoninSmIcon,
      primaryColor: '#1273EA',
      secondaryColor: '#398FF9',
    },
  ],
  [
    CHAINS_NAME_ID_PAIR.Fantom,
    {
      name: 'Fantom',
      Icon: FantomIcon,
      IconSm: FantomSmIcon,
      primaryColor: '#13B5EC',
      secondaryColor: '#009FD4',
    },
  ],
  [
    CHAINS_NAME_ID_PAIR.Arbitrum,
    {
      name: 'Arbitrum',
      Icon: ArbitrumIcon,
      IconSm: ArbitrumSmIcon,
      primaryColor: '#12AAFF',
      secondaryColor: '#008CDA',
    },
  ],
  [
    CHAINS_NAME_ID_PAIR.Base,
    {
      name: 'Base',
      Icon: BaseIcon,
      IconSm: BaseSmIcon,
      primaryColor: `#0052FF`,
      secondaryColor: `#568CFF`,
    },
  ],
  [
    CHAINS_NAME_ID_PAIR.Mantle,
    {
      name: 'Mantle',
      Icon: MantleIcon,
      IconSm: MantleSmIcon,
      primaryColor: '#000000',
      secondaryColor: '#000000',
    },
  ],
  [
    CHAINS_NAME_ID_PAIR.Goerli,
    {
      name: 'Goerli',
      Icon: EthereumIcon,
      IconSm: EthereumSmIcon,
      primaryColor: '#4C5EF2',
      secondaryColor: '#5081E7',
    },
  ],
  [
    CHAINS_NAME_ID_PAIR['Mantle Testnet'],
    {
      name: 'Mantle Testnet',
      Icon: MantleIcon,
      IconSm: MantleSmIcon,
      primaryColor: '#000000',
      secondaryColor: '#000000',
    },
  ],
  [
    CHAINS_NAME_ID_PAIR.Optimism,
    {
      name: 'Optimism',
      Icon: OptimismIcon,
      IconSm: OptimismSmIcon,
      primaryColor: '#EA3431',
      secondaryColor: '#EA3431',
    },
  ],
  [
    CHAINS_NAME_ID_PAIR.Linea,
    {
      name: 'Linea',
      Icon: LineaIcon,
      IconSm: LineaSmIcon,
      primaryColor: '#048fc5',
      secondaryColor: '#0d506d',
    },
  ],
  [
    88888,
    {
      name: 'Chiliz',
      Icon: ChilizIcon,
      IconSm: ChilizSmIcon,
      primaryColor: '#FF1256',
      secondaryColor: '#FF3971',
    },
  ],
  [
    169,
    {
      name: 'Manta',
      Icon: MantaIcon,
      IconSm: MantaSmIcon,
      primaryColor: '#23C2C6',
      secondaryColor: '#2E8AF2',
    },
  ],
  [
    168587773,
    {
      name: 'Blast Testnet',
      Icon: BlastIcon,
      IconSm: BlastSmIcon,
      primaryColor: '#000000',
      secondaryColor: '#000000',
    },
  ],
  [
    196,
    {
      name: 'X Layer',
      Icon: XLayerIcon,
      IconSm: XLayerSmIcon,
      primaryColor: '#000000',
      secondaryColor: '#000000',
    },
  ],
  [
    3776,
    {
      name: 'Astar Network',
      Icon: AstarIcon,
      IconSm: AstarSmIcon,
      primaryColor: '#00d1ff',
      secondaryColor: '#0047ff',
    },
  ],
  [
    1088,
    {
      name: 'Metis',
      Icon: MetisIcon,
      IconSm: MetisSmIcon,
      primaryColor: '#00DACC',
      secondaryColor: '#313042',
    },
  ],
  [
    81457,
    {
      name: 'Blast',
      Icon: BlastIcon,
      IconSm: BlastSmIcon,
      primaryColor: '#000000',
      secondaryColor: '#000000',
    },
  ],
  [
    34443,
    {
      name: 'Mode',
      Icon: ModeIcon,
      IconSm: ModeSmIcon,
      primaryColor: '#000000',
      secondaryColor: '#000000',
    },
  ],
  [
    534352,
    {
      name: 'Scroll',
      Icon: ScrollIcon,
      IconSm: ScrollSmIcon,
      primaryColor: '#E6BE90',
      secondaryColor: '#8A7257',
    },
  ],
]);

export const CHAIN_IDS = [...CHAINS.keys()];

export interface Chain {
  name: string;
  Icon: React.ComponentType<any> | string;
  IconSm: React.ComponentType<any> | string;
  primaryColor: string;
  secondaryColor: string;
}
