import merge from 'lodash/merge';
import { create, StateCreator } from 'zustand';
import {
  createJSONStorage,
  persist,
  subscribeWithSelector,
} from 'zustand/middleware';

import {
  ExplorerStoreI,
  Filters,
} from 'components/views/explorer/store/explorerStore.interface';
import { convertFiltersFromApiResIntoStore } from 'components/views/explorer/store/utils/convertFiltersFromApiResIntoStore';
import { ExplorerCrmColumnId } from 'types/Explorer/Crm';

import { getIntialStateForFilters } from './utils/getDefaultFilterValue';

const initialFilters: Filters = {};

const initialCrmColumnFilter = {
  direction: 'asc',
  columnId: ExplorerCrmColumnId.None,
} as const;

const prepareStore: StateCreator<ExplorerStoreI> = (set) => ({
  appliedFilters: initialFilters,
  temporaryFilters: initialFilters,
  changeTemporaryFilters: (key, value) =>
    set((state) => ({
      temporaryFilters: {
        ...state.temporaryFilters,
        [key]: value,
      },
    })),
  deleteTemporaryFilter: (key) =>
    set((state) => {
      const { [key]: deletedFilter, ...remainingFilters } =
        state.temporaryFilters;
      return {
        temporaryFilters: remainingFilters,
      };
    }),
  applyNewFilters: () =>
    set((state) => ({
      appliedFilters: state.temporaryFilters,
    })),
  applyFilter: (key) =>
    set((state) => {
      const appliedFilters = merge({}, state.appliedFilters, {
        [key]: state.temporaryFilters[key],
      });
      return {
        appliedFilters,
      };
    }),
  applyAudienceToFilters: (audienceFilters, chainId) => {
    const filters = convertFiltersFromApiResIntoStore(audienceFilters, chainId);

    set(() => {
      return {
        appliedFilters: filters,
        temporaryFilters: filters,
        crmColumnFilter: initialCrmColumnFilter,
      };
    });

    return filters;
  },
  crmColumnFilter: initialCrmColumnFilter,
  toggleCrmColumnFilter: (columnId) =>
    set((state) => ({
      crmColumnFilter: {
        direction: state.crmColumnFilter?.direction === 'asc' ? 'desc' : 'asc',
        columnId,
      },
    })),
  removeAllFilters: () =>
    set({
      crmColumnFilter: initialCrmColumnFilter,
      appliedFilters: initialFilters,
      temporaryFilters: initialFilters,
    }),
  setInitialFiltersState: (chainId) =>
    set((state) => {
      const filters = getIntialStateForFilters(chainId);
      filters.offChainHelper = state.temporaryFilters.offChainHelper;

      return {
        temporaryFilters: filters,
        appliedFilters: filters,
        crmColumnFilter: initialCrmColumnFilter,
      };
    }),
});

export const useExplorerStore = create(
  subscribeWithSelector(
    persist(prepareStore, {
      name: 'explorer-store',
      storage: createJSONStorage(() => sessionStorage),
      partialize: (state) => ({
        appliedFilters: state.appliedFilters,
        temporaryFilters: state.temporaryFilters,
        crmColumnFilter: state.crmColumnFilter,
      }),
      version: 1.8,
    }),
  ),
);
