import type Stripe from 'stripe';
import useSWR from 'swr';

import fetcher from 'utils/fetcher';

export interface TeamInvoicesRes {
  details: Stripe.Subscription & { plan: Stripe.Plan };
}

// returns empty details when subscription is created manually by admin or it's a demo team
export const useGetTeamSubscriptionDetails = ({
  isSubscriptionActive,
  teamId,
}: {
  teamId?: string;
  isSubscriptionActive: boolean;
}) => {
  const { data, isLoading, mutate } = useSWR<TeamInvoicesRes>(
    isSubscriptionActive && teamId
      ? `/api/teamManagement/${teamId}/payments/subscription`
      : null,
    fetcher,
  );

  const details = data?.details;

  const selectedPlanId = details?.items.data[0].plan.id;

  const endingDate =
    !!details?.current_period_end &&
    new Date(details.current_period_end * 1000);

  const cancellationDetails = {
    isCanceled: details?.cancel_at_period_end,
    date: !!details?.canceled_at && new Date(details?.canceled_at * 1000),
  };

  const isTrialActive = details?.status === 'trialing';

  return {
    details,
    isLoading,
    mutate,
    selectedPlanId,
    endingDate,
    cancellationDetails,
    isTrialActive,
  };
};
