import { useEffect, useState } from 'react';

import { getStorageData, setStorageData } from 'utils/storage';
import { AudienceDetails } from 'services/explorer/audiences/useGetAudiences';
import { ContractDetails } from 'types/SegmentBuilder';

const STORAGE_KEY = 'explorerRecentlySelected';

export type RecentlySelectedItem = ContractDetails | AudienceDetails;

export const useManageRecentlySelected = () => {
  const [recentlySelected, setRecentlySelected] = useState<
    RecentlySelectedItem[]
  >([]);

  useEffect(() => {
    const storedValue = getStorageData(STORAGE_KEY);

    if (storedValue && Array.isArray(storedValue)) {
      // Limit the stored values to the last 5 items
      const limitedValues = storedValue.slice(-5);
      setRecentlySelected(limitedValues);
    }
  }, []);

  const updateRecentlySelectedOptions = (
    selectedValue: RecentlySelectedItem,
  ) => {
    const updateLocalStorageAndState = () => {
      const updatedRecentlySelected = [
        selectedValue,
        ...recentlySelected.slice(0, 4),
      ];
      setRecentlySelected(updatedRecentlySelected);

      setStorageData(STORAGE_KEY, updatedRecentlySelected);
    };

    if ('address' in selectedValue) {
      // Do not add duplicates
      if (
        recentlySelected
          .filter((i) => 'address' in i && !!i?.address)
          ?.find(
            (i) => (i as ContractDetails).address === selectedValue.address,
          )
      )
        return;
    }

    if ('audienceDefinition' in selectedValue) {
      // Do not add duplicates
      if (
        recentlySelected
          .filter((i) => 'audienceDefinition' in i && !!i?.audienceDefinition)
          ?.find(
            (i) =>
              (i as AudienceDetails).audienceDefinition.id ===
              selectedValue.audienceDefinition.id,
          )
      )
        return;
    }

    updateLocalStorageAndState();
  };

  const clearRecentlySelectedOptions = () => {
    setRecentlySelected([]);
    setStorageData(STORAGE_KEY, []);
  };

  return {
    updateRecentlySelectedOptions,
    clearRecentlySelectedOptions,
    recentlySelected,
  };
};
