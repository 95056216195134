import { useCallback, useEffect } from 'react';

import { useDashboardStore } from 'components/views/dashboard/store/dashboardStore';
import { useManageRecentlySelected } from 'components/views/explorer/common/hooks/useManageRecentlySelected';
import { useExplorerStore } from 'components/views/explorer/store/explorerStore';
import { useSocialTrackingStore } from 'components/views/social-tracking/store/socialTrackingStore';

import { useTeamStore } from '../teamStore';

export const useTeamStoreSubscription = () => {
  const saveSelectedSiteIds = useDashboardStore(
    (state) => state.saveSelectedSiteIds,
  );

  const setAreAllSitesSelected = useDashboardStore(
    (state) => state.setAreAllSitesSelected,
  );

  const setLastVisitedProjectId = useSocialTrackingStore(
    (state) => state.setLastVisitedProjectId,
  );

  const recentlySelected = useManageRecentlySelected();
  const { removeAllFilters: removeAllExplorerFilters } = useExplorerStore();

  const removeAllFilters = useCallback(() => {
    saveSelectedSiteIds([]);
    setAreAllSitesSelected(false);
    recentlySelected.clearRecentlySelectedOptions();
    removeAllExplorerFilters();
    setLastVisitedProjectId(null);
  }, [
    saveSelectedSiteIds,
    setAreAllSitesSelected,
    recentlySelected,
    removeAllExplorerFilters,
    setLastVisitedProjectId,
  ]);

  useEffect(() => {
    const unsubscribe = useTeamStore.subscribe(removeAllFilters);

    return () => {
      unsubscribe();
    };
  }, [removeAllFilters]);
};
