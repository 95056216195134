import { Box, SxProps, Theme } from '@mui/material';

export default function PopoverContainer(
  props: React.ComponentProps<typeof Box>,
) {
  return (
    <Box
      {...props}
      sx={{
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        borderRadius: 3,
        border: '1px solid',
        borderColor: 'neutral.300',
        bgcolor: 'background.paper',
        height: 'auto',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        zIndex: 200,
        ...props.sx,
      }}
    />
  );
}
