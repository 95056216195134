export default function ChilizSmIcon() {
  return (
    <svg
      width="8"
      height="16"
      viewBox="0 0 8 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.350151 3.77618L2.63519 2.37C2.8344 2.25282 2.8344 2.01845 2.69378 1.87784L0.783723 0.0263659C0.748569 -0.00878863 0.689978 -0.00878863 0.643105 0.0263659L0.467333 0.213857C0.232969 0.44822 0.232969 0.823201 0.467333 1.05756L1.2173 1.74894C1.3462 1.87784 1.32276 2.08876 1.17042 2.18251L0.279842 2.73326C-0.0013939 2.90903 -0.0834211 3.2723 0.0923514 3.54182L0.221251 3.76446C0.244688 3.81133 0.303278 3.82305 0.350151 3.79962V3.77618Z"
        fill="white"
      />
      <path
        d="M2.5649 11.3461C2.75239 10.5376 2.52974 9.68214 1.96727 9.06108L0.525936 7.42053C-0.282618 6.50652 -0.0834087 5.10034 0.94779 4.44412L3.31486 2.93248C3.4672 2.83873 3.6664 2.85045 3.7953 2.97935C4.66245 3.77618 7.6623 6.64714 7.5334 9.61183C7.5334 9.61183 7.5334 14.2171 2.99847 15.9396C2.33053 16.1974 1.6626 15.5881 1.83837 14.8967C2.45943 12.4125 2.57662 11.3461 2.57662 11.3461H2.5649ZM3.15081 4.25663C2.78754 4.49099 2.09617 4.92456 1.49854 5.31126C1.10013 5.56906 1.0181 6.1081 1.32277 6.47136L2.83441 8.21737C3.70156 9.16654 4.04139 10.479 3.74843 11.7328L3.49063 12.846C3.38517 13.3265 3.94764 13.6663 4.32262 13.3382C5.91629 11.9672 8.14274 8.66266 3.49063 4.29178C3.39689 4.19804 3.25627 4.18632 3.13909 4.25663H3.15081Z"
        fill="white"
      />
    </svg>
  );
}
