import { v4 as uuidv4 } from 'uuid';

import {
  ActivityEntry,
  Filters,
} from 'components/views/explorer/store/explorerStore.interface';
import { ChainId } from 'types/Chain';

export const getDefaultTokensHeldEntry = (): NonNullable<
  Filters['tokensHeld']
>[number] => ({
  id: uuidv4(),
  greaterThan: true,
  value: null,
  typeOfValue: 'raw',
  address: null,
  chain: null,
});

export const getDefaultActivityEntry = (chainId: ChainId): ActivityEntry => ({
  id: uuidv4(),
  chain: chainId,
  value: null,
  greaterThan: false,
});

export const getDefaultBalanceEntry = (
  chainId: ChainId,
): NonNullable<Filters['balance']>[number] => ({
  id: uuidv4(),
  chain: chainId,
  value: null,
  greaterThan: false,
});

export const getDefaultBoughtSoldEntry = (): NonNullable<
  Filters['boughtSoldFilter']
>[number] => ({
  id: uuidv4(),
  address: null,
  chain: null,
  bought: true,
  timeRangeCondition: {
    from: null,
    to: null,
  },
  isValueUsdGreaterThan: true,
  valueUsd: null,
});

export const getDefaultInteractedWithContract = (
  chainId: ChainId,
): NonNullable<Filters['interactedWithContract']>[number] => ({
  id: uuidv4(),
  chain: chainId,
  address: '',
  timeRangeCondition: {
    from: null,
    to: null,
  },
});

export const getIntialStateForFilters = (chainId: ChainId): Filters => {
  return {
    balance: [getDefaultBalanceEntry(chainId)],
    boughtSoldFilter: [getDefaultBoughtSoldEntry()],
    firstActivity: [getDefaultActivityEntry(chainId)],
    lastActivity: [getDefaultActivityEntry(chainId)],
    tokensHeld: [getDefaultTokensHeldEntry()],
    interactedWithContract: [getDefaultInteractedWithContract(chainId)],
    manualSourcesFilter: {
      sources: [],
    },
    website: {
      sitesIds: [],
    },
    campaign: {
      equalFilterCampaignContent: [],
      equalFilterCampaignName: [],
      equalFilterCampaignKeyword: [],
      equalFilterCampaignSource: [],
      equalFilterCampaignMedium: [],
    },
    location: {
      equalFilterCountry: [],
      equalFilterContinent: [],
      equalFilterRegion: [],
      equalFilterCity: [],
    },
    browserAndOS: {
      equalDeviceType: [],
      equalBrowser: [],
    },
    trafficSource: {
      equalFilterReferrerUrl: [],
      equalFilterReferrerType: [],
      equalFilterReferrerName: [],
    },
  };
};
