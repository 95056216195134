import { ComponentType } from 'react';
import { IconBaseProps } from 'react-icons';
import {
  PiArrowUpRight,
  PiBinoculars,
  PiChartBarHorizontal,
  PiFlowArrow,
  PiHouse,
  PiPulseFill,
  PiShareNetworkFill,
  PiUserList,
  PiUsersThree,
} from 'react-icons/pi';
import { StepType } from '@reactour/tour';

export const onboardingSteps: Array<
  StepType & { title: string; Icon?: ComponentType<IconBaseProps> }
> = [
  {
    selector: '.onboarding-step-sidebar',
    position: [80, 80],
    padding: 0,
    title: 'Navigation',
    content:
      'This is the sidebar, you can navigate through the platform using the links here.',
    Icon: PiArrowUpRight,
  },
  {
    selector: '.onboarding-step-homepage',
    position: 'right',
    title: 'Homepage',
    content:
      'This is the homepage, we will keep you updated with the latest news and updates here.',
    Icon: PiHouse,
  },
  {
    selector: '.onboarding-step-website',
    position: 'right',
    title: 'Website analytics',
    content:
      'This is the website section, you can add your website here and start tracking your users.',
    Icon: PiChartBarHorizontal,
  },
  {
    selector: '.onboarding-step-explorer',
    position: 'right',
    title: 'On-chain explorer',
    content:
      'This is the explorer section, you can explore the any on-chain community and get insights about it in real-time.',
    Icon: PiBinoculars,
  },
  {
    selector: '.onboarding-step-audience',
    position: 'right',
    title: 'Audiences',
    Icon: PiUserList,
    content:
      'Using audiences you can create custom on-chain and off-chain segments of your users.',
  },
  {
    selector: '.onboarding-step-advertise',
    Icon: PiPulseFill,
    position: 'right',
    title: 'Advertise',
    content:
      'Here you can create and run X (formerly Twitter) ads based on your custom audiences. Yes, you can target your users on X based on their on-chain activity!',
  },
  {
    selector: '.onboarding-step-campaigns',
    Icon: PiShareNetworkFill,
    position: 'right',
    title: 'Campaigns',
    content:
      'Here you can setup your custom campaigns using links that point to your website. You can track the performance of your campaigns in real-time.',
  },
  {
    selector: '.onboarding-step-goals',
    Icon: PiFlowArrow,
    position: 'right',
    title: 'Conversion events',
    content:
      'By using conversion events you can track the completion of certain on-chain and off-chain events that take place on your website or even after your visitors leave. You can use conversion events to track the conversion of your campaigns and your website in general.',
  },
  {
    selector: '.onboarding-step-team',
    Icon: PiUsersThree,
    position: 'right',
    title: 'Teams & members',
    content:
      'Here you can invite your team members and manage their roles and permissions. You can also select to visit the "Demo" team, which is a team that we created for you to explore the platform when used to a full extent.',
  },
];
