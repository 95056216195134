import { IoSettingsOutline, IoSettingsSharp } from 'react-icons/io5';
import {
  PiBinoculars,
  PiBinocularsFill,
  PiBrowsers,
  PiBrowsersFill,
  PiChartBarHorizontal,
  PiChartBarHorizontalFill,
  PiCrosshair,
  PiCrosshairFill,
  PiDownloadSimple,
  PiHouse,
  PiHouseFill,
  PiListMagnifyingGlass,
  PiListMagnifyingGlassFill,
  PiMegaphoneSimple,
  PiMegaphoneSimpleFill,
  PiPresentationChart,
  PiPresentationChartFill,
  PiRocketLaunch,
  PiRocketLaunchBold,
  PiShield,
  PiShieldFill,
  PiSignIn,
  PiUserFocus,
  PiUserFocusBold,
} from 'react-icons/pi';
import { useRouter } from 'next/router';
import { Box, Divider, Stack } from '@mui/material';
import { useIsLoggedIn } from 'src/store/AppProvider';

import Scrollable from 'common/Scrollable';
import UserPanel from 'components/Dashboard/NewSidebar/Content/UserPanel';
import SlashButton from 'components/primitives/Button/SlashButton';
import HStack from 'components/primitives/HStack';
import Text from 'components/primitives/Text';
import useLoginModal from 'components/views/login/hooks/useLoginModal';

import SidebarItem from '../Item';

const ROUTES_WEBSITE = [
  {
    label: 'Campaigns',
    icon: PiPresentationChart,
    iconSelected: PiPresentationChartFill,
    href: '/campaigns',
    baseHref: '/campaigns',
    className: 'onboarding-step-campaigns',
  },

  {
    label: 'Conversion events',
    icon: PiCrosshair,
    iconSelected: PiCrosshairFill,
    href: '/goals',
    baseHref: '/goals',
    className: 'onboarding-step-goals',
  },
  {
    label: 'Advertise',
    icon: PiMegaphoneSimple,
    iconSelected: PiMegaphoneSimpleFill,
    href: '/advertise',
    baseHref: '/advertise',
    className: 'onboarding-step-advertise',
  },
];

interface Props {
  isSidebarOpen: boolean;
}

const isRouteActive = (route: string, pathname: string) => {
  if (route === '/') {
    return route === pathname;
  }

  // the /explorer/audiences/[audienceId] path should make the "explorer" navbar icon "active only"
  if (route === '/audiences')
    return pathname.includes(route) && !pathname.includes('/explorer');

  // the /website/manage path shouldn't make the "website" navbar icon "active"
  if (route === '/website' && pathname.includes('/website/manage')) {
    return false;
  }

  return pathname.includes(route);
};

export default function SidebarContent({ isSidebarOpen }: Props) {
  const { pathname, asPath } = useRouter();
  const loginModal = useLoginModal();

  const isLoggedIn = useIsLoggedIn();
  const shouldNavigateWithinTeam = isLoggedIn;
  return (
    <Stack height={'100%'} width="100%">
      <Scrollable disableDetectOverflow height="100%" sx={{ pb: 0 }}>
        <Stack height={'100%'} width="100%">
          <Stack gap={0.5}>
            <SidebarItem
              isOpen={isSidebarOpen}
              navigateWithinTeam={false}
              href="/"
              Icon={PiHouse}
              IconSelected={PiHouseFill}
              title="Home"
              active={isRouteActive('/', pathname)}
              className={'onboarding-step-homepage'}
            />
            <SidebarItem
              isOpen={isSidebarOpen}
              navigateWithinTeam={shouldNavigateWithinTeam}
              href={'/website'}
              Icon={PiChartBarHorizontal}
              IconSelected={PiChartBarHorizontalFill}
              title="Website analytics"
              active={isRouteActive('/website', pathname)}
              className={'onboarding-step-website'}
            />
            <SidebarItem
              isOpen={isSidebarOpen}
              navigateWithinTeam={shouldNavigateWithinTeam}
              href="/explorer"
              Icon={PiBinoculars}
              IconSelected={PiBinocularsFill}
              title="On-chain explorer"
              active={isRouteActive('/explorer', pathname)}
              className={'onboarding-step-explorer'}
            />
            {isLoggedIn && (
              <>
                <SidebarItem
                  isOpen={isSidebarOpen}
                  navigateWithinTeam={false}
                  href="/token"
                  Icon={PiListMagnifyingGlass}
                  IconSelected={PiListMagnifyingGlassFill}
                  title="Token analytics"
                  active={isRouteActive('/token', pathname)}
                />

                <SidebarItem
                  isOpen={isSidebarOpen}
                  navigateWithinTeam
                  href="/social-tracking"
                  Icon={PiUserFocus}
                  IconSelected={PiUserFocusBold}
                  title="Social tracking"
                  active={isRouteActive('/social-tracking', pathname)}
                />
              </>
            )}
            <SidebarItem
              isOpen={isSidebarOpen}
              href="/airdrop-shield"
              Icon={PiShield}
              IconSelected={PiShieldFill}
              title="Airdrop Shield"
              isNew
              active={isRouteActive('/airdrop-shield', pathname)}
            />
          </Stack>
          <Divider sx={{ my: 2, borderColor: 'neutral.400', opacity: 0.5 }} />
          <Stack mt={2} height="100%" flex={1} flexGrow={1} gap={0.5}>
            <Text
              size="xxs"
              color="tertiary"
              weight="medium"
              pl={0.75}
              sx={{
                transitionProperty: 'padding, letter-spacing',
                transitionTimingFunction: 'ease-in-out',
                transitionDuration: 100 + 'ms',
              }}
              letterSpacing={'0.5px'}
              mb={1.5}
            >
              ACTIONS
            </Text>
            {ROUTES_WEBSITE.map((route) => (
              <SidebarItem
                isOpen={isSidebarOpen}
                className={route.className}
                navigateWithinTeam={shouldNavigateWithinTeam}
                href={route.href}
                Icon={route.icon}
                IconSelected={route.iconSelected}
                title={route.label}
                key={route.label}
                active={isRouteActive(route.baseHref, pathname)}
              />
            ))}
          </Stack>
          <Stack pb={2} pt={4} mt="auto" gap={0.5}>
            {!isLoggedIn && (
              <SidebarItem
                navigateWithinTeam={shouldNavigateWithinTeam}
                isOpen={isSidebarOpen}
                href="/plans"
                Icon={PiRocketLaunch}
                IconSelected={PiRocketLaunchBold}
                title="Pricing plans"
                active={isRouteActive('/plans', pathname)}
              />
            )}

            {isLoggedIn && (
              <SidebarItem
                isOpen={isSidebarOpen}
                title="Import your users"
                navigateWithinTeam
                href={`/csv?from=${asPath}`}
                Icon={PiDownloadSimple}
                active={isRouteActive('/csv', pathname)}
              />
            )}
            {isLoggedIn && (
              <SidebarItem
                isOpen={isSidebarOpen}
                title="Manage websites"
                navigateWithinTeam
                href={`/website/manage?from=${asPath}`}
                Icon={PiBrowsers}
                IconSelected={PiBrowsersFill}
                active={isRouteActive('/website/manage', pathname)}
              />
            )}
            {isLoggedIn && (
              <SidebarItem
                isOpen={isSidebarOpen}
                title="Settings"
                navigateWithinTeam
                href={`/settings`}
                Icon={IoSettingsOutline}
                IconSelected={IoSettingsSharp}
                active={isRouteActive('/settings', pathname)}
              />
            )}
          </Stack>
        </Stack>
      </Scrollable>
      <Box position="sticky" bottom={0} bgcolor="background.paper">
        {isLoggedIn && (
          <Box
            sx={{
              py: 2,
              px: 1.5,
              pr: 2,
              borderTop: '1px solid',
              borderColor: 'neutral.400',
            }}
          >
            <UserPanel />
          </Box>
        )}
        {!isLoggedIn && (
          <Box
            sx={{
              py: 2,
              px: 1,
              pr: 2,
              borderTop: '1px solid',
              borderColor: 'neutral.400',
              position: 'relative',
              zIndex: 1,
            }}
          >
            <SlashButton iconPlacement="right" onClick={loginModal.open}>
              <HStack
                gap={2}
                vcenter
                fullHeight
                sx={{
                  fontSize: '12px !important',
                }}
              >
                {isSidebarOpen && (
                  <Box textAlign="left" mr={4}>
                    <Text
                      sx={{
                        lineHeight: '1 !important',
                      }}
                      color="white"
                      weight="medium"
                    >
                      Sign up
                    </Text>
                    <Text
                      mt={0.25}
                      sx={{
                        fontSize: '12px !important',
                      }}
                      color="white"
                      size="xs"
                    >
                      It&apos;s free!
                    </Text>
                  </Box>
                )}
                <PiSignIn />
              </HStack>
            </SlashButton>
          </Box>
        )}
      </Box>
    </Stack>
  );
}
