import { IconContext } from 'react-icons';
import { FiUser } from 'react-icons/fi';
import { HiOutlineLogout } from 'react-icons/hi';
import { TbUsersGroup } from 'react-icons/tb';
import {
  Box,
  Button,
  CircularProgress,
  DialogTitle,
  styled,
  useTheme,
} from '@mui/material';

import UnstyledLink from 'common/UnstyledLink';
import { useLogout } from 'utils/hooks/useLogout';

export default function AccountModal() {
  const { handleLogout, isLoading } = useLogout();
  const theme = useTheme();

  return (
    <Box
      bgcolor="white"
      boxShadow="tooltip"
      borderRadius={2}
      sx={{
        boxShadow: theme.shadows.elevation2,
        zIndex: 99,
      }}
    >
      <DialogTitle sx={{ p: '0px' }}>
        <IconContext.Provider value={{ color: 'neutral.600', size: '16px' }}>
          <Box
            sx={{ p: '8px 0' }}
            display="flex"
            alignItems="flex-start"
            rowGap="4px"
            flexDirection="column"
          >
            <UnstyledLink href="/settings/personal">
              <StyledButton fullWidth>
                <FiUser color="#8F95B2" />
                My Account
              </StyledButton>
            </UnstyledLink>
            <UnstyledLink href="/settings/team-management">
              <StyledButton fullWidth>
                <TbUsersGroup color="#8F95B2" />
                Teams
              </StyledButton>
            </UnstyledLink>
            <StyledButton onClick={handleLogout} disabled={isLoading} fullWidth>
              {isLoading ? (
                <CircularProgress
                  sx={{ color: '#000', opacity: 0.4 }}
                  size="16px"
                />
              ) : (
                <HiOutlineLogout color="#8F95B2" />
              )}
              Logout
            </StyledButton>
          </Box>
        </IconContext.Provider>
      </DialogTitle>
    </Box>
  );
}

const StyledButton = styled(Button)({
  padding: '8px 48px 8px 16px',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '10px',
  display: 'flex',
  fontSize: '14px',
  lineHeight: '20px',
  cursor: 'pointer',
  boxShadow: 'none',
  fontWeight: '400',
  color: '#474D66',
  height: 'auto',

  '&:hover': {
    opacity: '0.5',
  },
});
