import React from 'react';
import { Box } from '@mui/material';
import dayjs from 'dayjs';

import TooltipWrapper from 'common/TooltipWrapper';
import ConditionalLink from 'components/Dashboard/ConditionalLink';
import SlashButton from 'components/primitives/Button/SlashButton';
import Text from 'components/primitives/Text';
import { useGetTeamSubscriptionDetails } from 'services/teamManagement/payments/useGetTeamSubscriptionDetails';
import { useGetTeams } from 'services/teamManagement/useGetTeams';
import { cookie3Analytics } from 'lib/cookie3-analytics';

const current = new Date();

export default function UpgradePlanButton() {
  const { selectedTeam } = useGetTeams();
  const teamId = selectedTeam?.id;

  const { isTrialActive, endingDate } = useGetTeamSubscriptionDetails({
    teamId,
    isSubscriptionActive: !!selectedTeam?.isSubscriptionActive,
  });

  const numberOfDaysLeft =
    !!endingDate && dayjs(endingDate).diff(current, 'day');

  return (
    <TooltipWrapper
      content={
        selectedTeam?.isDemo ? 'Change your team to upgrade your plan.' : ''
      }
    >
      <Box>
        <ConditionalLink
          condition={!selectedTeam?.isDemo}
          href="/settings/billing"
          navigateWithinTeam
        >
          <SlashButton
            onClick={() => {
              cookie3Analytics.trackEvent({
                category: 'action',
                action: 'open billing in navbar',
              });
            }}
            sx={{
              height: '40px !important',
            }}
          >
            <Box>
              <Text
                color="white"
                width="100%"
                textAlign="left"
                sx={{
                  fontSize: '12px !important',
                  lineHeight: 1,
                  mr: 0.5,
                }}
              >
                {isTrialActive ? 'Free trial is active' : 'Upgrade plan'}
              </Text>
              <Text
                color="white"
                width="100%"
                textAlign="left"
                sx={{
                  fontSize: '10px !important',
                  opacity: 0.6,
                  lineHeight: 1,
                  mt: 0.25,
                  ml: 0,
                  mr: 1,
                }}
              >
                {isTrialActive
                  ? `${numberOfDaysLeft}d left, upgrade now`
                  : 'Go to billing'}
              </Text>
            </Box>
          </SlashButton>
        </ConditionalLink>
      </Box>
    </TooltipWrapper>
  );
}
