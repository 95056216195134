import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { PiFile, PiPaperclip, PiPaperPlane } from 'react-icons/pi';
import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  FormLabel,
  IconButton,
  styled,
  TextField,
  Typography,
} from '@mui/material';

import Button from 'components/primitives/Button';
import Text from 'components/primitives/Text';
import FileIcon from 'theme/icons/fileIcon';
import { SnackbarVariant } from 'utils/constants';
import SnackbarContext, { useSnackbar } from 'context/SnackbarContext';

import WidgetAction from '../Widget/WidgetAction';
import { prepareFeedbackForm } from './prepareForm';
import { feedbackFormSchema } from './schema';

interface Data {
  description: string;
  file?: FileList;
}

interface Props {
  onSuccess: () => void;
}

export default function FeedbackForm({ onSuccess }: Props) {
  const [loading, setLoading] = useState(false);
  const { register, watch, handleSubmit, reset } = useForm<Data>({
    resolver: yupResolver(feedbackFormSchema),
  });

  const snackbar = useSnackbar();

  const onSubmit = async (data: Data) => {
    setLoading(true);

    const formData = prepareFeedbackForm({ ...data });

    try {
      const res = await fetch(`/api/feedback/form`, {
        method: 'POST',
        body: formData,
      });

      if (res.status === 200) {
        onSuccess();
        snackbar.trigger({
          message: 'Feedback sent successfully! Thanks!',
          variant: SnackbarVariant.success,
        });
        reset();
      }
    } catch (err) {
      console.error('Error while sending email', err);
      snackbar.trigger({
        message: 'Sending email failed. Our team will investigate!',
        variant: SnackbarVariant.error,
      });
    } finally {
      setLoading(false);
    }
  };

  const file = watch('file')?.[0];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Text weight="medium">Encountered a bug? Have a suggestion?</Text>
      <Text size="xs" color="tertiary" mt={0.5} mb={2}>
        Let us know! We are always looking to improve our app!
      </Text>
      <FormLabel
        htmlFor="description"
        sx={{
          fontSize: '12px',
          color: 'text.primary',
          fontWeight: 500,
          display: 'block',
          mb: 1,
        }}
      >
        Description<span style={{ color: 'red' }}>*</span>
      </FormLabel>
      <TextField
        {...register('description')}
        placeholder="Describe your issue or suggestion here. Please be as detailed as possible. You can also attach a screenshot below. Thanks!"
        multiline
        rows={6}
        sx={{
          width: '100%',
          '& .MuiOutlinedInput-root': {
            fontSize: '12px',
          },
        }}
      />

      {file && (
        <Box
          mt={2}
          mb={-1}
          px={1.5}
          py={0.5}
          border={'1px solid #E0E0E0'}
          width="fit-content"
          borderRadius={5}
          display={'flex'}
          alignItems={'center'}
          columnGap={1}
        >
          <Box flexShrink={0} display="flex" alignItems="center">
            <PiFile />
          </Box>
          <Typography
            textOverflow={'ellipsis'}
            width={'100%'}
            sx={{
              display: 'inline',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              fontSize: '12px',
              maxWidth: 200,
            }}
          >
            {file?.name}
          </Typography>

          <IconButton
            aria-label="Remove attachment"
            onClick={() => reset({ file: undefined })}
            sx={{ p: 0, width: 'min-content', flexShrink: 0, ml: 1 }}
          >
            <CloseIcon
              sx={{
                width: 10,
                height: 10,
              }}
            />
          </IconButton>
        </Box>
      )}
      <Button
        isLabel
        aria-label="Add attachment"
        disableRipple
        disabled={loading}
        size="small"
        Icon={PiPaperclip}
        variant="lightOutlined"
        sx={{ mt: 2 }}
      >
        Add screenshot (optional)
        <VisuallyHiddenInput
          {...register('file')}
          type="file"
          accept="image/png, image/jpeg"
        />
      </Button>
      <WidgetAction
        aria-label="Send feedback"
        isLoading={loading}
        disabled={loading}
        loadingText="Sending..."
        type="submit"
        // size="small"
        fullWidth
        sx={{ mt: 2 }}
        size="small"
        Icon={PiPaperPlane}
        iconPlacement="right"
      >
        Send Feedback
      </WidgetAction>
    </form>
  );
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
