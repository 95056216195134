export const XLayerIcon = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="30" height="30" rx="15" fill="black" />
      <path
        d="M17.3133 12.3335H12.6911C12.4947 12.3335 12.3355 12.4927 12.3355 12.6891V17.3113C12.3355 17.5077 12.4947 17.6669 12.6911 17.6669H17.3133C17.5097 17.6669 17.6689 17.5077 17.6689 17.3113V12.6891C17.6689 12.4927 17.5097 12.3335 17.3133 12.3335Z"
        fill="white"
      />
      <path
        d="M11.9778 7.00003H7.35558C7.15922 7.00003 7 7.15918 7 7.35561V11.9778C7 12.1742 7.15922 12.3334 7.35558 12.3334H11.9778C12.1742 12.3334 12.3334 12.1742 12.3334 11.9778V7.35561C12.3334 7.15918 12.1742 7.00003 11.9778 7.00003Z"
        fill="white"
      />
      <path
        d="M22.6445 7.00003H18.0223C17.8259 7.00003 17.6667 7.15918 17.6667 7.35561V11.9778C17.6667 12.1742 17.8259 12.3334 18.0223 12.3334H22.6445C22.8409 12.3334 23.0001 12.1742 23.0001 11.9778V7.35561C23.0001 7.15918 22.8409 7.00003 22.6445 7.00003Z"
        fill="white"
      />
      <path
        d="M11.9778 17.6666H7.35558C7.15922 17.6666 7 17.8258 7 18.0221V22.6444C7 22.8408 7.15922 23 7.35558 23H11.9778C12.1742 23 12.3334 22.8408 12.3334 22.6444V18.0221C12.3334 17.8258 12.1742 17.6666 11.9778 17.6666Z"
        fill="white"
      />
      <path
        d="M22.6445 17.6666H18.0223C17.8259 17.6666 17.6667 17.8258 17.6667 18.0221V22.6444C17.6667 22.8408 17.8259 23 18.0223 23H22.6445C22.8409 23 23.0001 22.8408 23.0001 22.6444V18.0221C23.0001 17.8258 22.8409 17.6666 22.6445 17.6666Z"
        fill="white"
      />
    </svg>
  );
};
