export default function ModeIcon() {
  return (
    <img
      src="/icons/chains/mode.svg"
      width={31}
      height={31}
      alt="mode"
      style={{
        maxWidth: '100%',
        height: 'auto',
      }}
    />
  );
}
