import React from 'react';
import { PiList } from 'react-icons/pi';
import { Box } from '@mui/material';

import UnstyledLink from 'common/UnstyledLink';
import Button from 'components/primitives/Button';
import HStack from 'components/primitives/HStack';
import Cookie3Logo from 'theme/logo';

interface Props {
  onBurgerMenuClick: () => void;
}
export default function MobileHeader({ onBurgerMenuClick }: Props) {
  return (
    <div className="sticky top-0 z-[100] border-b border-neutral-300 bg-white/50 py-2 backdrop-blur-lg lg:hidden">
      <HStack vcenter px={2.5} gap={2} justifyContent={'space-between'}>
        <UnstyledLink href="/">
          <Box width="110px" height="27px">
            <Cookie3Logo />
          </Box>
        </UnstyledLink>
        <Box>
          <Button
            onClick={onBurgerMenuClick}
            variant="text"
            sx={{ py: 0, px: 0, color: 'text.secondary' }}
          >
            <Box>
              <PiList color="black" size="24px" />
            </Box>
          </Button>
        </Box>
      </HStack>
    </div>
  );
}
