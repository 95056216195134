export default function LineaSmIcon() {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="7.5" cy="7.5" r="7.5" fill="black" />
      <mask
        id="mask0_11024_2678"
        style={{
          maskType: 'luminance',
        }}
        maskUnits="userSpaceOnUse"
        x="5"
        y="4"
        width="6"
        height="6"
      >
        <path d="M10.7041 4H5V10H10.7041V4Z" fill="white" />
      </mask>
      <g mask="url(#mask0_11024_2678)">
        <path
          d="M9.73727 10H5V4.97363H6.08389V9.02589H9.73727V9.99951V10Z"
          fill="white"
        />
        <path
          d="M9.73636 5.94722C10.2703 5.94722 10.7032 5.51131 10.7032 4.9736C10.7032 4.4359 10.2703 4 9.73636 4C9.20241 4 8.76953 4.4359 8.76953 4.9736C8.76953 5.51131 9.20241 5.94722 9.73636 5.94722Z"
          fill="white"
        />
      </g>
    </svg>
  );
}
