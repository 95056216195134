import { createContext, PropsWithChildren, useContext, useState } from 'react';

const SidebarContext = createContext({
  isOpen: false,
  toggleSidebar: () => {},
  isPermanentClosed: false,
  transitionDuration: 100,
});

export default function useSidebar() {
  return useContext(SidebarContext);
}

export function SidebarContextProvider(
  props: PropsWithChildren<{
    isOpen: boolean;
    toggleSidebar: () => void;
    transitionDuration?: number;
    isPermanentClosed?: boolean;
  }>,
) {
  const { isOpen, toggleSidebar, transitionDuration } = props;

  return (
    <SidebarContext.Provider
      value={{
        isOpen,
        toggleSidebar,
        transitionDuration: transitionDuration ?? 300,
        isPermanentClosed: props.isPermanentClosed ?? false,
      }}
    >
      {props.children}
    </SidebarContext.Provider>
  );
}
