export default function FantomSmIcon() {
  return (
    <svg
      width="12"
      height="20"
      viewBox="0 0 12 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.975 7.04531L10.4625 5.01093V9.07968L6.975 7.04531ZM10.4625 15.7641L5.8125 18.4766L1.1625 15.7641V11.0172L5.8125 13.7297L10.4625 11.0172V15.7641ZM1.1625 5.01093L4.65 7.04531L1.1625 9.07968V5.01093ZM6.39375 8.01406L9.88125 10.0484L6.39375 12.0828V8.01406ZM5.23125 12.0828L1.74375 10.0484L5.23125 8.01406V12.0828ZM9.88125 4.04218L5.8125 6.36718L1.74375 4.04218L5.8125 1.62031L9.88125 4.04218ZM0 3.65468V16.3453L5.8125 19.6391L11.625 16.3453V3.65468L5.8125 0.360931L0 3.65468Z"
        fill="white"
      />
    </svg>
  );
}
