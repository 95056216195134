export const XLayerSmIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.02413 4.66685H4.97971C4.80789 4.66685 4.66858 4.8061 4.66858 4.97792V9.0224C4.66858 9.19421 4.80789 9.33353 4.97971 9.33353H9.02413C9.19595 9.33353 9.33526 9.19421 9.33526 9.0224V4.97792C9.33526 4.8061 9.19595 4.66685 9.02413 4.66685Z"
        fill="white"
      />
      <path
        d="M4.35555 3.8147e-05H0.311135C0.139318 3.8147e-05 0 0.139292 0 0.311171V4.35559C0 4.52741 0.139318 4.66672 0.311135 4.66672H4.35555C4.52743 4.66672 4.66668 4.52741 4.66668 4.35559V0.311171C4.66668 0.139292 4.52743 3.8147e-05 4.35555 3.8147e-05Z"
        fill="white"
      />
      <path
        d="M13.6889 3.8147e-05H9.64439C9.47257 3.8147e-05 9.33325 0.139292 9.33325 0.311171V4.35559C9.33325 4.52741 9.47257 4.66672 9.64439 4.66672H13.6889C13.8607 4.66672 13.9999 4.52741 13.9999 4.35559V0.311171C13.9999 0.139292 13.8607 3.8147e-05 13.6889 3.8147e-05Z"
        fill="white"
      />
      <path
        d="M4.35555 9.33328H0.311135C0.139318 9.33328 0 9.47254 0 9.64435V13.6888C0 13.8607 0.139318 14 0.311135 14H4.35555C4.52743 14 4.66668 13.8607 4.66668 13.6888V9.64435C4.66668 9.47254 4.52743 9.33328 4.35555 9.33328Z"
        fill="white"
      />
      <path
        d="M13.6889 9.33328H9.64439C9.47257 9.33328 9.33325 9.47254 9.33325 9.64435V13.6888C9.33325 13.8607 9.47257 14 9.64439 14H13.6889C13.8607 14 13.9999 13.8607 13.9999 13.6888V9.64435C13.9999 9.47254 13.8607 9.33328 13.6889 9.33328Z"
        fill="white"
      />
    </svg>
  );
};
