import { forwardRef } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  Slide,
  SxProps,
  Theme,
  useTheme,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  sx?: SxProps<Theme>;
  innerSx?: SxProps<Theme>;
  InnerPanelProps?: InnerPanelProps;
  disableEscapeKeyDown?: boolean;
  footer?: React.ReactNode;
  background?: 'primary' | 'accent';
  footerPadding?: number;
}

interface InnerPanelProps {
  isOpen?: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return (
    <Slide
      timeout={{
        enter: 100,
        exit: 100,
        appear: 100,
      }}
      direction="left"
      ref={ref}
      {...props}
    />
  );
});

export default function SidePanel({
  isOpen,
  onClose,
  children,
  InnerPanelProps,
  sx,
  innerSx,
  disableEscapeKeyDown,
  footer,
  background = 'primary',
  footerPadding = 3,
}: Props) {
  const { palette } = useTheme();

  return (
    <>
      <Dialog
        disableEscapeKeyDown={disableEscapeKeyDown}
        disableEnforceFocus
        disableAutoFocus
        open={isOpen}
        onClose={() => {
          if (InnerPanelProps?.isOpen) InnerPanelProps?.onClose?.();
          else {
            onClose();
          }
        }}
        BackdropProps={{
          sx: {
            backgroundColor: palette.neutral[300] + 'aa',
            opacity: 0.5,
          },
        }}
        TransitionComponent={Transition}
        sx={{
          p: 0,
          width: '100%',
          '& .MuiDialog-paper': {
            boxShadow: `-10px 4px 30px 0px rgba(0, 0, 0, 0.07)`,
            borderLeft: '1px solid',
            borderColor: 'neutral.400',
            m: 0,
            borderRadius: 0,
            height: '100%',
            maxHeight: '100%',

            width: '100%',
            transition: 'right 0.2s ease-in-out',
            right: '0px',
            // casted to any, because of a bug in MUI types
            ...(sx as any),
          },
          '& .MuiDialog-container': {
            justifyContent: 'flex-end',
          },
        }}
      >
        <DialogContent
          sx={{
            height: '100%',
            m: 0,
            p: 0,
            bgcolor:
              background === 'primary' ? 'background.paper' : 'neutral.100',
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            height={'100%'}
          >
            <Box p={3} sx={{ height: 'auto', ...innerSx }}>
              {children}
            </Box>
            {footer ? (
              <Box
                sx={{
                  mt: 'auto',
                  position: 'sticky',
                  bottom: '0px',
                  left: 0,
                  width: '100%',
                  bgcolor: 'background.paper',
                  boxShadow: '0px 12px 40px 0px rgba(16, 24, 64, 0.16)',
                  p: footerPadding,
                  borderTop: '1px solid',
                  borderColor: 'neutral.300',
                  zIndex: 100,
                }}
              >
                {footer}
              </Box>
            ) : null}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
