export default function ArbitrumIcon() {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_9885_18216)">
        <path
          d="M2.80273 9.42403V21.576C2.80273 22.3572 3.21193 23.064 3.89393 23.4608L14.4215 29.5368C15.0911 29.9212 15.9219 29.9212 16.5915 29.5368L27.1191 23.4608C27.7887 23.0764 28.2103 22.3572 28.2103 21.576V9.42403C28.2103 8.64283 27.8011 7.93603 27.1191 7.53923L16.5915 1.46323C15.9219 1.07883 15.0911 1.07883 14.4215 1.46323L3.89393 7.53923C3.22433 7.92363 2.81513 8.64283 2.81513 9.42403H2.80273Z"
          fill="#213147"
        />
        <path
          d="M17.7959 17.856L16.2955 21.9728C16.2583 22.0844 16.2583 22.2084 16.2955 22.3324L18.8747 29.4128L21.8631 27.6892L18.2795 17.856C18.1927 17.6328 17.8827 17.6328 17.7959 17.856Z"
          fill="#12AAFF"
        />
        <path
          d="M20.8088 10.9368C20.722 10.7136 20.412 10.7136 20.3252 10.9368L18.8248 15.0536C18.7876 15.1652 18.7876 15.2892 18.8248 15.4132L23.0532 27.0072L26.0416 25.2836L20.8088 10.9492V10.9368Z"
          fill="#12AAFF"
        />
        <path
          d="M15.5009 1.922C15.5753 1.922 15.6497 1.9468 15.7117 1.984L27.0949 8.556C27.2313 8.6304 27.3057 8.7792 27.3057 8.928V22.072C27.3057 22.2208 27.2189 22.3696 27.0949 22.444L15.7117 29.016C15.6497 29.0532 15.5753 29.078 15.5009 29.078C15.4265 29.078 15.3521 29.0532 15.2901 29.016L3.90692 22.444C3.77052 22.3696 3.69612 22.2208 3.69612 22.072V8.9156C3.69612 8.7668 3.78292 8.618 3.90692 8.5436L15.2901 1.9716C15.3521 1.9344 15.4265 1.9096 15.5009 1.9096V1.922ZM15.5009 0C15.0917 0 14.6949 0.0992 14.3229 0.31L2.93972 6.882C2.20812 7.3036 1.76172 8.0724 1.76172 8.9156V22.0596C1.76172 22.9028 2.20812 23.6716 2.93972 24.0932L14.3229 30.6652C14.6825 30.876 15.0917 30.9752 15.5009 30.9752C15.9101 30.9752 16.3069 30.876 16.6789 30.6652L28.0621 24.0932C28.7937 23.6716 29.2401 22.9028 29.2401 22.0596V8.9156C29.2401 8.0724 28.7937 7.3036 28.0621 6.882L16.6665 0.31C16.3069 0.0992 15.8977 0 15.4885 0H15.5009Z"
          fill="#9DCCED"
        />
        <path
          d="M7.96094 27.0196L9.01494 24.1428L11.1229 25.8912L9.15134 27.7016L7.96094 27.0196Z"
          fill="#213147"
        />
        <path
          d="M14.5331 7.98561H11.6439C11.4331 7.98561 11.2347 8.12201 11.1603 8.32041L4.97266 25.2836L7.96106 27.0072L14.7811 8.32041C14.8431 8.14681 14.7191 7.97321 14.5455 7.97321L14.5331 7.98561Z"
          fill="white"
        />
        <path
          d="M19.5931 7.98561H16.7039C16.4931 7.98561 16.2947 8.12201 16.2203 8.32041L9.15234 27.6892L12.1407 29.4128L19.8287 8.32041C19.8907 8.14681 19.7667 7.97321 19.5931 7.97321V7.98561Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_9885_18216">
          <rect width="31" height="31" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
