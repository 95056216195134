import React from 'react';
import { Divider, Stack } from '@mui/material';

import { useTooltipTheme } from 'common/TooltipWrapper/utils/ThemeContext';
import Text from 'components/primitives/Text';

interface Props {
  text: string;
}

export default function Header({ text }: Props) {
  const { theme } = useTooltipTheme();

  const color = theme === 'dark' ? '#fff' : '#101840';
  const dividerColor = theme === 'dark' ? '#818EA3' : '#EDEFF5';

  return (
    <Stack gap={1}>
      <Text size="xs" sx={{ color }}>
        {text}
      </Text>
      <Divider
        color={dividerColor}
        sx={{ width: 'calc(100% + 20px)', mb: '10px', mx: '-10px' }}
      />
    </Stack>
  );
}
