export default function PolygonIcon() {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15.5" cy="15.5" r="15.5" fill="#8247E5" />
      <g clipPath="url(#clip0_9885_18300)">
        <path
          d="M20.0535 12.666C19.7282 12.48 19.3099 12.48 18.938 12.666L16.3351 14.1998L14.5689 15.1759L12.0124 16.7098C11.6871 16.8957 11.2687 16.8957 10.8969 16.7098L8.89824 15.5013C8.57288 15.3153 8.34047 14.9435 8.34047 14.5252V12.2012C8.34047 11.8293 8.5264 11.4575 8.89824 11.2251L10.8969 10.0631C11.2223 9.87714 11.6406 9.87714 12.0124 10.0631L14.0111 11.2716C14.3364 11.4575 14.5689 11.8293 14.5689 12.2476V13.7815L16.3351 12.7589V11.1786C16.3351 10.8067 16.1492 10.4349 15.7773 10.2025L12.0589 8.01792C11.7335 7.832 11.3152 7.832 10.9434 8.01792L7.13198 10.249C6.76014 10.4349 6.57422 10.8067 6.57422 11.1786V15.5477C6.57422 15.9196 6.76014 16.2914 7.13198 16.5238L10.8969 18.7084C11.2223 18.8943 11.6406 18.8943 12.0124 18.7084L14.5689 17.221L16.3351 16.1985L18.8915 14.7111C19.2169 14.5252 19.6352 14.5252 20.0071 14.7111L22.0057 15.8731C22.3311 16.059 22.5635 16.4309 22.5635 16.8492V19.1732C22.5635 19.5451 22.3776 19.9169 22.0057 20.1493L20.0535 21.3113C19.7282 21.4972 19.3099 21.4972 18.938 21.3113L16.9394 20.1493C16.614 19.9634 16.3816 19.5915 16.3816 19.1732V17.6859L14.6153 18.7084V20.2423C14.6153 20.6141 14.8013 20.986 15.1731 21.2184L18.938 23.4029C19.2634 23.5889 19.6817 23.5889 20.0535 23.4029L23.8185 21.2184C24.1438 21.0324 24.3762 20.6606 24.3762 20.2423V15.8266C24.3762 15.4548 24.1903 15.0829 23.8185 14.8505L20.0535 12.666Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_9885_18300">
          <rect
            width="17.8485"
            height="15.5709"
            fill="white"
            transform="translate(6.57422 7.92499)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
