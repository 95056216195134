import React from 'react';
import { PiX } from 'react-icons/pi';
import { Box } from '@mui/material';

import SidePanel from 'common/SidePanel';
import { TransparentBgButton } from 'common/TransparentBgButton';
import TokenSearch from 'components/Dashboard/TokenSearch';
import { useDisclosure } from 'utils/hooks';

import { useIsLoggedIn } from '../../../../store/AppProvider';
import SidebarContent from '../Content';

interface Props {
  mobileNavigationDisclosure: ReturnType<typeof useDisclosure>;
}

export default function MobileSidebar({ mobileNavigationDisclosure }: Props) {
  const isLoggedIn = useIsLoggedIn();

  return (
    <SidePanel
      isOpen={mobileNavigationDisclosure.isOpen}
      onClose={mobileNavigationDisclosure.close}
      innerSx={{
        px: 0,
        py: 0,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: isLoggedIn ? 'space-between' : 'flex-end',
          pt: 2,
          px: 2,
        }}
      >
        {isLoggedIn && (
          <div className="sm:hidden">
            <TokenSearch />
          </div>
        )}

        <TransparentBgButton
          onClick={mobileNavigationDisclosure.close}
          sx={{ color: 'text.secondary' }}
        >
          <PiX color="black" size="24px" />
        </TransparentBgButton>
      </Box>

      <Box sx={{ height: 'calc(100vh - 50px)', pt: 2 }}>
        <SidebarContent isSidebarOpen={true} />
      </Box>
    </SidePanel>
  );
}
