export default function BnbIcon() {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_9885_18197)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.5 0C24.061 0 31 6.93898 31 15.5C31 24.061 24.061 31 15.5 31C6.93898 31 0 24.061 0 15.5C0 6.93898 6.93898 0 15.5 0Z"
          fill="#F0B90B"
        />
        <path
          d="M8.5184 15.5L8.52958 19.5986L12.0121 21.6479V24.0474L6.49148 20.8095V14.3015L8.5184 15.5ZM8.5184 11.4015V13.7898L6.49023 12.5901V10.2017L8.5184 9.00195L10.5565 10.2017L8.5184 11.4015ZM13.4665 10.2017L15.4946 9.00195L17.5327 10.2017L15.4946 11.4015L13.4665 10.2017Z"
          fill="white"
        />
        <path
          d="M9.98438 18.7491V16.3495L12.0125 17.5493V19.9376L9.98438 18.7491ZM13.4669 22.5073L15.4951 23.7071L17.5332 22.5073V24.8957L15.4951 26.0954L13.4669 24.8957V22.5073ZM20.4419 10.2017L22.4701 9.00195L24.5082 10.2017V12.5901L22.4701 13.7898V11.4015L20.4419 10.2017ZM22.4701 19.5986L22.4812 15.5L24.5094 14.3003V20.8083L18.9888 24.0461V21.6466L22.4701 19.5986Z"
          fill="white"
        />
        <path
          d="M21.0164 18.749L18.9883 19.9376V17.5492L21.0164 16.3495V18.749Z"
          fill="white"
        />
        <path
          d="M21.0157 12.251L21.0269 14.6505L17.5344 16.6998V20.8082L15.5062 21.9968L13.4781 20.8082V16.6998L9.98562 14.6505V12.251L12.0225 11.0512L15.4938 13.1104L18.9863 11.0512L21.0244 12.251H21.0157ZM9.98438 8.15364L15.4951 4.9046L21.0157 8.15364L18.9875 9.3534L15.4951 7.29418L12.0125 9.3534L9.98438 8.15364Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_9885_18197">
          <rect width="31" height="31" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
