import React from 'react';
import { FiPlus } from 'react-icons/fi';
import { PiCaretUpDown, PiMagnifyingGlass } from 'react-icons/pi';
import { useRouter } from 'next/router';
import { Box, Stack, styled } from '@mui/material';
import { useIsLoggedIn } from 'src/store/AppProvider';

import Input from 'common/Input';
import PopperWrapper from 'common/PopperWrapper';
import Tag from 'common/Tag';
import UnstyledLink from 'common/UnstyledLink';
import HStack from 'components/primitives/HStack';
import Text from 'components/primitives/Text';
import VStack from 'components/primitives/VStack';
import TeamMenuItem from 'components/team-management/TeamDropdown/TeamMenuItem';
import useDebounce from 'utils/hooks/useDebounce';
import { TeamResItem, useGetTeams } from 'services/teamManagement/useGetTeams';

import { useTeamStore } from '../../../store/team/teamStore';

interface Props {
  TeamDetailsPopperBox?: React.ReactNode;
}

export default function TeamDropdown({ TeamDetailsPopperBox }: Props) {
  const router = useRouter();
  const [searchInput, setSearchInput] = React.useState('');

  const debouncedSearchInput = useDebounce(searchInput, 500);

  const { teams, selectedTeam } = useGetTeams();

  const filteredTeams = React.useMemo(() => {
    if (!debouncedSearchInput) return teams;
    return teams.filter((team) =>
      team.name.toLowerCase().includes(debouncedSearchInput.toLowerCase()),
    );
  }, [debouncedSearchInput, teams]);

  const {
    setTeam,
    team: currentlyPickedTeam,
    setProject,
    setTeamAndProject,
  } = useTeamStore();

  const isLoggedIn = useIsLoggedIn();

  const onTeamClick = async (team: TeamResItem) => {
    if (team.id === currentlyPickedTeam?.id) return;
    setTeam(team);

    if (team.projects.length > 0) {
      setProject(team.projects[0]);
    }

    // Change the URL only if the route contains [teamSlug] or [projectSlug]
    const route = router.route;

    const routeHasTeamSlug = route.includes('[teamSlug]');
    const routeHasProjectSlug = route.includes('[projectSlug]');

    let updatedRoute = window.location.pathname;

    if (routeHasTeamSlug) {
      const currentTeamSlug = router.query.teamSlug as string;
      updatedRoute = updatedRoute.replace(currentTeamSlug, team.slug);
    }

    if (routeHasProjectSlug) {
      const currentProjectSlug = router.query.projectSlug as string;
      updatedRoute = updatedRoute.replace(
        currentProjectSlug,
        team.projects[0].slug,
      );
    }

    if (routeHasProjectSlug || routeHasTeamSlug) {
      setTeamAndProject(team, team.projects[0]);
      window.location.href = updatedRoute;
    } else {
      // If the route doesn't contain [teamSlug] or [projectSlug], we need to reload the page
    }
  };

  if (!isLoggedIn) return null;

  const handleMenuItemClick = (team: TeamResItem) => {
    onTeamClick(team);
  };

  return (
    <Box className="onboarding-step-team">
      <PopperWrapper
        key="2"
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 16],
              },
            },
          ],
        }}
        sx={{
          zIndex: 99999,
        }}
        placement="bottom-start"
        content={
          <Box
            key="3"
            sx={{ bgcolor: 'white', overflow: 'hidden' }}
            boxShadow="0px 4px 8px rgba(16, 24, 64, 0.08)"
            border="1px solid"
            borderColor="neutral.400"
            borderRadius={2}
          >
            <Box sx={{ minWidth: '324px' }}>
              <Box
                bgcolor="neutral.100"
                borderBottom="1px solid"
                borderColor={'neutral.400'}
              >
                <HStack vcenter px={1}>
                  <PiMagnifyingGlass size="14px" color="black" />
                  <Input
                    InputProps={{
                      onChange: (e) => setSearchInput(e.target.value),
                      value: searchInput,
                    }}
                    autoFocus
                    placeholder="Find team..."
                    sx={{
                      m: 0,
                      p: 0,
                      borderRadius: 0,
                      border: 'none',
                      boxShadow: 'none',
                      fontSize: '12px',
                      '& .MuiInputBase-input ': {
                        px: 1,
                        py: 1,
                      },
                      '& .MuiInputBase-root': {
                        borderRadius: 0,
                        fontSize: '12px',
                        py: 0,
                        px: 0,
                        minHeight: '0px',
                        height: '36px',

                        border: 'none',
                        boxShadow: 'none',
                        bgcolor: 'neutral.100',
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                        px: 0,
                      },
                      '& fieldset': {
                        border: 'none',
                        px: 0,
                        fontSize: '12px',
                        borderColor: 'transparent',
                        borderRadius: 0,
                      },
                    }}
                  />
                </HStack>
              </Box>
              <Box sx={{ my: 0.5 }}>
                <VStack gap={0.25}>
                  {filteredTeams.map((team) => {
                    const isSelected = team.slug === selectedTeam?.slug;

                    return (
                      <TeamMenuItem
                        key={team.id}
                        onClick={handleMenuItemClick}
                        isSelected={isSelected}
                        team={team}
                      />
                    );
                  })}
                </VStack>
              </Box>

              <Box
                px={2}
                py={1.5}
                borderTop="1px solid"
                borderColor={'neutral.400'}
                bgcolor="neutral.100"
              >
                <UnstyledLink href="/settings/team-management?create=true">
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 1,
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Stack>
                      <Text size="xs" weight="medium">
                        Want to create a team?
                      </Text>
                      <Text size="xxs" color="tertiary">
                        Invite others and start collaborating!
                      </Text>
                    </Stack>

                    <AddIconWrapper>
                      <FiPlus color="#8F95B2" />
                    </AddIconWrapper>
                  </Box>
                </UnstyledLink>
              </Box>
            </Box>
          </Box>
        }
      >
        {({ isOpen }) => (
          <>
            {TeamDetailsPopperBox || (
              <TeamDetailsBox selectedTeam={selectedTeam} />
            )}
          </>
        )}
      </PopperWrapper>
    </Box>
  );
}

const TeamDetailsBox = ({
  selectedTeam,
}: {
  selectedTeam: TeamResItem | undefined;
}) => {
  if (!selectedTeam) return null;
  return (
    <Box
      // border="1px solid"
      borderColor="neutral.400"
      bgcolor="neutral.100"
      height="40px"
      borderRadius={3}
      pl={1.5}
      pr={0.5}
      display="flex"
      alignItems="center"
      gap={2}
      sx={{
        cursor: 'pointer',
        transition: 'background-color 0.2s ease-in-out',
        '&:hover': {
          bgcolor: 'neutral.300',
        },
      }}
    >
      <Box>
        <HStack vcenter gap={1}>
          <Text size="xs" weight="medium">
            {selectedTeam.name}
          </Text>
          {selectedTeam?.isSubscriptionActive ? (
            <Tag size="small" color="green">
              {selectedTeam.subscriptionType}
            </Tag>
          ) : (
            <Tag size="small" color="gray">
              Free
            </Tag>
          )}

          {selectedTeam?.isDemo && (
            <Tag size="small" color="red">
              Demo
            </Tag>
          )}
        </HStack>
      </Box>
      <PiCaretUpDown />
    </Box>
  );
};

const AddIconWrapper = styled(Box)({
  padding: 8,
  background: '#fff',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 8,
  border: '1px solid #D8DAE5',
});
