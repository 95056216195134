import Image from 'next/image';

export default function BaseIcon() {
  return (
    <Image
      src="/icons/chains/base.png"
      width={31}
      height={31}
      alt="base"
      style={{
        maxWidth: '100%',
        height: 'auto',
      }}
    />
  );
}
