import { cn } from 'src/utils';

function Skeleton({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={cn('animate-pulse rounded-md bg-ds-tertiary', className)}
      {...props}
    >
      &nbsp;
    </div>
  );
}

export { Skeleton };
