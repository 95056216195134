export default function OptimismIcon() {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_11024_2657)">
        <path
          d="M15.5 31C24.0604 31 31 24.0604 31 15.5C31 6.93959 24.0604 0 15.5 0C6.93959 0 0 6.93959 0 15.5C0 24.0604 6.93959 31 15.5 31Z"
          fill="#FF0420"
        />
        <path
          d="M10.9793 19.6168C10.0555 19.6168 9.29914 19.3998 8.71014 18.9658C8.12734 18.5256 7.83594 17.8932 7.83594 17.081C7.83594 16.9074 7.85454 16.7028 7.89174 16.4548C7.99094 15.8968 8.13354 15.2272 8.31954 14.4398C8.84654 12.307 10.2105 11.2406 12.4053 11.2406C13.0005 11.2406 13.5399 11.3398 14.0111 11.5444C14.4823 11.7366 14.8543 12.0342 15.1271 12.431C15.3999 12.8216 15.5363 13.2866 15.5363 13.826C15.5363 13.9872 15.5177 14.1918 15.4805 14.4398C15.3627 15.128 15.2263 15.8038 15.0589 16.4548C14.7861 17.515 14.3211 18.3148 13.6515 18.8418C12.9881 19.3626 12.0953 19.6168 10.9793 19.6168ZM11.1467 17.9428C11.5807 17.9428 11.9465 17.8126 12.2503 17.5584C12.5603 17.3042 12.7835 16.9136 12.9137 16.3804C13.0935 15.6488 13.2299 15.0164 13.3229 14.4708C13.3539 14.3096 13.3725 14.1422 13.3725 13.9686C13.3725 13.2618 13.0067 12.9084 12.2689 12.9084C11.8349 12.9084 11.4629 13.0386 11.1529 13.2928C10.8491 13.547 10.6321 13.9376 10.5019 14.4708C10.3593 14.9916 10.2229 15.624 10.0803 16.3804C10.0493 16.5354 10.0307 16.6966 10.0307 16.8702C10.0245 17.5894 10.4027 17.9428 11.1467 17.9428Z"
          fill="white"
        />
        <path
          d="M16.0762 19.5052C15.9894 19.5052 15.9274 19.4804 15.8778 19.4246C15.8406 19.3626 15.8282 19.2944 15.8406 19.2138L17.4464 11.6498C17.4588 11.563 17.5022 11.4948 17.5766 11.439C17.6448 11.3832 17.7192 11.3584 17.7998 11.3584H20.8936C21.7554 11.3584 22.4436 11.5382 22.9644 11.8916C23.4914 12.2512 23.758 12.7658 23.758 13.4416C23.758 13.6338 23.7332 13.8384 23.6898 14.0492C23.4976 14.942 23.107 15.5992 22.5118 16.027C21.929 16.4548 21.1292 16.6656 20.1124 16.6656H18.5438L18.0106 19.2138C17.992 19.3006 17.9548 19.3688 17.8804 19.4246C17.8122 19.4804 17.7378 19.5052 17.6572 19.5052H16.0762ZM20.193 15.0598C20.5216 15.0598 20.8006 14.973 21.0424 14.7932C21.2904 14.6134 21.4516 14.3592 21.5322 14.0244C21.557 13.8942 21.5694 13.7764 21.5694 13.6772C21.5694 13.454 21.5012 13.2804 21.371 13.1626C21.2408 13.0386 21.0114 12.9766 20.6952 12.9766H19.3002L18.86 15.0598H20.193Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_11024_2657">
          <rect width="31" height="31" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
