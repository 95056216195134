import { AdvancedFilterSuggestions } from 'types/Dashboard/web-sessions/AdvancedFilterSuggestions';
import { SuggestionsGroupingDimension } from 'types/Suggestions';

import { websiteAdvancedFilterSchema } from './schema';
import { AdvancedFilters } from './types';

const defaultValue = {
  isOrIncludes: 'is',
  values: [],
};

type KeyOf<T> = keyof T;
type KeyOfSuggestions = KeyOf<AdvancedFilterSuggestions>;

export type AdvancedFilterFormType = {
  [key: string]: {
    autocompleteKey: KeyOfSuggestions;
    name: string;
    label: string;
    placeholder: string;
    formatAs?: 'country' | 'pathname' | 'url' | 'country';
    suggestionsDimension: SuggestionsGroupingDimension;
  };
};

const utmForm: AdvancedFilterFormType = {
  utm_source: {
    autocompleteKey: 'campaignSource',
    name: 'utm_source',
    label: 'UTM Source',
    placeholder: 'UTM Source',
    suggestionsDimension: SuggestionsGroupingDimension.CampaignSource,
  },
  utm_medium: {
    autocompleteKey: 'campaignMedium',
    name: 'utm_medium',
    label: 'UTM Medium',
    placeholder: 'UTM Medium',
    suggestionsDimension: SuggestionsGroupingDimension.CampaignMedium,
  },
  utm_campaign: {
    autocompleteKey: 'campaignName',
    name: 'utm_campaign',
    label: 'UTM Campaign',
    placeholder: 'UTM Campaign',
    suggestionsDimension: SuggestionsGroupingDimension.CampaignName,
  },
  utm_keyword: {
    autocompleteKey: 'campaignKeyword',
    name: 'utm_keyword',
    label: 'UTM Keyword',
    placeholder: 'UTM Keyword',
    suggestionsDimension: SuggestionsGroupingDimension.CampaignKeyword,
  },
  utm_content: {
    autocompleteKey: 'campaignContent',
    name: 'utm_content',
    label: 'UTM content',
    placeholder: 'UTM Content',
    suggestionsDimension: SuggestionsGroupingDimension.CampaignContent,
  },
};

const trafficSourceForm: AdvancedFilterFormType = {
  referrerUrl: {
    autocompleteKey: 'referrerUrl',
    name: 'referrerUrl',
    label: 'Referrer URL',
    placeholder: 'Referrer URL',
    formatAs: 'url',
    suggestionsDimension: SuggestionsGroupingDimension.ReferrerUrl,
  },
  referrerType: {
    autocompleteKey: 'referrerType',
    name: 'referrerType',
    label: 'Referrer Type',
    placeholder: 'Referrer Type',
    suggestionsDimension: SuggestionsGroupingDimension.ReferrerType,
  },
  referrerName: {
    autocompleteKey: 'referrerName',
    name: 'referrerName',
    label: 'Referrer Name',
    placeholder: 'Referrer Name',
    suggestionsDimension: SuggestionsGroupingDimension.ReferrerName,
  },
};

const locationForm: AdvancedFilterFormType = {
  country: {
    autocompleteKey: 'country',
    name: 'country',
    label: 'Country',
    placeholder: 'Country',
    formatAs: 'country',
    suggestionsDimension: SuggestionsGroupingDimension.Country,
  },
  region: {
    autocompleteKey: 'region',
    name: 'region',
    label: 'Region',
    placeholder: 'Region',
    suggestionsDimension: SuggestionsGroupingDimension.Region,
  },
  city: {
    autocompleteKey: 'city',
    name: 'city',
    label: 'City',
    placeholder: 'City',
    suggestionsDimension: SuggestionsGroupingDimension.City,
  },
};

const userAgentForm: AdvancedFilterFormType = {
  browser: {
    autocompleteKey: 'browser',
    name: 'browser',
    label: 'Browser',
    placeholder: 'Browser',
    suggestionsDimension: SuggestionsGroupingDimension.Browser,
  },
  device: {
    autocompleteKey: 'deviceType',
    name: 'device',
    label: 'Device',
    placeholder: 'Device',
    suggestionsDimension: SuggestionsGroupingDimension.Device,
  },
};

const pageForm: AdvancedFilterFormType = {
  page: {
    autocompleteKey: 'url',
    name: 'page',
    label: 'Page',
    placeholder: 'Page',
    suggestionsDimension: SuggestionsGroupingDimension.Page,
    // formatAs: 'pathname',
  },
  entryPage: {
    autocompleteKey: 'url',
    name: 'entryPage',
    label: 'Entry Page',
    placeholder: 'Entry Page',
    formatAs: 'pathname',
    suggestionsDimension: SuggestionsGroupingDimension.EntryPage,
  },
  exitPage: {
    autocompleteKey: 'url',
    name: 'exitPage',
    label: 'Exit Page',
    placeholder: 'Exit Page',
    formatAs: 'pathname',
    suggestionsDimension: SuggestionsGroupingDimension.ExitPage,
  },
};

const inputs = {
  utmParameters: {
    label: 'UTM Parameters',
    inputs: utmForm,
  },
  trafficSource: {
    label: 'Traffic Source',
    inputs: trafficSourceForm,
  },
  location: {
    label: 'Location',
    inputs: locationForm,
  },
  userAgent: {
    label: 'User Agent',
    inputs: userAgentForm,
  },
  page: {
    label: 'Page',
    inputs: pageForm,
  },
};

export const websiteAdvancedFilterForm = {
  schema: websiteAdvancedFilterSchema,
  inputs,
};

function generateDefaultValues(
  inputs: typeof websiteAdvancedFilterForm.inputs,
) {
  const defaultValues = {} as any;

  for (const key in inputs) {
    if (
      inputs.hasOwnProperty(key) &&
      inputs[key as keyof (typeof websiteAdvancedFilterForm)['inputs']].inputs
    ) {
      const _key = key as keyof (typeof websiteAdvancedFilterForm)['inputs'];

      const nestedObject = inputs[_key].inputs;
      defaultValues[_key] = {};

      for (const nestedKey in nestedObject) {
        if (nestedObject.hasOwnProperty(nestedKey)) {
          defaultValues[_key][nestedKey] = {
            values: [],
            isOrIncludes: 'is',
          };
        }
      }
    }
  }

  return defaultValues;
}

export const defaultValues = generateDefaultValues(
  websiteAdvancedFilterForm.inputs,
);

export const _defaultValues: AdvancedFilters = {
  utmParameters: {
    utm_campaign: defaultValue,
    utm_content: defaultValue,
    utm_medium: defaultValue,
    utm_source: defaultValue,
    utm_keyword: defaultValue,
    utm_term: defaultValue,
  },
  trafficSource: {
    referrerName: defaultValue,
    referrerType: defaultValue,
    referrerUrl: defaultValue,
  },
  location: {
    city: defaultValue,
    continent: defaultValue,
    country: defaultValue,
    region: defaultValue,
  },
  userAgent: {
    browser: defaultValue,
    device: defaultValue,
    os: defaultValue,
  },
  page: {
    entryPage: defaultValue,
    exitPage: defaultValue,
    page: defaultValue,
  },
};
