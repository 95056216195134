import React from 'react';
import { Fade, SxProps, TooltipProps } from '@mui/material';
import { Theme } from '@mui/system';
import { TooltipPortal, TooltipProvider } from '@radix-ui/react-tooltip';

import Header from 'common/TooltipWrapper/Header';
import ThemeProvider from 'common/TooltipWrapper/utils/ThemeContext';
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip';

interface Props extends Omit<TooltipProps, 'title'> {
  content?: React.ReactNode;
  sx?: SxProps<Theme>;
  background?: string;
  offset?: [number, number];
  header?: React.ReactNode;
  theme?: 'light' | 'dark';
}

export default function TooltipWrapper({
  sx,
  content,
  placement = 'top',
  children,
  theme = 'dark',
  arrow = true,
  offset,
  header,
  ...rest
}: Props) {
  const background = theme === 'dark' ? '#0B182B' : '#fff';

  const popperOffsetConfig = offset
    ? {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset,
            },
          },
        ],
      }
    : {};

  if (!content) return <>{children}</>;

  return (
    <ThemeProvider theme={theme}>
      <Tooltip content={content}>{children}</Tooltip>
    </ThemeProvider>
  );
}

TooltipWrapper.Header = Header;
