import { CHAINS_WITH_ICONS } from 'config/chains/config';
import { create, StateCreator } from 'zustand';
import { persist } from 'zustand/middleware';

import { ChainId } from 'types/Chain';

export type ChainStore = {
  chain?: ChainId;
  setChain: (chain: ChainId) => void;
};

const prepareStore: StateCreator<ChainStore> = (set) => ({
  chain: undefined,
  setChain: (chain) =>
    set(() => ({
      chain: chain,
    })),
});

export const useChainStore = create(
  persist(prepareStore, {
    name: 'chain-store',
    partialize: (state) => ({
      chain: state.chain,
    }),
    version: 0.1,
  }),
);
