export default function BlastIcon() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="30" height="30" rx="15" fill="black" />
      <path
        d="M6.1389 21.4777C7.25092 18.1049 8.34752 14.7805 9.45918 11.4092C10.1591 11.915 10.8338 12.3859 11.4805 12.8899C11.5697 12.9596 11.5749 13.2107 11.5293 13.3502C11.0354 14.8654 10.5224 16.3752 10.0167 17.8869C9.8973 18.2442 9.78456 18.6032 9.65308 19.0099C9.82385 19.0206 9.94725 19.0348 10.0706 19.0348C13.0534 19.0358 16.0362 19.0316 19.019 19.0408C19.312 19.0419 19.449 18.9708 19.5386 18.6803C19.8401 17.7032 20.182 16.7375 20.5291 15.7068H13.5852C13.8158 15.0117 14.0259 14.3785 14.244 13.7214C14.4512 13.7214 14.6451 13.7214 14.839 13.7214C16.8276 13.7214 18.8159 13.7161 20.8045 13.7278C21.0833 13.7296 21.2254 13.6578 21.3084 13.39C21.5548 12.5944 21.8277 11.8067 22.1112 10.9525H4.98905C4.97767 10.9212 4.96591 10.8896 4.95453 10.8584C5.16055 10.701 5.36291 10.5391 5.57334 10.3874C6.34896 9.82835 7.11871 9.26149 7.91013 8.72447C8.09706 8.59768 8.35927 8.51492 8.58733 8.51421C13.1794 8.50249 17.7711 8.50995 22.3631 8.5C22.7381 8.49929 23.04 8.58702 23.3323 8.80865C23.8259 9.18265 24.3352 9.53782 24.8501 9.88411C25.0459 10.0159 25.0848 10.1462 25.0099 10.3611C24.6929 11.2693 24.4002 12.1853 24.0741 13.0899C24.0223 13.2334 23.8666 13.3758 23.7212 13.4486C22.7605 13.9299 21.7895 14.392 20.7134 15.0039C20.8111 15.0398 20.9224 15.0579 21.0047 15.1147C21.5673 15.5047 22.1196 15.9085 22.6848 16.2953C22.8677 16.4204 22.9353 16.5265 22.8545 16.7585C22.517 17.726 22.2151 18.7048 21.8809 19.6737C21.8336 19.8108 21.7046 19.9593 21.5724 20.0254C20.644 20.4881 19.7079 20.9367 18.7663 21.3743C18.5966 21.4531 18.3913 21.4947 18.2022 21.4951C14.3428 21.5018 10.4838 21.5 6.6244 21.499C6.47346 21.499 6.32289 21.4858 6.13853 21.4773L6.1389 21.4777Z"
        fill="#FEFC06"
      />
    </svg>
  );
}
