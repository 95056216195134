export default function ScrollIcon() {
  return (
    <img
      src="/icons/chains/scroll.svg"
      width={31}
      height={31}
      alt="scroll"
      style={{
        maxWidth: '100%',
        height: 'auto',
      }}
    />
  );
}
