import React from 'react';

export default function MantleIcon() {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15.5" cy="15.5" r="15.5" fill="black" />
      <path
        d="M9.38612 12.3431L6.27552 10.7627C6.03145 11.2394 5.82633 11.7394 5.66016 12.2498L8.98107 13.3224C9.09012 12.9856 9.22514 12.6566 9.38612 12.3431Z"
        fill="white"
      />
      <path
        d="M12.0247 9.55847L13.7488 12.5198C14.0007 12.3747 14.2707 12.2633 14.5485 12.1882L13.6579 8.88226C13.8838 8.82267 14.1149 8.77086 14.3486 8.73458L13.8007 5.29395C13.271 5.37685 12.744 5.5038 12.235 5.66962L13.2996 8.9289C12.9595 9.0403 12.6297 9.17762 12.3129 9.33825L10.7473 6.28883C10.2721 6.53237 9.80992 6.81477 9.37891 7.13085L11.4379 9.94451C11.6249 9.8046 11.8222 9.67765 12.0247 9.55847Z"
        fill="white"
      />
      <path
        d="M21.2725 12.0116L18.3047 13.732C18.4501 13.9833 18.5643 14.2527 18.6396 14.5299L21.9528 13.6387C22.0151 13.8641 22.0644 14.0947 22.1008 14.3253L25.5489 13.7786C25.4658 13.2501 25.3386 12.7241 25.1698 12.2163L21.906 13.2812C21.7944 12.9418 21.6568 12.6127 21.4958 12.2966L24.5518 10.7318C24.3078 10.2576 24.0248 9.79647 23.708 9.36639L20.8908 11.4209C21.0258 11.6127 21.153 11.8096 21.2725 12.0116Z"
        fill="white"
      />
      <path
        d="M20.0627 6.27321C19.585 6.02967 19.0839 5.82499 18.5723 5.65918L17.5 8.97287C17.8349 9.08168 18.1647 9.21641 18.4815 9.37704L20.0627 6.27321Z"
        fill="white"
      />
      <path
        d="M18.8204 9.49874L17.0781 12.5171C17.33 12.6622 17.5637 12.8409 17.7688 13.0456L22.6554 8.1515C22.2763 7.77324 21.8635 7.42348 21.4324 7.10999L19.4176 9.88478C19.2254 9.74487 19.0281 9.61533 18.8204 9.49874Z"
        fill="white"
      />
      <path
        d="M9.5066 12.0016L12.5289 13.7427C12.6743 13.4914 12.8535 13.2582 13.0586 13.0509L8.15902 8.17236C7.77994 8.55063 7.42941 8.96257 7.11523 9.39265L9.89348 11.4057C9.75586 11.5975 9.62604 11.797 9.5066 12.0016Z"
        fill="white"
      />
      <path
        d="M16.4773 8.67475L17.0044 5.28852C16.4825 5.2082 15.9502 5.16675 15.4179 5.16675H15.4102V12.0739H15.4179C15.7088 12.0739 15.9996 12.1102 16.2774 12.1853L17.1732 8.81984C16.9447 8.75766 16.7136 8.71102 16.4773 8.67475Z"
        fill="white"
      />
      <path
        d="M12.198 14.5431L8.82511 13.6467C8.76539 13.8747 8.71606 14.1078 8.67971 14.341L5.2861 13.8125C5.20561 14.3332 5.16406 14.8695 5.16406 15.4007H12.0863C12.0863 15.1079 12.1252 14.8203 12.198 14.5431Z"
        fill="white"
      />
      <path
        d="M21.4551 18.458L24.5657 20.0384C24.8097 19.5617 25.0149 19.0616 25.181 18.5512L21.8601 17.4786C21.7511 17.8154 21.6161 18.1445 21.4551 18.458Z"
        fill="white"
      />
      <path
        d="M18.8103 21.2403L17.0888 18.279C16.8369 18.4241 16.5669 18.5355 16.2891 18.6106L17.1797 21.9165C16.9538 21.9761 16.7227 22.0279 16.489 22.0642L17.0369 25.5049C17.5665 25.4219 18.0936 25.295 18.6025 25.1292L17.538 21.8699C17.8781 21.7585 18.2079 21.6212 18.5246 21.4605L20.0903 24.51C20.5655 24.2664 21.0277 23.984 21.4587 23.6679L19.4023 20.8543C19.2127 20.9968 19.0154 21.1237 18.8103 21.2403Z"
        fill="white"
      />
      <path
        d="M9.56939 18.7894L12.5372 17.0691C12.3918 16.8178 12.2775 16.5483 12.2022 16.2711L8.88911 17.1624C8.82679 16.937 8.77746 16.7064 8.74111 16.4758L5.29297 17.0225C5.37606 17.551 5.50328 18.0769 5.67206 18.5847L8.93584 17.5199C9.04749 17.8567 9.18511 18.1883 9.34609 18.5044L6.29002 20.0693C6.53409 20.5434 6.81711 21.0046 7.13388 21.4347L9.95107 19.3801C9.81605 19.1884 9.68883 18.9915 9.56939 18.7894Z"
        fill="white"
      />
      <path
        d="M10.7754 24.5282C11.2531 24.7717 11.7543 24.9764 12.2658 25.1422L13.3381 21.8285C13.0032 21.7197 12.6734 21.585 12.3567 21.4244L10.7754 24.5282Z"
        fill="white"
      />
      <path
        d="M12.0147 21.3026L13.7569 18.2843C13.5051 18.1392 13.2714 17.9604 13.0663 17.7557L8.17969 22.6498C8.55877 23.0281 8.97162 23.3779 9.40263 23.6914L11.4175 20.9166C11.6096 21.0539 11.8096 21.1834 12.0147 21.3026Z"
        fill="white"
      />
      <path
        d="M21.3332 18.7999L18.3109 17.0589C18.1655 17.3102 17.9864 17.5434 17.7812 17.7507L22.6834 22.6292C23.0625 22.2509 23.413 21.839 23.7272 21.4089L20.949 19.3958C21.0866 19.2015 21.2164 19.0046 21.3332 18.7999Z"
        fill="white"
      />
      <path
        d="M14.5618 18.6157L13.666 21.9812C13.8945 22.0408 14.1282 22.09 14.3619 22.1263L13.8348 25.5125C14.3567 25.5929 14.889 25.6343 15.4212 25.6343H15.429V18.7271H15.4212C15.1304 18.7271 14.8396 18.6883 14.5618 18.6157Z"
        fill="white"
      />
      <path
        d="M18.7523 15.4006C18.7523 15.6933 18.7159 15.9809 18.6406 16.2581L22.0135 17.1546C22.0732 16.9266 22.1225 16.6934 22.1589 16.4602L25.5525 16.9888C25.633 16.468 25.6745 15.9317 25.6745 15.4006H18.7523Z"
        fill="white"
      />
    </svg>
  );
}
