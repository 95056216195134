import { ComponentProps } from 'react';

import Button from '..';

interface Props extends ComponentProps<typeof Button> {}
export default function SlashButton(props: Props) {
  return (
    <Button
      disableRipple
      {...props}
      sx={{
        height: '46px',
        border: 'none',
        background: 'none',
        overflow: 'hidden',
        px: 0,
        pr: 2,
        py: 0,

        '&:active': {
          background: 'none',
        },
        '&:hover': {
          background: 'none',

          '&:after': {
            backgroundPosition: '100% 100%',
          },
        },

        '&:after': {
          background:
            'linear-gradient(75deg,#0066FF 0%,#68A4FF 80%,black 200%)',
          backgroundSize: '200% 200%',
          borderBottomRightRadius: '4.5px',
          borderTopRightRadius: '11.5px',
          transform: 'translateX(-6px) skew(-22deg)',
          transition: 'all 0.5s ease-in-out',
          bottom: 0,
          contain: 'strict',
          content: '" "',
          flex: '0 0 auto;',
          left: '0',
          position: 'absolute',
          right: '1px',
          top: '0',
          willChange: 'transform',
          zIndex: '-1',
        },

        '&:before': {
          bottom: 0,
          contain: 'strict',
          content: '"',
          flex: '0 0 auto;',
          left: '0',
          position: 'absolute',
          right: '1px',
          top: '0',
          willChange: 'transform',
          zIndex: '-1',
        },
        ...props.sx,
      }}
    />
  );
}
