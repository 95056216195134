import { useEffect, useMemo } from 'react';

import { getListOfChains } from 'components/Dashboard/ChainDropdown/utils/getListOfChains';
import { useGetTeams } from 'services/teamManagement/useGetTeams';

import { useChainStore } from '../chainStore';

export const useInitializeChainStore = () => {
  const { chain, setChain } = useChainStore();
  const { selectedTeam, isLoading: areTeamsLoading } = useGetTeams();

  const chainIdList = useMemo(() => {
    return getListOfChains({ selectedTeam })
      .filter((chain) => chain.isAvailable && !chain.isComingSoon)
      .map((chain) => chain.id);
  }, [selectedTeam]);

  useEffect(() => {
    if (chainIdList.length == 0) return;

    if (chain === null || chain === undefined || !chainIdList.includes(chain)) {
      setChain(chainIdList[0]);
    }
  }, [chain, setChain, chainIdList]);
};
