import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useIsLoggedIn } from 'src/store/AppProvider';

import { useGetTeams } from 'services/teamManagement/useGetTeams';

import { useTeamStore } from '../teamStore';
import { useTeamStoreSubscription } from './useTeamStoreSubscription';

export const useInitializeTeamStore = () => {
  const router = useRouter();
  const isLoggedIn = useIsLoggedIn();

  const {
    team: selectedTeam,
    project: selectedProject,
    setTeam: setSelectedTeam,
    setProject: setSelectedProject,
    clear: clearTeamStore,
    setTeamAndProject,
  } = useTeamStore();

  useTeamStoreSubscription();

  const {
    teams,
    isLoading: areTeamsLoading,
    mutate: mutateTeams,
  } = useGetTeams();

  useEffect(() => {
    // case 1: user is not logged in, but there is a team in the store
    // -> clear the store
    if (!isLoggedIn && Boolean(selectedTeam) && Boolean(selectedProject)) {
      clearTeamStore();
    }

    // make sure that teams are loaded and projects are loaded
    if (areTeamsLoading || !teams.length) {
      return;
    }

    // case 2: user is logged in, but there is no team in the store
    // -> set the first team in the store that is not demo team unless there is no other team
    if (!Boolean(selectedTeam)) {
      const teamsWithoutDemo = teams.filter((team) => !team.isDemo);

      if (teamsWithoutDemo.length) {
        const firstTeam = teamsWithoutDemo[0];
        setTeamAndProject(firstTeam, firstTeam.projects[0]);

        return;
      }

      const firstTeam = teams[0];
      setTeamAndProject(firstTeam, firstTeam.projects[0]);

      return;
    }

    // case 3: user is logged in and there is no project in the store
    // -> set the first project in the store
    if (!selectedProject) {
      const fetchedTeam = teams.find((team) => team.id === selectedTeam?.id);

      if (!fetchedTeam) return;

      const firstProject = fetchedTeam.projects[0];

      setSelectedProject(firstProject);

      return;
    }

    // if (router.pathname === '/[teamSlug]') {

    //   const teamBySlug = teams.find(
    //     (team) => team.slug === router.query.teamSlug
    //   );

    //   if (!teamBySlug) {
    //     router.push('/');

    //     return;
    //   }

    //   if (teamBySlug !== selectedTeam) {
    //     setTeamAndProject(teamBySlug, teamBySlug.projects[0]);

    //     return;
    //   }

    //   return;
    // }

    // case 5: user tries to navigate to a team that is not matching the team in the store
    // -> redirect to the first team

    const teamSlug = router.query.teamSlug as string;
    const projectSlug = router.query.projectSlug as string;

    if (
      selectedTeam &&
      selectedTeam.id &&
      teamSlug &&
      projectSlug &&
      (selectedTeam.slug !== teamSlug || selectedProject?.slug !== projectSlug)
    ) {
      // if teams include the teamSlug, set the team and project in store and continue
      const teamExists = teams.find((team) => team.slug === teamSlug);
      if (teamExists) {
        const projectExists = teamExists.projects.find(
          (project) => project.slug === projectSlug,
        );

        if (projectExists) {
          setTeamAndProject(teamExists, projectExists);

          return;
        }
      }

      const teamsWithoutDemo = teams.filter((team) => !team.isDemo);

      let firstTeam = teamsWithoutDemo[0];

      if (!firstTeam) {
        firstTeam = teams[0];
      }

      setTeamAndProject(firstTeam, firstTeam.projects[0]);

      router.query.teamSlug = firstTeam.slug;
      router.query.projectSlug = firstTeam.projects[0].slug;

      const newPathname = router.pathname.replace(
        '[teamSlug]/[projectSlug]',
        `${firstTeam.slug}/${firstTeam.projects[0].slug}`,
      );

      router.push(newPathname);

      return;
    }

    // case 4: user is logged in, and there is a team in the store
    // -> check if the team is still valid
    if (selectedTeam && selectedTeam.id) {
      const teamExists = teams.find((team) => team.id === selectedTeam.id);

      // case 4.1: team is not valid anymore
      //
      if (!teamExists) {
        clearTeamStore();

        return;
      }

      return;
    } else {
    }
  }, [
    areTeamsLoading,
    clearTeamStore,
    mutateTeams,
    isLoggedIn,
    router,
    selectedProject,
    selectedTeam,
    setSelectedProject,
    setSelectedTeam,
    setTeamAndProject,
    teams,
    teams.length,
  ]);
};
