import {
  CHAINS_NAME_ID_PAIR,
  CHAINS_WITH_ICONS_ARRAY,
  SUPPORTED_EXPLORER_CHAINS,
  SUPPORTED_EXPLORER_CHAINS_IDS,
} from 'config/chains/config';

import { TeamResItem } from 'services/teamManagement/useGetTeams';
import { ChainId } from 'types/Chain';
import { SubscriptionType } from 'types/TeamManagement/subscription';

const DROPDOWN_CHAIN_ITEMS = CHAINS_WITH_ICONS_ARRAY.map((i) => ({
  ...i,
  isAvailable: true,
  isComingSoon: !SUPPORTED_EXPLORER_CHAINS_IDS.includes(i.id as ChainId),
})).sort((a, b) => (a.isComingSoon ? 1 : 0) - (b.isComingSoon ? 1 : 0));

export const getListOfChains = ({
  selectedTeam,
}: {
  selectedTeam?: TeamResItem;
}) => {
  const listOfChains = () => {
    if (!selectedTeam) return [];
    if (selectedTeam?.isDemo) return DROPDOWN_CHAIN_ITEMS;

    if (
      Boolean(selectedTeam?.isSubscriptionActive) &&
      (selectedTeam.subscriptionType === SubscriptionType.Growth ||
        selectedTeam.subscriptionType === SubscriptionType.Enterprise)
    ) {
      return DROPDOWN_CHAIN_ITEMS;
    }

    if (
      Boolean(selectedTeam?.isSubscriptionActive) &&
      selectedTeam.subscriptionType === SubscriptionType.Basic
    ) {
      const chainsForBasicPlan = selectedTeam?.teamChains?.chains || [
        SUPPORTED_EXPLORER_CHAINS.Ethereum,
        SUPPORTED_EXPLORER_CHAINS.Polygon,
      ];

      return DROPDOWN_CHAIN_ITEMS.map((i) => ({
        ...i,
        isAvailable:
          chainsForBasicPlan[0] === i.id || chainsForBasicPlan[1] === i.id,
      }));
    }

    return DROPDOWN_CHAIN_ITEMS.map((i) => ({
      ...i,
      isAvailable: i.id === CHAINS_NAME_ID_PAIR.Ethereum,
    }));
  };

  return listOfChains();
};
