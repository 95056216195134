export enum SuggestionsGroupingDimension {
  CampaignName = 0,
  CampaignMedium = 1,
  CampaignSource = 2,
  CampaignKeyword = 3,
  CampaignContent = 4,
  ReferrerType = 5,
  ReferrerName = 6,
  ReferrerUrl = 7,
  Country = 8,
  Region = 9,
  City = 10,
  Browser = 11,
  Device = 12,
  Page = 13,
  EntryPage = 14,
  ExitPage = 15,
  Continent = 16,
}
