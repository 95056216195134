import { useState } from 'react';
import Image from 'next/image';
import { Box, Skeleton, styled, SxProps, Theme } from '@mui/material';

import isProductionEnv from 'utils/isProductionEnv';
import { isNativeAddress } from 'utils/validation/isAddress';

interface BaseProps {
  address: string;
  size?: number;
  symbol?: string;
  sx?: SxProps<Theme>;
  chainId?: number;
}

interface NftItemProps {
  type: 'nft';
  tokenId: string;
}

interface TokenProps {
  type: 'collection' | 'token';
}

type Props = BaseProps & (NftItemProps | TokenProps);

const imageLoader = ({ chainId, ...props }: Props) => {
  const url = isProductionEnv ? 'https://app.cookie3.co' : '';

  const { address, type } = props;
  if (type !== 'nft') {
    return `${url}/api/token/icon?address=${address}&type=${type}&chainId=${chainId}`;
  }

  const { tokenId } = props;

  return `${url}/api/token/icon?address=${address}&type=${type}&chainId=${chainId}&tokenId=${tokenId}`;
};

export default function TokenIcon({ size = 20, chainId = 1, ...props }: Props) {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  // if (isNativeAddress(props.address)) {
  //   return (
  //     <StyledWrapper size={size} {...props}>
  //       <Image
  //         src={`/icons/crm/ethereum.svg`}
  //         width={size}
  //         height={size}
  //         priority
  //         alt="T"
  //         style={{
  //           maxWidth: '100%',
  //           height: 'auto',
  //         }}
  //       />
  //     </StyledWrapper>
  //   );
  // }

  const url = isProductionEnv ? 'https://app.cookie3.co' : '';

  return (
    <StyledWrapper title={props.address} size={size} {...props}>
      <Image
        loader={() => imageLoader({ ...props, chainId })}
        src={`${url}/api/token/icon?address=${props.address}&type=${props.type}&chainId=${chainId}`}
        width={size}
        height={size}
        onError={(e: any) => {
          setIsError(true);
        }}
        onLoadingComplete={(e: any) => {
          setIsLoading(false);
        }}
        alt=""
        style={{
          height: size,
          width: size,
          maxWidth: '100%',
          opacity: isError ? 0 : 1,
        }}
      />

      {isError && (
        <StyledFallback>
          <Image
            width={size}
            height={size}
            src={
              props.type === 'collection'
                ? '/icons/nft.svg'
                : '/icons/token.svg'
            }
            alt="C"
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        </StyledFallback>
      )}
    </StyledWrapper>
  );
}

const StyledWrapper = styled(Box)<{ size: number }>(({ size }) => {
  return {
    height: size,
    width: size,
    borderRadius: '50%',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    position: 'relative',
  };
});

const StyledFallback = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 600,
  background: '#FDFDFF',
});
