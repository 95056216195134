import {
  PiBookOpen,
  PiDiscordLogo,
  PiEnvelope,
  PiQuestion,
  PiTelegramLogoFill,
} from 'react-icons/pi';
import Image from 'next/image';
import Link from 'next/link';
import { Box } from '@mui/material';

import Button from 'components/primitives/Button';
import HStack from 'components/primitives/HStack';
import Text from 'components/primitives/Text';

export default function HelpPopover() {
  return (
    <Box
      border="1px solid"
      borderColor="neutral.400"
      bgcolor="background.paper"
      width="400px"
      borderRadius={2}
      boxShadow="0px 4px 12px rgba(0, 0, 0, 0.05)"
    >
      <Box p={2}>
        <Text weight="medium" size="sm">
          Need help with your Cookie3 project?
        </Text>
        <Text
          sx={{
            lineHeight: '1.5 !important',
          }}
          mt={1}
          color="tertiary"
          size="xs"
          pr={2}
        >
          We&apos;re here to help you with any questions you might have. Choose
          the best way to reach out to us.
        </Text>
        <HStack mt={1.5} gap={1}>
          <a href="https://docs.cookie3.co" target="_blank">
            <Button
              variant="outline"
              sx={{ bgcolor: 'neutral.200' }}
              size="mini"
              Icon={PiBookOpen}
            >
              Docs
            </Button>
          </a>
          <a href="https://cookie3.co/blog" target="_blank">
            <Button
              variant="outline"
              sx={{ bgcolor: 'neutral.200' }}
              size="mini"
              Icon={PiQuestion}
            >
              Guides
            </Button>
          </a>
        </HStack>
        <Text
          sx={{
            lineHeight: '1.5 !important',
          }}
          mt={2}
          color="tertiary"
          size="xs"
          pr={2}
          mb={1.5}
        >
          Expected response time is based on your billing plan. Projects on paid
          plans are prioritized.
        </Text>
        <Link href="/src/pages/contact">
          <Button
            variant="outline"
            sx={{ bgcolor: 'neutral.200' }}
            size="mini"
            Icon={PiEnvelope}
          >
            Email us
          </Button>
        </Link>
      </Box>
      <Box p={2} borderTop="1px solid" borderColor="neutral.400">
        <Text size="sm" weight="medium">
          Reach out to us
        </Text>
        <Text
          sx={{
            lineHeight: '1.5 !important',
          }}
          mt={1}
          color="tertiary"
          size="xs"
          pr={2}
        >
          For general questions about Cookie3, to connect with the community or
          your account manager.
        </Text>
        <a href="https://t.me/St3cu" target="_blank">
          <Box
            mt={1.5}
            height="88px"
            p={1.5}
            bgcolor="blue.100"
            borderRadius={2}
            border="1px solid"
            borderColor="blue.200"
            position="relative"
            overflow={'hidden'}
            sx={{
              cursor: 'pointer',
              transition: 'all 0.2s ease-in-out',
              '&:hover': {
                opacity: 0.8,
                transform: 'scale(0.98)',
              },
              '&:active': {
                transform: 'scale(0.95)',
              },
            }}
          >
            <Image
              alt=""
              fill
              src="/assets/homepage/telegram-background.jpg"
              objectFit="cover"
              sizes="(max-width: 768px) 100vw, 33vw"
            />
            <Button
              variant="outline"
              sx={{
                bgcolor: 'background.paper',
                '&:hover': {
                  background: 'white !important',
                  opacity: 0.8,
                },
              }}
              size="mini"
              Icon={PiTelegramLogoFill}
            >
              Telegram
            </Button>
          </Box>
        </a>
        <Box
          mt={1}
          height="88px"
          p={1.5}
          bgcolor="blue.100"
          borderRadius={2}
          border="1px solid"
          borderColor="blue.200"
          position="relative"
          overflow={'hidden'}
          sx={{
            filter: 'grayscale(100%)',
            opacity: 0.3,
            pointerEvents: 'none',
          }}
        >
          <Image
            alt=""
            fill
            src="/assets/homepage/discord-background.png"
            objectFit="cover"
            sizes="(max-width: 768px) 100vw, 33vw"
          />
          <Button
            variant="white"
            sx={{
              bgcolor: 'background.paper',
              '&:hover': {
                background: 'white !important',
              },
            }}
            size="mini"
            Icon={PiDiscordLogo}
          >
            Discord Community - coming soon
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
