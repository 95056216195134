import { ComponentType, PropsWithChildren } from 'react';
import { IconBaseProps } from 'react-icons';
import { PiArrowLeft, PiArrowRight, PiHeart, PiX } from 'react-icons/pi';
import { Box, IconButton } from '@mui/material';
import { PopoverContentProps, StepType } from '@reactour/tour';
import { prependOnceListener } from 'process';

import Button from 'components/primitives/Button';
import HStack from 'components/primitives/HStack';
import Text from 'components/primitives/Text';

export default function OnboardingStepCustomContent(
  props: PropsWithChildren<
    PopoverContentProps & {
      title?: string;
      Icon?: ComponentType<IconBaseProps>;
    }
  >,
) {
  const isLastStep = props.currentStep === props.steps.length - 1;
  const content = props.steps[props.currentStep].content;
  const title = (
    props.steps[props.currentStep] as StepType & {
      title?: string;
      Icon?: ComponentType<IconBaseProps>;
    }
  ).title;

  const Icon = (
    props.steps[props.currentStep] as StepType & {
      title?: string;
      Icon?: ComponentType<IconBaseProps>;
    }
  ).Icon;

  const size = '16px';

  return (
    <Box
      // border="1px solid"
      borderColor="neutral.400"
      borderRadius={2}
      bgcolor="neutral.300"
      boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
      position="relative"
    >
      <Box
        position="absolute"
        top={'50%'}
        sx={{
          transform:
            'translateY(-50%) rotate(-60deg) skewX(-30deg) scale(1,.866)',
          width: size,
          height: size,

          borderTopLeftRadius: '30%',

          '&:after': {
            borderTopRightRadius: '30%',
            width: size,
            height: size,
            content: '""',
            position: 'absolute',
            transform:
              'rotate(-135deg) skewX(-45deg) scale(1.414,.707) translate(0,-50%)',
          },
          '&:before': {
            borderTopRightRadius: '30%',
            width: size,
            height: size,
            position: 'absolute',
            content: '""',
            transform:
              'rotate(135deg) skewY(-45deg) scale(.707,1.414) translate(50%)',
          },
        }}
        left={-8}
        zIndex={2}
        bgcolor="neutral.300"
      ></Box>
      <Box position="absolute" top={8} right={8} zIndex={1}>
        <IconButton
          onClick={(e) => {
            props.onClickClose?.(e as any);
          }}
          sx={{ p: 0.25 }}
          size="small"
        >
          <PiX />
        </IconButton>
      </Box>

      <Box px={2} py={2}>
        {Icon && (
          <Box mb={1} display="flex" alignItems="center">
            <Icon size={24} color={'black'} />
          </Box>
        )}
        <Text weight="medium" size="base" color="primary" textAlign={'left'}>
          {title}
        </Text>
        <Box fontSize="14px" mt={1} color="text.secondary">
          {typeof content === 'function' ? content({ ...props }) : content}
        </Box>
      </Box>

      <Box px={2} py={1.5} borderTop="1px solid" borderColor="neutral.400">
        <HStack justifyContent="space-between" vcenter>
          <Text size="sm" color="tertiary">
            {props.currentStep + 1} of {props.steps.length}
          </Text>
          <HStack gap={1} vcenter>
            <Button
              size="mini"
              Icon={PiArrowLeft}
              sx={{ height: '26px', px: 0.75 }}
              variant="text"
              onClick={() => props.setCurrentStep(props.currentStep - 1)}
            ></Button>
            <Button
              size="mini"
              Icon={isLastStep ? PiHeart : PiArrowRight}
              sx={{ height: '26px', px: 0.75 }}
              // variant="lightOutlined"
              onClick={(e) => {
                if (isLastStep) {
                  props.onClickClose?.(e as any);
                  props.setIsOpen(false);
                }
                props.setCurrentStep(props.currentStep + 1);
              }}
            >
              {isLastStep ? 'Finish' : 'Next'}
            </Button>
          </HStack>
        </HStack>
      </Box>
    </Box>
  );
}
