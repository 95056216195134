import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { Box, MenuItem, Stack, styled } from '@mui/material';

import Tag from 'common/Tag';
import Text from 'components/primitives/Text';
import { TeamResItem } from 'services/teamManagement/useGetTeams';

interface Props {
  onClick: (team: TeamResItem) => void;
  isSelected: boolean;
  team: TeamResItem;
}

export default function TeamMenuItem({ onClick, isSelected, team }: Props) {
  return (
    <MenuItem
      disableRipple
      sx={{
        mx: 0.5,
        borderRadius: '8px',
        px: 1,
      }}
      onClick={() => onClick(team)}
      selected={isSelected}
    >
      <MenuContentBox>
        <Stack gap={1} direction="row" alignItems="center">
          <Box
            component="span"
            sx={{
              fontWeight: isSelected ? 500 : 400,
              color: 'neutral.800',
            }}
          >
            <Text size="xs">{team.name}</Text>
          </Box>

          {team.isSubscriptionActive && (
            <Tag size="small">
              {team.isAdmin ? 'Admin' : team.subscriptionType}
            </Tag>
          )}
          {team.isDemo && (
            <Tag size="small" color="red">
              Demo
            </Tag>
          )}
        </Stack>
        {isSelected && (
          <CheckIconWrapper>
            <CheckIcon sx={{ opacity: 0.3 }} />
          </CheckIconWrapper>
        )}
      </MenuContentBox>
    </MenuItem>
  );
}

const MenuContentBox = styled(Box)({
  gap: 6,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '2px 0px',
});

const CheckIconWrapper = styled(Box)({
  '& svg': { width: '16px', height: '16px' },
  alignItems: 'center',
  display: 'flex',
});
