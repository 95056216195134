import { useCallback, useLayoutEffect, useRef, useState } from 'react';

export default function useDetectOverflow(deps?: any[]) {
  const ref = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);

  /**
   * Detect if a given element is overflowing
   *
   * If it is: return true
   * If it isn't: return false
   * If it's scrolled all the way down, return false
   *
   * Handle the following cases additionally:
   * 1. If the element's height
   */

  const detectOverflow = useCallback(() => {
    if (!ref.current)
      return {
        isOverflowing: false,
        isScrolledToBottom: false,
      };
    const { scrollHeight, clientHeight, scrollTop } = ref.current;
    const isOverflowing = scrollHeight > clientHeight;

    const isScrolledToBottom = scrollHeight - scrollTop === clientHeight;

    return {
      isOverflowing,
      isScrolledToBottom,
    };
  }, [ref]);

  const handleScroll = useCallback(() => {
    const { isOverflowing, isScrolledToBottom } = detectOverflow();

    setIsOverflowing(isOverflowing);
    setIsScrolledToBottom(isScrolledToBottom);
  }, [detectOverflow]);

  useLayoutEffect(() => {
    if (!ref.current) return;
    handleScroll();
    ref.current.addEventListener('scroll', handleScroll);

    const resizeObserver = new ResizeObserver(detectOverflow);
    resizeObserver.observe(ref.current);
    return () => {
      if (!ref.current) return;
      // eslint-disable-next-line react-hooks/exhaustive-deps
      ref.current.removeEventListener('scroll', handleScroll);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      resizeObserver.disconnect();
    };
  }, [detectOverflow, handleScroll, ref, ref.current?.clientHeight, deps]);

  return { ref, isOverflowing, isScrolledToBottom };
}
