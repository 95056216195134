import * as yup from 'yup';

const stringArraySchema = yup.array().of(yup.string()).optional();
const isOrIncludes = yup.mixed().oneOf(['is', 'includes']).optional();

const singleObjectSchema = yup
  .object()
  .shape({
    isOrIncludes,
    values: stringArraySchema,
  })
  .optional();

export const websiteAdvancedFilterSchema = yup
  .object()
  .shape({
    utmParameters: yup
      .object()
      .shape({
        utm_source: singleObjectSchema,
        utm_medium: singleObjectSchema,
        utm_campaign: singleObjectSchema,
        utm_term: singleObjectSchema,
        utm_content: singleObjectSchema,
      })
      .optional(),
    trafficSource: yup
      .object()
      .shape({
        referrerUrl: singleObjectSchema,
        referrerType: singleObjectSchema,
        referrerName: singleObjectSchema,
      })
      .optional(),
    location: yup
      .object()
      .shape({
        continent: singleObjectSchema,
        country: singleObjectSchema,
        region: singleObjectSchema,
        city: singleObjectSchema,
      })
      .optional(),
    userAgent: yup
      .object()
      .shape({
        browser: singleObjectSchema,
        device: singleObjectSchema,
        os: singleObjectSchema,
      })
      .optional(),
    page: yup
      .object()
      .shape({
        page: singleObjectSchema,
        entryPage: singleObjectSchema,
        exitPage: singleObjectSchema,
      })
      .optional(),
  })
  .optional();

export type WebsiteAdvancedFilterSchema = yup.InferType<
  typeof websiteAdvancedFilterSchema
>;
