import invert from 'lodash/invert';
import pick from 'lodash/pick';

import isProductionEnv from 'utils/isProductionEnv';
import { Chain, ChainId } from 'types/Chain';

export const CHAINS_NAME_ID_PAIR: Record<string, ChainId> = {
  Ethereum: 1,
  'BNB Chain': 56,
  Polygon: 137,
  Avalanche: 43114,
  Fantom: 250,
  Arbitrum: 42161,
  Base: 8453,
  Mantle: 5000,
  Goerli: 5,
  'Mantle Testnet': 5001,
  Optimism: 10,
  Linea: 59144,
  Ronin: 2020,
  XLayer: 196,
  Blast: 81457,
  'Astar zkEVM': 3776,
};

export const CHAIN_ID_TO_NAME = invert(CHAINS_NAME_ID_PAIR);

export const SUPPORTED_EXPLORER_CHAINS = pick(CHAINS_NAME_ID_PAIR, [
  'Ethereum',
  'Polygon',
  'Base',
  'Avalanche',
  'Arbitrum',
  'XLayer',
  'Blast',
  'Mantle',
  'BNB Chain',
  'Astar zkEVM',
]);

export const CHAINS_WITH_ICONS: Partial<Record<ChainId, Chain>> = {
  1: {
    name: 'Ethereum',
    iconLight: '/assets/chains/ethereum-transparent.svg',
    iconDark: '/assets/chains/ethereum.svg',
    className: 'bg-gradient-to-br from-[#627EEA] to-[#4A69DE] text-white',
  },
  56: {
    name: 'BNB Chain',
    iconLight: '/assets/chains/binance-light_1.svg',
    iconDark: '/assets/chains/binance-light_2.svg',
    className: 'bg-gradient-to-br from-[#F3BA2F] to-[#F0B90B] text-white',
  },
  137: {
    name: 'Polygon',
    iconLight: '/assets/chains/polygon-light.svg',
    iconDark: '/assets/chains/polygon_1.svg',
    className: 'bg-gradient-to-br from-[#8247E5] to-[#4A69DE] text-white',
  },
  43114: {
    name: 'Avalanche',
    iconLight: '/assets/chains/avalanche.svg',
    iconDark: '/assets/chains/avalanche.svg',
    className: 'bg-gradient-to-br from-[#D64F4A] to-[#D64F6A] text-white',
  },
  250: {
    name: 'Fantom',
    iconLight: '/assets/chains/fantom.svg',
    iconDark: '/assets/chains/fantom.svg',
    className: 'bg-gradient-to-br from-[#0A15EE] to-[#1969B0] text-white',
  },
  42161: {
    name: 'Arbitrum',
    iconLight: '/assets/chains/arbitrum.svg',
    iconDark: '/assets/chains/arbitrum.svg',
    className: 'bg-gradient-to-br from-[#2D374B] to-[#28A0F0] text-white',
  },
  8453: {
    name: 'Base',
    iconLight: '/assets/chains/base.svg',
    iconDark: '/assets/chains/base.svg',
    className: 'bg-gradient-to-br from-[#0052FF] to-[#0052FF] text-white',
  },
  5000: {
    name: 'Mantle',
    iconLight: '/assets/chains/mantle.svg',
    iconDark: '/assets/chains/mantle.svg',
    className: 'bg-gradient-to-br from-black to-neutral-800 text-white',
  },
  2020: {
    name: 'Ronin',
    iconLight: '/assets/chains/ronin.jpg',
    iconDark: '/assets/chains/ronin.jpg',
    className: 'bg-gradient-to-br from-[#000000] to-[#000000] text-white',
  },
  196: {
    name: 'X Layer',
    iconLight: '/assets/chains/x-layer.svg',
    iconDark: '/assets/chains/x-layer.svg',
    className: 'bg-gradient-to-b from-[#000000] to-[#000000] text-white',
  },
  81457: {
    name: 'Blast',
    iconLight: '/assets/chains/blast.svg',
    iconDark: '/assets/chains/blast.svg',
    className: 'bg-gradient-to-b from-[#000000] to-[#000000] text-white',
  },
  59144: {
    name: 'Linea',
    iconLight: '/assets/chains/linea.png',
    iconDark: '/assets/chains/linea.png',
    className: 'bg-gradient-to-b from-[#000000] to-[#000000] text-white',
  },
  3776: {
    name: 'Astar zkEVM',
    iconLight: '/assets/chains/astar-zkevm.png',
    iconDark: '/assets/chains/astar-zkevm.png',
    className: 'bg-gradient-to-b from-[#000000] to-[#000000] text-white',
  },
};

export const CHAINS_WITH_ICONS_ARRAY = Object.entries(CHAINS_WITH_ICONS).map(
  ([id, chain]) => ({
    id: Number(id) as ChainId,
    ...chain,
  }),
);

export const SUPPORTED_EXPLORER_CHAINS_IDS = Object.values(
  SUPPORTED_EXPLORER_CHAINS,
);
