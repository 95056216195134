import React from 'react';
import { PiChat } from 'react-icons/pi';

import FeedbackForm from 'common/FeedbackWidget/FeedbackForm';
import PopperWrapper from 'common/PopperWrapper';
import PopoverContainer from 'common/PopperWrapper/PopoverContainer';
import Button from 'components/primitives/Button';
import { cookie3Analytics } from 'lib/cookie3-analytics';

export default function FeedbackButton() {
  return (
    <PopperWrapper
      sx={{
        zIndex: 99999,
      }}
      popperOptions={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 8],
            },
          },
        ],
      }}
      placement="bottom-end"
      content={(close) => (
        <PopoverContainer p={2} maxWidth={'500px'}>
          <FeedbackForm onSuccess={() => close()} />
        </PopoverContainer>
      )}
    >
      {() => (
        <Button
          onClick={() => {
            cookie3Analytics.trackEvent({
              category: 'action',
              action: 'open feedback form',
            });
          }}
          variant="outline"
          sx={{
            bgcolor: 'neutral.200',
            display: {
              xs: 'none',
              lg: 'block',
            },
          }}
          size="mini"
          Icon={PiChat}
        >
          Feedback
        </Button>
      )}
    </PopperWrapper>
  );
}
