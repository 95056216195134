export default function MantleSmIcon() {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.88844 4.90868L0.760761 3.82767C0.593815 4.15374 0.453509 4.49577 0.339844 4.84489L2.61138 5.57856C2.68597 5.34818 2.77832 5.12312 2.88844 4.90868Z"
        fill="white"
      />
      <path
        d="M4.69258 3.00398L5.87186 5.02956C6.04413 4.93032 6.22884 4.85412 6.41887 4.80272L5.8097 2.54145C5.96421 2.50069 6.12228 2.46524 6.28212 2.44043L5.90738 0.0870056C5.54507 0.143715 5.18454 0.230551 4.83644 0.343969L5.56461 2.57335C5.33195 2.64955 5.10639 2.74347 4.88972 2.85335L3.81878 0.767515C3.49376 0.934098 3.17763 1.12726 2.88281 1.34347L4.2912 3.26803C4.41907 3.17233 4.55405 3.0855 4.69258 3.00398Z"
        fill="white"
      />
      <path
        d="M11.0183 4.68203L8.98828 5.85874C9.08774 6.03064 9.16588 6.21494 9.21739 6.40456L11.4836 5.79494C11.5262 5.94912 11.56 6.10684 11.5848 6.26456L13.9434 5.89064C13.8866 5.52912 13.7995 5.16937 13.6841 4.82203L11.4516 5.55038C11.3753 5.31823 11.2811 5.09317 11.171 4.87696L13.2614 3.80658C13.0944 3.48227 12.9009 3.16683 12.6842 2.87265L10.7572 4.27797C10.8496 4.40911 10.9366 4.5438 11.0183 4.68203Z"
        fill="white"
      />
      <path
        d="M10.1904 0.756824C9.86365 0.590241 9.52087 0.450241 9.171 0.336823L8.4375 2.60341C8.66661 2.67784 8.89216 2.77 9.10884 2.87987L10.1904 0.756824Z"
        fill="white"
      />
      <path
        d="M9.34015 2.96312L8.14844 5.02769C8.32071 5.12693 8.48055 5.24921 8.62086 5.38921L11.9633 2.0416C11.704 1.78287 11.4216 1.54362 11.1268 1.32919L9.74863 3.22718C9.61721 3.13148 9.48223 3.04287 9.34015 2.96312Z"
        fill="white"
      />
      <path
        d="M2.9697 4.67513L5.03699 5.86602C5.13645 5.69412 5.25899 5.53463 5.3993 5.39285L2.04795 2.05588C1.78865 2.31461 1.54888 2.59639 1.33398 2.89056L3.23433 4.26753C3.1402 4.39867 3.0514 4.53513 2.9697 4.67513Z"
        fill="white"
      />
      <path
        d="M7.73776 2.3995L8.09829 0.0832912C7.74131 0.0283542 7.37722 0 7.01314 0H7.00781V4.72458H7.01314C7.21205 4.72458 7.41097 4.74939 7.601 4.80078L8.21373 2.49875C8.05744 2.45621 7.89938 2.42431 7.73776 2.3995Z"
        fill="white"
      />
      <path
        d="M4.81124 6.41355L2.50419 5.80038C2.46334 5.95633 2.4296 6.11583 2.40473 6.27532L0.0834727 5.9138C0.028416 6.27001 0 6.63684 0 7.00014H4.73487C4.73487 6.79988 4.76152 6.60317 4.81124 6.41355Z"
        fill="white"
      />
      <path
        d="M11.1426 9.09132L13.2703 10.1723C13.4372 9.84626 13.5775 9.50424 13.6912 9.15512L11.4196 8.42145C11.345 8.65183 11.2527 8.87689 11.1426 9.09132Z"
        fill="white"
      />
      <path
        d="M9.33389 10.9944L8.15639 8.96884C7.98412 9.06808 7.79941 9.14429 7.60938 9.19568L8.21855 11.457C8.06404 11.4977 7.90597 11.5332 7.74613 11.558L8.12087 13.9114C8.48318 13.8547 8.84371 13.7678 9.19181 13.6544L8.46364 11.4251C8.6963 11.3489 8.92186 11.2549 9.13853 11.1451L10.2095 13.2309C10.5345 13.0643 10.8506 12.8711 11.1454 12.6549L9.73883 10.7304C9.60918 10.8278 9.4742 10.9147 9.33389 10.9944Z"
        fill="white"
      />
      <path
        d="M3.013 9.31803L5.04299 8.14131C4.94353 7.96942 4.86539 7.78511 4.81389 7.59549L2.54768 8.20511C2.50506 8.05094 2.47131 7.89321 2.44645 7.73549L0.0878906 8.10942C0.144723 8.47094 0.231749 8.83068 0.34719 9.17803L2.57965 8.44967C2.65602 8.68005 2.75015 8.90689 2.86026 9.12309L0.769883 10.1935C0.936829 10.5178 1.13042 10.8332 1.34709 11.1274L3.27407 9.72208C3.18172 9.59094 3.0947 9.45626 3.013 9.31803Z"
        fill="white"
      />
      <path
        d="M3.83789 13.2434C4.16468 13.41 4.50745 13.55 4.85733 13.6634L5.59082 11.3968C5.36172 11.3224 5.13616 11.2302 4.91949 11.1203L3.83789 13.2434Z"
        fill="white"
      />
      <path
        d="M4.68568 11.0371L5.8774 8.97251C5.70512 8.87327 5.54528 8.75099 5.40497 8.61099L2.0625 11.9586C2.3218 12.2173 2.60419 12.4566 2.89901 12.671L4.2772 10.773C4.40863 10.867 4.54538 10.9556 4.68568 11.0371Z"
        fill="white"
      />
      <path
        d="M11.0605 9.3252L8.99317 8.13431C8.89371 8.30621 8.77117 8.4657 8.63086 8.60747L11.984 11.9444C12.2433 11.6857 12.4831 11.4039 12.698 11.1098L10.7976 9.7328C10.8917 9.59988 10.9805 9.4652 11.0605 9.3252Z"
        fill="white"
      />
      <path
        d="M6.42718 9.19922L5.81445 11.5013C5.97074 11.542 6.13059 11.5757 6.29043 11.6005L5.92989 13.9167C6.28688 13.9716 6.65096 14 7.01504 14H7.02037V9.27542H7.01504C6.81613 9.27542 6.61722 9.24884 6.42718 9.19922Z"
        fill="white"
      />
      <path
        d="M9.29512 7.00006C9.29512 7.20032 9.27025 7.39702 9.21875 7.58665L11.5258 8.19981C11.5667 8.04386 11.6004 7.88437 11.6253 7.72487L13.9465 8.08639C14.0016 7.73019 14.03 7.36335 14.03 7.00006H9.29512Z"
        fill="white"
      />
    </svg>
  );
}
