import { useCallback, useState } from 'react';
import { useAppStateContext } from 'src/store/AppProvider';

import fetcher from 'utils/fetcher';
import supabase from 'lib/supabase';

export function useLogout() {
  const [isLoading, setLoading] = useState(false);
  const { user } = useAppStateContext();

  const handleDestroySession = useCallback(async () => {
    if (!user.data?.id) return;
    try {
      await fetcher('/api/logout', {
        method: 'POST',
      });
    } catch (error) {
      console.error(error);
    }
  }, [user]);

  const handleLogout = useCallback(async () => {
    setLoading(true);

    await supabase.auth.signOut();
    await handleDestroySession();

    setLoading(false);
    window.location.href = '/';
  }, [handleDestroySession]);

  return {
    handleLogout,
    isLoading,
  };
}
