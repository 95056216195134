import { createContext, useContext } from 'react';

const TooltipThemeContext = createContext<{
  theme?: 'light' | 'dark';
}>({ theme: 'dark' });

export const useTooltipTheme = () => {
  const context = useContext(TooltipThemeContext);

  if (!context) {
    throw new Error(
      'TooltipWrapper element must be used within the Tooltip Provider',
    );
  }

  return context;
};

export default function ThemeProvider({
  children,
  theme,
}: {
  children: React.ReactNode;
  theme: 'light' | 'dark';
}) {
  return (
    <TooltipThemeContext.Provider value={{ theme }}>
      {children}
    </TooltipThemeContext.Provider>
  );
}
