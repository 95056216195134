import defaults from 'lodash/defaults';
import { v4 as uuidv4 } from 'uuid';

import { Filters } from 'components/views/explorer/store/explorerStore.interface';
import { ChainId } from 'types/Chain';
import { ExplorerRequestFilterPayload } from 'types/Explorer';
import {
  BalanceFilter,
  BoughtSoldFilter,
  FilterType,
  FirstActivityFilter,
  HoldingFilter,
  InteractionFilter,
  LastActivityFilter,
} from 'types/Explorer/Filters';

import { getIntialStateForFilters } from './getDefaultFilterValue';

export const convertFiltersFromApiResIntoStore = (
  filtersFromApi: ExplorerRequestFilterPayload,
  chainId: ChainId,
): Filters => {
  const initial = getIntialStateForFilters(chainId);
  if (!filtersFromApi.filters) return initial;

  const active = filtersFromApi.filters.reduce((acc, filter) => {
    switch (filter.filterType) {
      case FilterType.Balance: {
        const balanceEntry = convertToNetWorthEntry(filter);
        if (!hasFilterWithKey('balance', acc)) {
          acc.balance = [balanceEntry];
        } else {
          acc.balance?.push(balanceEntry);
        }
        break;
      }
      case FilterType.Holding: {
        const holdingEntry = convertToTokenHoldingEntry(filter);
        if (!hasFilterWithKey('tokensHeld', acc)) {
          acc.tokensHeld = [holdingEntry];
        } else {
          acc.tokensHeld?.push(holdingEntry);
        }
        break;
      }
      case FilterType.FirstActivity: {
        const firstActivityEntry = convertToActivityEntry(filter);
        if (!hasFilterWithKey('firstActivity', acc)) {
          acc.firstActivity = [firstActivityEntry];
        } else {
          acc.firstActivity?.push(firstActivityEntry);
        }
        break;
      }
      case FilterType.LastActivity: {
        const lastActivityEntry = convertToActivityEntry(filter);
        if (!hasFilterWithKey('lastActivity', acc)) {
          acc.lastActivity = [lastActivityEntry];
        } else {
          acc.lastActivity?.push(lastActivityEntry);
        }
        break;
      }
      case FilterType.Site: {
        if (!hasFilterWithKey('website', acc) && filter.basicFilterInput) {
          acc.website = {
            sitesIds: filter.basicFilterInput?.sitesIds,
          };
        }

        const customFiltersInput = filter.customFiltersInput;
        if (customFiltersInput) {
          if (
            !hasFilterWithKey('location', acc) &&
            (customFiltersInput?.equalFilterCountry ||
              customFiltersInput?.equalFilterContinent ||
              customFiltersInput?.equalFilterRegion ||
              customFiltersInput?.equalFilterCity)
          ) {
            acc.location = {
              equalFilterCountry: customFiltersInput.equalFilterCountry || [],
              equalFilterContinent:
                customFiltersInput.equalFilterContinent || [],
              equalFilterRegion: customFiltersInput.equalFilterRegion || [],
              equalFilterCity: customFiltersInput.equalFilterCity || [],
            };
          }

          if (
            !hasFilterWithKey('browserAndOS', acc) &&
            (customFiltersInput?.equalBrowser ||
              customFiltersInput?.equalDeviceType)
          ) {
            acc.browserAndOS = {
              equalBrowser: customFiltersInput.equalBrowser || [],
              equalDeviceType: customFiltersInput.equalDeviceType || [],
            };
          }

          if (
            !hasFilterWithKey('campaign', acc) &&
            (customFiltersInput?.equalFilterCampaignKeyword ||
              customFiltersInput?.equalFilterCampaignContent ||
              customFiltersInput?.equalFilterCampaignMedium ||
              customFiltersInput?.equalFilterCampaignName ||
              customFiltersInput?.equalFilterCampaignSource)
          ) {
            acc.campaign = {
              equalFilterCampaignName:
                customFiltersInput.equalFilterCampaignName || [],
              equalFilterCampaignKeyword:
                customFiltersInput.equalFilterCampaignKeyword || [],
              equalFilterCampaignContent:
                customFiltersInput.equalFilterCampaignContent || [],
              equalFilterCampaignMedium:
                customFiltersInput.equalFilterCampaignMedium || [],
              equalFilterCampaignSource:
                customFiltersInput.equalFilterCampaignSource || [],
            };
          }
        }
        break;
      }
      case FilterType.BuyOrSell: {
        const boughtSoldEntry = convertToBoughtOrSoldEntry(filter);
        if (!hasFilterWithKey('boughtSoldFilter', acc)) {
          acc.boughtSoldFilter = [boughtSoldEntry];
        } else {
          acc.boughtSoldFilter?.push(boughtSoldEntry);
        }
        break;
      }
      case FilterType.Interaction: {
        const interactionEntry = convertToInteractedWithContractEntry(filter);
        if (!hasFilterWithKey('interactedWithContract', acc)) {
          acc.interactedWithContract = [interactionEntry];
        } else {
          acc.interactedWithContract?.push(interactionEntry);
        }
        break;
      }
      case FilterType.ManualSource: {
        const manualSourceEntry = {
          source: filter.condition.source,
          addressNotIn: filter.condition.addressNotIn,
        };
        if (!hasFilterWithKey('manualSourcesFilter', acc)) {
          acc.manualSourcesFilter = {
            sources: [manualSourceEntry],
          };
        } else {
          acc.manualSourcesFilter?.sources.push(manualSourceEntry);
        }
      }
    }

    return acc;
  }, {} as Filters);

  return defaults(active, initial);
};

const hasFilterWithKey = (key: keyof Filters, object: Filters) => !!object[key];

const convertToBoughtOrSoldEntry = (
  filter: BoughtSoldFilter,
): NonNullable<Filters['boughtSoldFilter']>[number] => ({
  id: uuidv4(),
  chain: filter.condition.chain,
  address: filter.condition.address,
  bought: filter.condition.bought,
  timeRangeCondition: filter.condition.timeRangeCondition,
  valueUsd: filter.condition.valueUsdCondition?.value ?? null,
  isValueUsdGreaterThan:
    filter.condition.valueUsdCondition?.greaterThan ?? true,
});

const convertToInteractedWithContractEntry = (
  filter: InteractionFilter,
): NonNullable<Filters['interactedWithContract']>[number] => ({
  id: uuidv4(),
  address: filter.condition.address,
  chain: filter.condition.chain,
  timeRangeCondition: filter.condition.timeRangeCondition,
});

const convertToActivityEntry = (
  filter: FirstActivityFilter | LastActivityFilter,
) => ({
  id: uuidv4(),
  chain: filter.condition.chain,
  greaterThan: filter.condition.greaterThan,
  value: filter.condition.value,
});

const convertToNetWorthEntry = (filter: BalanceFilter) => ({
  id: uuidv4(),
  chain: filter.condition.chain,
  greaterThan: filter.condition.greaterThan,
  value: filter.condition.value,
});

const convertToTokenHoldingEntry = (
  filter: HoldingFilter,
): NonNullable<Filters['tokensHeld']>[number] => {
  const typeOfValue = filter.condition.valueRawCondition ? 'raw' : 'usd';
  const valueObj =
    typeOfValue === 'raw'
      ? filter.condition.valueRawCondition
      : filter.condition.valueUsdCondition;

  return {
    id: uuidv4(),
    address: filter.condition.address,
    chain: filter.condition.chain,
    typeOfValue,
    value: valueObj?.value ?? null,
    greaterThan: valueObj?.greaterThan ?? true,
  };
};
