export default function AvalancheSmIcon() {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.96145 14H1.30739C0.749692 14 0.474212 14 0.306243 13.8926C0.124818 13.7751 0.0139556 13.5804 0.000516684 13.3654C-0.00957162 13.1675 0.128187 12.9256 0.403667 12.4422L6.95688 0.898927C7.23573 0.408755 7.37682 0.163661 7.55488 0.0730184C7.74637 -0.0243395 7.97483 -0.0243395 8.16633 0.0730184C8.34439 0.163661 8.48549 0.408755 8.76432 0.898927L10.1115 3.24907L10.1184 3.26106C10.4196 3.78692 10.5723 4.0536 10.639 4.33348C10.7129 4.639 10.7129 4.9613 10.639 5.26683C10.5718 5.54883 10.4206 5.81744 10.1149 6.35125L6.67264 12.4321L6.66373 12.4477C6.36057 12.9779 6.20694 13.2466 5.99401 13.4493C5.7622 13.671 5.48335 13.832 5.17762 13.9228C4.89877 14 4.58634 14 3.96145 14ZM10.6638 14H14.4668C15.0279 14 15.3101 14 15.4782 13.8894C15.6596 13.7718 15.7737 13.5736 15.7839 13.3589C15.7936 13.1673 15.6588 12.9349 15.3948 12.4795C15.3857 12.464 15.3766 12.4482 15.3673 12.4322L13.4623 9.17557L13.4407 9.1389C13.173 8.68655 13.0379 8.4581 12.8643 8.36981C12.6729 8.27243 12.4477 8.27243 12.2563 8.36981C12.0816 8.46045 11.9405 8.69883 11.6616 9.17892L9.76347 12.4356L9.75697 12.4468C9.4791 12.9261 9.34023 13.1656 9.35025 13.3622C9.36369 13.5771 9.47455 13.7751 9.65596 13.8926C9.82059 14 10.1028 14 10.6638 14Z"
        fill="white"
      />
    </svg>
  );
}
