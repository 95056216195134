export default function LineaIcon() {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15.5" cy="15.5" r="15.5" fill="black" />
      <mask
        id="mask0_11024_2680"
        style={{
          maskType: 'luminance',
        }}
        maskUnits="userSpaceOnUse"
        x="8"
        y="6"
        width="17"
        height="18"
      >
        <path d="M25 6H8V23.8819H25V6Z" fill="white" />
      </mask>
      <g mask="url(#mask0_11024_2680)">
        <path
          d="M22.1185 23.882H8V8.90173H11.2303V20.9787H22.1185V23.8804V23.882Z"
          fill="white"
        />
        <path
          d="M22.1158 11.8033C23.7072 11.8033 24.9973 10.5042 24.9973 8.90164C24.9973 7.29911 23.7072 6 22.1158 6C20.5245 6 19.2344 7.29911 19.2344 8.90164C19.2344 10.5042 20.5245 11.8033 22.1158 11.8033Z"
          fill="white"
        />
      </g>
    </svg>
  );
}
