export default function FantomIcon() {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_9885_18210)">
        <path
          d="M15.5 31C24.0604 31 31 24.0604 31 15.5C31 6.93959 24.0604 0 15.5 0C6.93959 0 0 6.93959 0 15.5C0 24.0604 6.93959 31 15.5 31Z"
          fill="#13B5EC"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.6625 12.4969L20.15 10.4625V14.5312L16.6625 12.4969ZM20.15 21.2156L15.5 23.9281L10.85 21.2156V16.4687L15.5 19.1812L20.15 16.4687V21.2156ZM10.85 10.4625L14.3375 12.4969L10.85 14.5312V10.4625ZM16.0813 13.4656L19.5688 15.5L16.0813 17.5344V13.4656ZM14.9187 17.5344L11.4313 15.5L14.9187 13.4656V17.5344ZM19.5688 9.49375L15.5 11.8187L11.4313 9.49375L15.5 7.07188L19.5688 9.49375ZM9.6875 9.10625V21.7969L15.5 25.0906L21.3125 21.7969V9.10625L15.5 5.8125L9.6875 9.10625Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_9885_18210">
          <rect width="31" height="31" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
