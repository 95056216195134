import React from 'react';
import { PiInfo } from 'react-icons/pi';

import PopperWrapper from 'common/PopperWrapper';
import HelpPopover from 'components/Dashboard/HelpButton/HelpPopover';
import Button from 'components/primitives/Button';
import { cookie3Analytics } from 'lib/cookie3-analytics';

export default function HelpButton() {
  return (
    <PopperWrapper
      sx={{
        zIndex: 99999,
      }}
      popperOptions={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 8],
            },
          },
        ],
      }}
      placement="bottom-end"
      content={<HelpPopover />}
    >
      {({ isOpen }) => (
        <Button
          onClick={() => {
            cookie3Analytics.trackEvent({
              category: 'action',
              action: 'open help',
            });
          }}
          variant="outline"
          sx={{ bgcolor: 'neutral.200' }}
          size="mini"
          Icon={PiInfo}
        >
          Help
        </Button>
      )}
    </PopperWrapper>
  );
}
