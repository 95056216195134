import React from 'react';
import { PiBell, PiCaretUpDown } from 'react-icons/pi';
import { useRouter } from 'next/router';
import { Box } from '@mui/material';
import chroma from 'chroma-js';
import { useInitializeChainStore } from 'src/store/chain/hooks/useInitializeChainStore';
import { useInitializeTeamStore } from 'src/store/team/hooks/useInitializeTeamStore';

import Tag from 'common/Tag';
import { onboardingSteps } from 'components/Dashboard/constants/onboardingSteps';
import FeedbackButton from 'components/Dashboard/FeedbackButton';
import GoToPricingButton from 'components/Dashboard/GoToPricingButton';
import DesktopHeaderDemoTeamOverlay from 'components/Dashboard/HeaderDemoTeamOverlay/desktop';
import MobileHeaderDemoTeamOverlay from 'components/Dashboard/HeaderDemoTeamOverlay/mobile';
import HelpButton from 'components/Dashboard/HelpButton';
import MobileHeader from 'components/Dashboard/MobileHeader';
import TokenSearch from 'components/Dashboard/TokenSearch';
import UpgradePlanButton from 'components/Dashboard/UpgradePlanButton';
import OnboardingWrapper from 'components/Onboarding/hooks/useOnboarding';
import Button from 'components/primitives/Button';
import HStack from 'components/primitives/HStack';
import Text from 'components/primitives/Text';
import TeamDropdown from 'components/team-management/TeamDropdown';
import { sideBarDesktopWidth } from 'utils/constants';
import { useDisclosure } from 'utils/hooks';
import { TeamResItem, useGetTeams } from 'services/teamManagement/useGetTeams';

import { useIsLoggedIn } from '../../store/AppProvider';
import NewSidebar from './NewSidebar';
import MobileSidebar from './NewSidebar/Mobile';

export interface DashboardProps {
  children?: React.ReactNode;
  pageName?: string;
  shouldShowTeamChooser?: boolean;
  teamChooserTheme?: 'light' | 'dark';
  containerClassName?: string;
}

export default function Dashboard({
  children,
  shouldShowTeamChooser = true,
  teamChooserTheme = 'dark',
  containerClassName,
  ...props
}: DashboardProps) {
  useInitializeTeamStore();
  useInitializeChainStore();

  const router = useRouter();
  const isLoggedIn = useIsLoggedIn();
  const mobileNavigationDisclosure = useDisclosure();
  const { selectedTeam } = useGetTeams();

  return (
    <OnboardingWrapper
      steps={onboardingSteps}
      condition={
        typeof router.query.onboarding !== 'undefined' &&
        router.query.onboarding === 'true' &&
        isLoggedIn
      }
      storageKey="initial-onboarding-2"
      onEnd={() => {
        delete router.query.onboarding;

        router.push(router, undefined, { shallow: true });
      }}
    >
      <Box
        display={{ xs: 'block', lg: 'grid' }}
        gridTemplateColumns={'auto minmax(0, 1fr)'}
        width="100%"
        height="100%"
        bgcolor="white"
      >
        <Box
          display={{
            xs: 'none',
            lg: 'block',
          }}
          component="aside"
          width={sideBarDesktopWidth}
          position="sticky"
          height="100vh"
          left={0}
          top={0}
          zIndex={9999}
          flexShrink={0}
        >
          <NewSidebar />
        </Box>

        <MobileHeader onBurgerMenuClick={mobileNavigationDisclosure.open} />

        <div className={containerClassName}>
          <Box
            sx={{
              position: { lg: 'sticky' },
              top: 0,
              zIndex: 600,
              bgcolor: ({ palette }) =>
                chroma(
                  selectedTeam?.isDemo
                    ? palette.red[200]
                    : palette.background.paper,
                )
                  .alpha(0.8)
                  .hex(),
              backdropFilter: 'blur(10px)',
              WebkitBackdropFilter: 'blur(10px)',
              borderBottom: '1px solid',
              borderColor: selectedTeam?.isDemo ? 'red.300' : 'neutral.400',
              width: '100%',
              height: '48px',
            }}
          >
            {selectedTeam?.isDemo && <DesktopHeaderDemoTeamOverlay />}
            <HStack
              fullHeight
              vcenter
              justifyContent={'space-between'}
              px={{
                xs: 1,
                lg: 2,
              }}
            >
              <HStack vcenter gap={1}>
                {shouldShowTeamChooser && (
                  <TeamDropdown
                    TeamDetailsPopperBox={
                      <TeamDetailsBox selectedTeam={selectedTeam} />
                    }
                  />
                )}
                {isLoggedIn && <UpgradePlanButton />}
                {!isLoggedIn && <GoToPricingButton />}
              </HStack>
              <HStack
                gap={{
                  xs: 0,
                  lg: 1,
                }}
                vcenter
              >
                {isLoggedIn && (
                  <div className="hidden sm:block">
                    <TokenSearch />
                  </div>
                )}
                <HelpButton />
                <FeedbackButton />
                <a
                  href="https://cookie3.co/blog"
                  target="_blank"
                  style={{
                    all: 'unset',
                  }}
                >
                  <Button
                    variant="outline"
                    sx={{
                      bgcolor: 'neutral.200',
                      display: {
                        xs: 'none',
                        lg: 'block',
                      },
                    }}
                    size="mini"
                    Icon={PiBell}
                  >
                    Blog
                  </Button>
                </a>
              </HStack>
            </HStack>
          </Box>
          {selectedTeam?.isDemo && <MobileHeaderDemoTeamOverlay />}
          <div>{children}</div>
        </div>
      </Box>
      <MobileSidebar mobileNavigationDisclosure={mobileNavigationDisclosure} />
    </OnboardingWrapper>
  );
}

const TeamDetailsBox = ({
  selectedTeam,
}: {
  selectedTeam: TeamResItem | undefined;
}) => {
  if (!selectedTeam) return null;
  return (
    <Box
      border="1px solid"
      borderColor="neutral.300"
      bgcolor="neutral.200"
      height="40px"
      borderRadius={2}
      pl={1.5}
      pr={1}
      display="flex"
      alignItems="center"
      gap={2}
      sx={{
        cursor: 'pointer',
        transition: 'background-color 0.2s ease-in-out',
        '&:hover': {
          bgcolor: 'neutral.300',
        },
      }}
    >
      <Box>
        <HStack vcenter gap={1}>
          <Text size="xs" weight="medium">
            {selectedTeam.name}
          </Text>
          {selectedTeam?.isSubscriptionActive ? (
            <Tag size="small" color="green">
              {selectedTeam.subscriptionType}
            </Tag>
          ) : (
            <Tag size="small" color="gray">
              Free
            </Tag>
          )}

          {selectedTeam?.isDemo && (
            <Tag size="small" color="red">
              Demo
            </Tag>
          )}
        </HStack>
      </Box>
      <PiCaretUpDown />
    </Box>
  );
};
