export default function PolygonSmIcon() {
  return (
    <svg
      width="17"
      height="14"
      viewBox="0 0 17 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_9991_1139)">
        <path
          d="M12.1194 4.2627C11.8269 4.09554 11.4507 4.09554 11.1164 4.2627L8.77612 5.6418L7.18806 6.51942L4.88955 7.89852C4.59701 8.06569 4.2209 8.06569 3.88657 7.89852L2.08955 6.81195C1.79701 6.64479 1.58806 6.31046 1.58806 5.93434V3.84479C1.58806 3.51046 1.75522 3.17613 2.08955 2.96718L3.88657 1.9224C4.1791 1.75524 4.55522 1.75524 4.88955 1.9224L6.68657 3.00897C6.9791 3.17613 7.18806 3.51046 7.18806 3.88658V5.26569L8.77612 4.34628V2.92539C8.77612 2.59106 8.60896 2.25673 8.27463 2.04777L4.93134 0.0835946C4.63881 -0.0835696 4.26269 -0.0835696 3.92836 0.0835946L0.501493 2.08956C0.167164 2.25673 0 2.59106 0 2.92539V6.85374C0 7.18807 0.167164 7.5224 0.501493 7.73136L3.88657 9.69554C4.1791 9.8627 4.55522 9.8627 4.88955 9.69554L7.18806 8.35822L8.77612 7.43882L11.0746 6.10151C11.3672 5.93434 11.7433 5.93434 12.0776 6.10151L13.8746 7.14628C14.1672 7.31345 14.3761 7.64777 14.3761 8.02389V10.1134C14.3761 10.4478 14.209 10.7821 13.8746 10.9911L12.1194 12.0358C11.8269 12.203 11.4507 12.203 11.1164 12.0358L9.3194 10.9911C9.02687 10.8239 8.81791 10.4896 8.81791 10.1134V8.77613L7.22985 9.69554V11.0746C7.22985 11.409 7.39702 11.7433 7.73134 11.9523L11.1164 13.9164C11.409 14.0836 11.7851 14.0836 12.1194 13.9164L15.5045 11.9523C15.797 11.7851 16.006 11.4508 16.006 11.0746V7.10449C16.006 6.77016 15.8388 6.43583 15.5045 6.22688L12.1194 4.2627Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_9991_1139">
          <rect width="16.0478" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
