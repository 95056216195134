export default function BnbSmIcon() {
  return (
    <svg
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.54955 8.99999L1.55808 12.1313L4.21879 13.697V15.5303L0.000949242 13.0565V8.0843L1.54955 8.99999ZM1.54955 5.86863V7.69336L0 6.77673V4.952L1.54955 4.03537L3.10668 4.952L1.54955 5.86863ZM5.32994 4.952L6.87949 4.03537L8.43662 4.952L6.87949 5.86863L5.32994 4.952Z"
        fill="white"
      />
      <path
        d="M2.66797 11.4823V9.64903L4.21751 10.5657V12.3904L2.66797 11.4823ZM5.32867 14.3536L6.87821 15.2703L8.43535 14.3536V16.1784L6.87821 17.095L5.32867 16.1784V14.3536ZM10.6577 4.952L12.2072 4.03537L13.7643 4.952V6.77673L12.2072 7.69336V5.86863L10.6577 4.952ZM12.2072 12.1313L12.2157 8.99999L13.7653 8.08335V13.0556L9.54746 15.5293V13.6961L12.2072 12.1313Z"
        fill="white"
      />
      <path
        d="M11.0984 11.4828L9.54883 12.3909V10.5662L11.0984 9.64954V11.4828Z"
        fill="white"
      />
      <path
        d="M11.0961 6.51771L11.1046 8.35097L8.4363 9.91665V13.0556L6.88675 13.9637L5.33721 13.0556V9.91665L2.66892 8.35097V6.51771L4.2251 5.60108L6.87726 7.17434L9.54556 5.60108L11.1027 6.51771H11.0961ZM2.66797 3.38731L6.87821 0.904999L11.0961 3.38731L9.54651 4.30394L6.87821 2.73067L4.21751 4.30394L2.66797 3.38731Z"
        fill="white"
      />
    </svg>
  );
}
