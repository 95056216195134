export default function ArbitrumSmIcon() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.37297 6.45346L7.39322 9.14171C7.36893 9.21458 7.36893 9.29556 7.39322 9.37653L9.07742 14L11.0288 12.8745L8.68876 6.45346C8.63208 6.30771 8.42965 6.30771 8.37297 6.45346Z"
        fill="white"
      />
      <path
        d="M10.3411 1.93527C10.2844 1.78952 10.082 1.78952 10.0253 1.93527L9.04556 4.62352C9.02127 4.6964 9.02127 4.77737 9.04556 4.85834L11.8067 12.4292L13.7581 11.3037L10.3411 1.94337V1.93527Z"
        fill="white"
      />
      <path
        d="M6.2429 0.00809715H4.35626C4.21861 0.00809715 4.08906 0.0971657 4.04048 0.22672L0 11.3036L1.95141 12.4291L6.40484 0.22672C6.44533 0.11336 6.36436 0 6.251 0L6.2429 0.00809715Z"
        fill="white"
      />
      <path
        d="M9.54631 0.00809714H7.65968C7.52203 0.00809714 7.39247 0.0971657 7.34389 0.22672L2.72852 12.8745L4.67993 14L9.70016 0.22672C9.74064 0.11336 9.65967 0 9.54631 0V0.00809714Z"
        fill="white"
      />
    </svg>
  );
}
