export default function EthereumSmIcon() {
  return (
    <svg
      width="8"
      height="14"
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.81781 0.727325L3.73438 1.01322V9.30847L3.81781 9.39244L7.63477 7.11638L3.81781 0.727325Z"
        fill="white"
      />
      <path
        d="M3.81706 0.727325L0 7.11638L3.81706 9.39244V5.36616V0.727325Z"
        fill="white"
      />
      <path
        d="M3.81655 10.1215L3.76953 10.1793V13.1342L3.81655 13.2727L7.63586 7.84659L3.81655 10.1215Z"
        fill="white"
      />
      <path
        d="M3.81706 13.2727V10.1215L0 7.84659L3.81706 13.2727Z"
        fill="white"
      />
      <path
        d="M3.81641 9.39243L7.63337 7.11638L3.81641 5.36615V9.39243Z"
        fill="white"
      />
      <path d="M0 7.11638L3.81706 9.39243V5.36615L0 7.11638Z" fill="white" />
    </svg>
  );
}
