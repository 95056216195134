export default function ChilizIcon() {
  return (
    <svg
      width="15"
      height="31"
      viewBox="0 0 15 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.678418 7.31635L5.10569 4.59187C5.49165 4.36483 5.49165 3.91076 5.21921 3.63831L1.51846 0.0510839C1.45035 -0.017028 1.33683 -0.017028 1.24602 0.0510839L0.905457 0.414347C0.451378 0.868426 0.451378 1.59495 0.905457 2.04903L2.35851 3.38856C2.60825 3.63831 2.56285 4.04698 2.26769 4.22861L0.542194 5.2957C-0.00270068 5.63626 -0.161628 6.34008 0.178931 6.86227L0.428674 7.29364C0.474082 7.38446 0.587602 7.40716 0.678418 7.36176V7.31635Z"
        fill="#FF1256"
      />
      <path
        d="M4.96949 21.9831C5.33276 20.4165 4.90138 18.7591 3.81159 17.5558L1.019 14.3773C-0.547568 12.6064 -0.161601 9.8819 1.83635 8.61047L6.42254 5.68166C6.7177 5.50003 7.10366 5.52274 7.35341 5.77248C9.0335 7.31635 14.8457 12.8788 14.596 18.6229C14.596 18.6229 14.596 27.5456 5.80954 30.883C4.51541 31.3825 3.22129 30.2019 3.56185 28.8624C4.76516 24.0492 4.9922 21.9831 4.9922 21.9831H4.96949ZM6.10469 8.24721C5.40087 8.70129 4.06133 9.54134 2.90343 10.2906C2.1315 10.7901 1.97257 11.8344 2.56287 12.5383L5.49168 15.9211C7.17177 17.7602 7.83019 20.303 7.26259 22.7323L6.7631 24.8892C6.55877 25.8201 7.64856 26.4785 8.37508 25.8428C11.4628 23.1864 15.7766 16.7839 6.7631 8.31532C6.58147 8.13369 6.30902 8.11099 6.08198 8.24721H6.10469Z"
        fill="#FF1256"
      />
    </svg>
  );
}
