import { Box, styled } from '@mui/material';
import useSWR from 'swr/immutable';

import UnstyledLink from 'common/UnstyledLink';
import fetcher from 'utils/fetcher';
import { SessionUser } from 'types/User';

export default function UserStatusBadge() {
  const { data } = useSWR<SessionUser>('/api/users/session', fetcher);

  if (!data?.roles?.map((role) => role.role).includes('ADMIN')) {
    return null;
  }

  return (
    <UnstyledLink href="/admin">
      <StyledBadge>Admin</StyledBadge>
    </UnstyledLink>
  );
}

const StyledBadge = styled(Box)`
  background: linear-gradient(
      96.55deg,
      rgba(143, 143, 143, 0.31) 4.97%,
      white 54.4%
    ),
    linear-gradient(276.13deg, #bdc4d5 2.67%, white 92.98%);

  flex-shrink: 0;
  font-weight: 700;
  font-size: 7px;
  height: fit-content;
  line-height: 9px;
  text-align: center;
  letter-spacing: 0.04em;
  position: relative;

  color: #000000;
  padding: 2px 6px;
  border-radius: 18px;
  box-shadow: 0px 0px 4px rgba(0, 10px, 0, 0.25);

  &:after {
    position: absolute;
    top: -0.5px;
    bottom: -0.5px;
    left: -0.5px;
    right: -0.5px;
    background: linear-gradient(276.13deg, #bdc4d5 2.67%, white 92.98%);
    content: '';
    z-index: -1;
    border-radius: 18px;
  }
`;
