import { ComponentPropsWithRef, ForwardedRef, forwardRef } from 'react';

import Button from 'components/primitives/Button';

interface Props extends ComponentPropsWithRef<typeof Button> {
  isLoading?: boolean;
  loadingText?: string;
}

const WidgetAction = forwardRef(
  ({ isLoading, ...props }: Props, ref: ForwardedRef<HTMLButtonElement>) => {
    return (
      <Button {...props} ref={ref}>
        {isLoading ? props.loadingText || 'Loading...' : props.children}
      </Button>
    );
  },
);

WidgetAction.displayName = 'WidgetAction';

export default WidgetAction;
