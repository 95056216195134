import { useMemo, useState } from 'react';
import useSWR from 'swr';
import { isAddress } from 'web3-utils';

import { getListOfChains } from 'components/Dashboard/ChainDropdown/utils/getListOfChains';
import fetcher from 'utils/fetcher';
import useDebounce from 'utils/hooks/useDebounce';
import { useGetTeams } from 'services/teamManagement/useGetTeams';
import { Token } from 'types/Token';

interface GetTokenDetailsProps {
  limit?: number;
  useChainsOnlyAvailableToTeam?: boolean;
}

export const useGetTokenDetails = ({
  limit = 6,
  useChainsOnlyAvailableToTeam = false,
}: GetTokenDetailsProps = {}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearchQuery = useDebounce(searchQuery, 300);
  const { selectedTeam, isLoading: areTeamsLoading } = useGetTeams();

  const handleSearch = (text?: string) => {
    setSearchQuery(text || '');
  };

  const chainIdList = useMemo(() => {
    if (useChainsOnlyAvailableToTeam) {
      return getListOfChains({ selectedTeam })
        .filter((chain) => chain.isAvailable && !chain.isComingSoon)
        .map((chain) => chain.id);
    }

    return null;
  }, [selectedTeam, useChainsOnlyAvailableToTeam]);

  const payload = {
    param: debouncedSearchQuery,
    limit,
    forChains: chainIdList,
  };

  const { data, error, isLoading } = useSWR<Token[]>(
    ['/api/tokenDetails', debouncedSearchQuery, limit, chainIdList],
    async ([url]) => {
      const response = await fetcher(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      return response;
    },
    {
      revalidateOnFocus: false,
    },
  );

  return {
    data,
    error,
    isLoading,
    handleSearch,
    searchQuery,
    debouncedSearchQuery,
  };
};

export const useGetContractDetails = ({
  address,
  chainId,
}: {
  address?: string | null;
  chainId?: number | null;
}) => {
  const shouldFetch = !!(isAddress(address ?? '') && chainId);

  const payload = {
    param: address,
    limit: 6,
    forChains: [chainId],
  };

  const { data, error, isLoading } = useSWR<Token[]>(
    shouldFetch ? ['/api/tokenDetails', address, chainId] : null,
    async ([url]) => {
      const response = await fetcher(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      return response;
    },
    {
      revalidateOnFocus: false,
    },
  );

  const _data = isLoading
    ? undefined
    : data?.find(
        (contract) =>
          contract.address.toLowerCase() === address?.toLocaleLowerCase() &&
          contract.chain === chainId,
      );

  return {
    data: _data,
    error,
    isLoading,
  };
};
