import { PiDotsThree } from 'react-icons/pi';
import Image from 'next/image';
import { Box, IconButton, Stack, styled } from '@mui/material';

import PopperWrapper from 'common/PopperWrapper';
import UserStatusBadge from 'common/UserStatusBadge';
import AccountModal from 'components/Dashboard/NewSidebar/Content/UserPanel/AccountModal';
import Text from 'components/primitives/Text';
import { useIsMobile } from 'utils/hooks';

import { useAppStateContext } from '../../../../../store/AppProvider';

export default function UserPanel() {
  const {
    user: { data: userInfo },
  } = useAppStateContext();
  const isMobile = useIsMobile();

  if (!userInfo) return null;
  return (
    <>
      <PopperWrapper
        withBackdrop
        content={<AccountModal />}
        placement={isMobile ? 'top' : 'right-start'}
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [10, 18],
              },
            },
          ],
        }}
        sx={{
          zIndex: 99999,
          width: { xs: '100%', sm: 'auto' },
        }}
      >
        {({ isOpen }) => (
          <Stack
            direction="row"
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{ cursor: 'pointer' }}
          >
            <Box display="flex" gap={2} alignItems="center">
              <Box display="flex">
                <Image
                  src="/user-avatar-gradient.svg"
                  alt="avatar"
                  width={32}
                  height={32}
                />
              </Box>
              <StyledUserInfoBox>
                <Stack direction="row" gap={1} alignItems="center">
                  <Text color="primary" size="xs">
                    {userInfo.username || 'Your profile'}
                  </Text>

                  <UserStatusBadge />
                </Stack>
                <Text textAlign="left" size="xxs" color="tertiary">
                  {userInfo.email}
                </Text>
              </StyledUserInfoBox>
            </Box>

            <IconButton size="small">
              <PiDotsThree size="20px" />
            </IconButton>
          </Stack>
        )}
      </PopperWrapper>
    </>
  );
}

const StyledUserInfoBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'right',
}));
