export default function RoninSmIcon() {
  return (
    <svg
      width="12"
      height="16"
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0688 5.57054V2.15314C11.0688 1.70045 10.8824 1.2655 10.554 0.945955C10.2167 0.626406 9.77288 0.440002 9.30243 0.440002H1.7664C1.29595 0.440002 0.843255 0.61753 0.514829 0.945955C0.186404 1.2655 0 1.70045 0 2.15314V12.0592C0 12.3166 0.0621346 12.574 0.177527 12.7959C0.29292 13.0267 0.461571 13.2308 0.665727 13.3906L3.40852 15.5121C3.44403 15.5387 3.47954 15.5476 3.52392 15.5565C3.5683 15.5565 3.6038 15.5565 3.64819 15.5387C3.68369 15.5209 3.7192 15.4943 3.73695 15.4588C3.76358 15.4233 3.77246 15.3878 3.77246 15.3434V8.33997C3.77246 8.27783 3.79908 8.22457 3.84347 8.18907C3.88785 8.14469 3.94111 8.12693 4.00324 8.12693H6.00042C6.3466 8.12693 6.69278 8.26008 6.94132 8.49974C7.18986 8.7394 7.33188 9.06783 7.33188 9.40513V15.3434C7.33188 15.3789 7.34075 15.4233 7.36738 15.4588C7.39401 15.4943 7.42064 15.5209 7.45615 15.5387C7.53603 15.5742 7.6248 15.5653 7.68693 15.5121L10.4297 13.3906C10.6428 13.2308 10.8025 13.0267 10.9179 12.7959C11.0333 12.5651 11.0955 12.3166 11.0955 12.0592V8.97906C11.0955 8.52637 10.9091 8.09143 10.5806 7.77188C10.2433 7.45233 9.79951 7.2748 9.32018 7.2748C9.79063 7.2748 10.2433 7.09728 10.5718 6.76885C10.9002 6.4493 11.0866 6.01436 11.0866 5.56167L11.0688 5.57054ZM5.97379 6.42267H3.97661C3.92335 6.42267 3.86122 6.39604 3.81684 6.36054C3.77246 6.32503 3.7547 6.2629 3.7547 6.20964V2.36617C3.7547 2.31292 3.78133 2.25966 3.81684 2.21528C3.86122 2.17977 3.91448 2.15314 3.97661 2.15314H7.07446C7.1366 2.15314 7.18986 2.17977 7.23424 2.21528C7.27862 2.25966 7.29637 2.31292 7.29637 2.36617V5.14448C7.29637 5.48178 7.15435 5.8102 6.90581 6.04986C6.65727 6.28953 6.31997 6.42267 5.96492 6.42267H5.97379Z"
        fill="white"
      />
    </svg>
  );
}
