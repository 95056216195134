enum Chain {
  Ethereum = 1,
  Binance = 56,
  Matic = 137,
  Fantom = 250,
  Arbitrum = 42161,
  Avalanche = 43114,
}

export enum ImplementedInterface {
  None = 0,
  Erc20 = 1 << 0,
  Erc721 = 1 << 1,
  Erc1155 = 1 << 2,
}

interface ContractDetails {
  address: string;
  name: string;
  symbol: string;
  chain: number;
  logo?: string;
  implementedInterfaces: ImplementedInterface;
}

export { Chain };
export type { ContractDetails };
