export default function AvalancheIcon() {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_9885_18207)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.9891 15.5C30.9891 24.0491 24.0543 30.9794 15.4995 30.9794C6.94473 30.9794 0.00976562 24.0491 0.00976562 15.5C0.00976562 6.95098 6.94473 0.0206299 15.4995 0.0206299C24.0543 0.0206299 30.9891 6.95098 30.9891 15.5ZM11.1101 21.66H8.104C7.47233 21.66 7.16031 21.66 6.97006 21.5384C6.76457 21.4053 6.639 21.1847 6.62378 20.9413C6.61235 20.717 6.76839 20.4432 7.08041 19.8956L14.5029 6.82115C14.8187 6.26595 14.9785 5.98836 15.1802 5.88569C15.3971 5.77542 15.6559 5.77542 15.8728 5.88569C16.0744 5.98836 16.2343 6.26595 16.5501 6.82115L18.076 9.48303L18.0838 9.49661C18.4249 10.0922 18.5979 10.3943 18.6734 10.7113C18.7571 11.0573 18.7571 11.4224 18.6734 11.7684C18.5973 12.0879 18.4261 12.3921 18.0798 12.9967L14.1809 19.8842L14.1708 19.9018C13.8275 20.5023 13.6535 20.8067 13.4123 21.0363C13.1497 21.2874 12.8339 21.4698 12.4876 21.5726C12.1718 21.66 11.8179 21.66 11.1101 21.66ZM18.7016 21.66H23.009C23.6444 21.66 23.9641 21.66 24.1545 21.5347C24.3599 21.4016 24.4893 21.1771 24.5008 20.9339C24.5117 20.7168 24.3591 20.4536 24.06 19.9379C24.0497 19.9203 24.0394 19.9024 24.0289 19.8843L21.8713 16.1957L21.8467 16.1541C21.5435 15.6418 21.3905 15.383 21.1939 15.283C20.9772 15.1727 20.722 15.1727 20.5052 15.283C20.3073 15.3857 20.1475 15.6557 19.8317 16.1995L17.6817 19.8881L17.6744 19.9008C17.3597 20.4437 17.2024 20.715 17.2137 20.9376C17.2289 21.181 17.3545 21.4053 17.56 21.5384C17.7464 21.66 18.0661 21.66 18.7016 21.66Z"
          fill="#E84142"
        />
      </g>
      <defs>
        <clipPath id="clip0_9885_18207">
          <rect width="31" height="31" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
