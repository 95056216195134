export default function RoninIcon() {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_16552_27190)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.04239 2.3808V21.514C4.04239 21.8736 4.12919 22.2208 4.29039 22.5432C4.45159 22.8656 4.68719 23.1384 4.97239 23.374L14.3344 30.6156C14.6692 30.876 15.0784 31.0124 15.5 31.0124C15.9216 31.0124 16.3308 30.876 16.6656 30.6156L26.0276 23.374C26.3128 23.1508 26.5484 22.8656 26.7096 22.5432C26.8708 22.2208 26.9576 21.8612 26.9576 21.514V2.3808C26.9576 1.7484 26.6972 1.1408 26.2384 0.6944C25.7672 0.248 25.1472 0 24.49 0H6.53479C5.87759 0 5.24519 0.248 4.78639 0.6944C4.32759 1.1408 4.05479 1.7484 4.05479 2.3808H4.04239Z"
          fill="#1273EA"
        />
        <path
          d="M23.2376 10.726V5.95201C23.2376 5.31961 22.9772 4.71201 22.5184 4.26561C22.0472 3.81921 21.4272 3.55881 20.77 3.55881H10.2424C9.58518 3.55881 8.95278 3.80681 8.49398 4.26561C8.03518 4.71201 7.77478 5.31961 7.77478 5.95201V19.7904C7.77478 20.15 7.86158 20.5096 8.02278 20.8196C8.18398 21.142 8.41958 21.4272 8.70478 21.6504L12.5364 24.614C12.586 24.6512 12.6356 24.6636 12.6976 24.676C12.7596 24.676 12.8092 24.676 12.8712 24.6512C12.9208 24.6264 12.9704 24.5892 12.9952 24.5396C13.0324 24.49 13.0448 24.4404 13.0448 24.3784V14.5948C13.0448 14.508 13.082 14.4336 13.144 14.384C13.206 14.322 13.2804 14.2972 13.3672 14.2972H16.1572C16.6408 14.2972 17.1244 14.4832 17.4716 14.818C17.8188 15.1528 18.0172 15.6116 18.0172 16.0828V24.3784C18.0172 24.428 18.0296 24.49 18.0668 24.5396C18.104 24.5892 18.1412 24.6264 18.1908 24.6512C18.3024 24.7008 18.4264 24.6884 18.5132 24.614L22.3448 21.6504C22.6424 21.4272 22.8656 21.142 23.0268 20.8196C23.188 20.4972 23.2748 20.15 23.2748 19.7904V15.4876C23.2748 14.8552 23.0144 14.2476 22.5556 13.8012C22.0844 13.3548 21.4644 13.1068 20.7948 13.1068C21.452 13.1068 22.0844 12.8588 22.5432 12.4C23.002 11.9536 23.2624 11.346 23.2624 10.7136L23.2376 10.726ZM16.12 11.9164H13.33C13.2556 11.9164 13.1688 11.8792 13.1068 11.8296C13.0448 11.78 13.02 11.6932 13.02 11.6188V6.24961C13.02 6.17521 13.0572 6.10081 13.1068 6.03881C13.1688 5.98921 13.2432 5.95201 13.33 5.95201H17.6576C17.7444 5.95201 17.8188 5.98921 17.8808 6.03881C17.9428 6.10081 17.9676 6.17521 17.9676 6.24961V10.1308C17.9676 10.602 17.7692 11.0608 17.422 11.3956C17.0748 11.7304 16.6036 11.9164 16.1076 11.9164H16.12Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_16552_27190">
          <rect width="31" height="31" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
