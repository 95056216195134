import { ChainId } from 'types/Chain';

export enum FilterType {
  FirstActivity = 0,
  LastActivity = 1,
  Site = 2,
  Balance = 3,
  Holding = 4,
  ManualSource = 5,
  Interaction = 6,
  BuyOrSell = 7,
}

export type Filter =
  | HoldingFilter
  | FirstActivityFilter
  | LastActivityFilter
  | BalanceFilter
  | SiteFilter
  | BoughtSoldFilter
  | InteractionFilter
  | ManualSourceFilter;

export type HoldingFilter = {
  filterType: FilterType.Holding;
  condition: {
    chain: ChainId;
    address: string;
    valueUsdCondition?: {
      value: number;
      greaterThan: boolean;
    };
    valueRawCondition?: {
      value: number;
      greaterThan: boolean;
    };
  };
};

export type FirstActivityFilter = {
  filterType: FilterType.FirstActivity;
  condition: {
    value: string;
    greaterThan: boolean;
    chain: ChainId;
  };
};

export type LastActivityFilter = {
  filterType: FilterType.LastActivity;
  condition: {
    value: string;
    greaterThan: boolean;
    chain: ChainId;
  };
};

export type BalanceFilter = {
  filterType: FilterType.Balance;
  condition: {
    value: number;
    greaterThan: boolean;
    chain: ChainId;
  };
};

export type SiteFilter = {
  filterType: FilterType.Site;
  basicFilterInput?: {
    sitesIds: string[];
    dateFrom?: string;
    dateTo?: string;
  };
  customFiltersInput?: {
    equalFilterCampaignId?: string[];
    equalFilterCampaignName?: string[];
    equalFilterCampaignContent?: string[];
    equalFilterCampaignKeyword?: string[];
    equalFilterCampaignSource?: string[];
    equalFilterCampaignMedium?: string[];
    equalFilterCampaignGroup?: string[];
    equalFilterCampaignPlacement?: string[];
    equalFilterReferrerType?: string[];
    equalFilterReferrerName?: string[];
    equalFilterReferrerUrl?: string[];
    equalFilterCountry?: string[];
    equalFilterContinent?: string[];
    equalFilterRegion?: string[];
    equalFilterCity?: string[];
    equalDeviceType?: string[];
    equalBrowser?: string[];
    equalFilterFirstActionUrl?: string[];
    likeFilterFirstActionUrl?: string[];
    equalFilterLastActionUrl?: string[];
    likeFilterLastActionUrl?: string[];
    equalFilterUrlVisited?: string[];
    likeFilterFullUrlVisited?: string[];
  };
};

export type BoughtSoldFilter = {
  filterType: FilterType.BuyOrSell;
  condition: {
    chain: ChainId;
    address: string;
    bought: boolean;
    valueUsdCondition?: {
      value: number;
      greaterThan: boolean;
    };
    timeRangeCondition: {
      from: string | null;
      to: string | null;
    };
    relativeTimeRangeCondition?: {
      inLastNDays: number;
    };
  };
};

export type InteractionFilter = {
  filterType: FilterType.Interaction;
  condition: {
    chain: ChainId;
    address: string;
    timeRangeCondition: {
      from: string | null;
      to: string | null;
    };
    relativeTimeRangeCondition?: {
      inLastNDays: number;
    };
  };
};

export type ManualSourceFilter = {
  filterType: FilterType.ManualSource;
  condition: {
    source: string;
    addressNotIn: boolean;
  };
};
